/* ========================================================================
 * Tables
 * ========================================================================
 *
 * Style and behavior adjustment for Tables
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  cb.apricot.mobileTbl = function (element, options) {
    var
      defaultOptions = {
        mobileClass : 'cb-mobile-tbl',
        mobileLayout: 'mobile'
      },

      cArr = [],
      rArr = [],
      plugin = this;

    plugin.$el = $(element);
    plugin.$mTbl = {};
    plugin.$parent = {};

    var
    buildMobileTbl = function () {
      var
        $tbl = $('<table />')
          .addClass(plugin.options.mobileClass)
          .addClass('table')
          .append('<tbody />');

      $('thead tr:first td, thead tr:first th', plugin.$el).each( function () {
        var self = $(this),
        obj = {};

        obj.class = (!!self.attr('class')) ? self.attr('class') : '';
        obj.html = self.html();
        cArr.push(obj);
      });


      $('tbody tr', plugin.$el).each( function (index) {
        var $tr = $(this),
        arr = [];

        $('th, td', $tr).each (function () {
          var self = $(this),
          obj = {};

          obj.class = (!!self.attr('class')) ? self.attr('class') : '';
          obj.html = self.html();
          arr.push(obj);
        });

        rArr.push(arr);
      });

      for (var i in rArr) {
        var cellNum = 0;
        for (var j in cArr) {
          var $tr = $('<tr />');

          $('<th />')
            .attr('scope', 'row')
            .html(cArr[j].html)
            .addClass(cArr[j].class)
            .appendTo($tr);

          if (cellNum == 0) {
            $('<th />')
              .html(rArr[i][j].html)
              .attr('scope', 'col')
              .addClass(rArr[i][j].class)
              .appendTo($tr);
          } else {
            $('<td />')
              .html(rArr[i][j].html)
              .addClass(rArr[i][j].class)
              .appendTo($tr);
          }

          if (j == cArr.length - 1) {
            $tr.addClass('cb-new-row');
          }

          $tr.appendTo($tbl);
          cellNum ++;
        }
      }

      plugin.$mTbl = $tbl;
      $tbl.insertAfter(plugin.$el);
    },

    checkStatus = function () {
      var
        currentViewport = cb.apricot.utils.viewport();

      if (plugin.options.mobileLayout === 'tablet') {
        if (currentViewport.prefix === 'sm' || currentViewport.prefix === 'xs') {
          showMobileTbl();
        } else {
          hideMobileTbl();
        }
      } else if (plugin.options.mobileLayout === 'mobile') {
        if (currentViewport.prefix === 'xs') {
          showMobileTbl();
        } else {
          hideMobileTbl();
        }
      }
    },

    showMobileTbl = function () {
      plugin.$mTbl.removeClass('hidden');
      plugin.$el.addClass('hidden');
      if (!!plugin.$parent.hasClass('table-responsive')) {
        plugin.$parent.addClass('cb-no-border');
      }
    },

    hideMobileTbl = function () {
      plugin.$mTbl.addClass('hidden');
      plugin.$el.removeClass('hidden');
      if (!!plugin.$parent.hasClass('table-responsive')) {
        plugin.$parent.removeClass('cb-no-border');
        plugin.$el.cbCustomScrollbar('adjustTable');
      }
    };

    plugin.init = function () {

      plugin.options = $.extend({}, defaultOptions, options);
      plugin.$parent = plugin.$el.parent();

      buildMobileTbl();
      checkStatus();

      //Check structure on resize
      $(window).on('resize.cbMobileTbl', function () {
        checkStatus();
      });
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      $('.' + plugin.options.mobileClass, plugin.$parent).remove();
      $(window).on('resize.cbMobileTbl');

      plugin.$el.removeData('cbMobileTbl');
    };

    plugin.init();
  };

  $.fn.cbMobileTbl = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbMobileTbl')) {
          $(this).data('cbMobileTbl', new cb.apricot.mobileTbl(this, options));
        }
       });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbMobileTbl');
        if (instance instanceof cb.apricot.mobileTbl && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call(args, 1) );
        }
      });
    }
  };
}(jQuery, cb);