/*!
 *  Apricot v3.4.0
 *  By:             The College Board
 *  App:            apricot
 *  Build Time:     2019-04-02 [10:28:53 AM] EDT
 *  Build Number:   unknown
 *  SVN Revision:   unknown
 *  Jenkins Job:    unknown
 *  This version of Apricot includes Bootstrap v3.7.0
 */

/* ========================================================================
 * Tooltip extension
 * ========================================================================
 *
 * This extension fixes the tooltip positioning issue when the hover
 * control has a line break
 * ======================================================================== */

+function ($) {
  'use strict';

  if (!$.fn.tooltip) throw new Error('tooltip-extension requires tooltip.js');

  $.fn.tooltip.Constructor.prototype.getCalculatedOffset = function (placement, pos, actualWidth, actualHeight) {
    var
      defaultHeight = 0,
      defaultWidth = 0,
      currentHeight = pos.height,
      tmpPlacement = placement,
      $tmp = this.$element.clone()
      .css({
        'position': 'absolute',
        'left': '-9999px'
      });

    $tmp.appendTo('body');
    defaultHeight = $tmp.outerHeight();
    defaultWidth = $tmp.outerWidth();
    $tmp.remove();


    // There was a line break
    if (currentHeight !== defaultHeight) {
      pos.width = defaultWidth ;
      //IE8 bug
      if (!isNaN(pos.right)) {
        pos.left = pos.right - defaultWidth;
      }
    }

    if (this.type === 'popover' && !!this.$element.hasClass('cb-popover-adjust-height')) {
      var $tip = this.tip(),
      pageHeight = $(window).height(),
      $content = $('.popover-content', $tip),
      newHeight = 0,
      offsetTop = this.$element.offset().top - $(document).scrollTop(),
      offsetBottom = pageHeight - offsetTop,
      limit = (!isNaN(parseInt(this.$element.attr('data-cb-adjust-height'), 10))) ?
        parseInt(this.$element.attr('data-cb-adjust-height'), 10) : 96;

      if (!!this.$element.attr('data-cb-actual-height')) {
        actualHeight = this.$element.attr('data-cb-actual-height')
      } else {
        this.$element.attr('data-cb-actual-height', actualHeight);
      }

      if (offsetTop < limit && tmpPlacement == 'top') {
        $tip.removeClass('top');
        tmpPlacement = 'bottom';
      } else if (offsetBottom < limit && tmpPlacement == 'bottom') {
        $tip.removeClass('bottom');
        tmpPlacement = 'top';
      }

      $tip.addClass(tmpPlacement)

      if (tmpPlacement == 'bottom' ) {
        newHeight = pageHeight - (offsetTop + (pos.height * 2));
      } else if (tmpPlacement == 'top') {
        newHeight = offsetTop - (pos.height * 2);

        if (newHeight < actualHeight) {
          actualHeight = newHeight;
        } else {
          newHeight = 0;
        }
      }

      if (newHeight > 0 ) {
        $content.css({
          'max-height': newHeight + 'px',
          'overflow-y': 'auto'
        }).addClass('cb-popover-contnet-height')
      }
    }

    return tmpPlacement == 'bottom' ? { top: pos.top + pos.height,   left: pos.left + pos.width / 2 - actualWidth / 2  } :
           tmpPlacement == 'top'    ? { top: pos.top - actualHeight, left: pos.left + pos.width / 2 - actualWidth / 2  } :
           tmpPlacement == 'left'   ? { top: pos.top + pos.height / 2 - actualHeight / 2, left: pos.left - actualWidth } :
        /* placement == 'right' */ { top: pos.top + pos.height / 2 - actualHeight / 2, left: pos.left + pos.width   };
  };

  $.fn.tooltip.Constructor.prototype.show = function () {
    var e = $.Event('show.bs.' + this.type)

    if (this.hasContent() && this.enabled) {
      this.$element.trigger(e)

      var inDom = $.contains(this.$element[0].ownerDocument.documentElement, this.$element[0])
      if (e.isDefaultPrevented() || !inDom) return
      var that = this

      var $tip = this.tip()

      var tipId = this.getUID(this.type)

      var elmId = (!!this.$element.attr('id')) ? this.$element.attr('id') : cb.apricot.utils.uniqueID(5, 'apricot_')

      this.setContent()
      $tip.attr('id', tipId)
      this.$element.attr('aria-describedby', tipId)

      if (this.type === 'popover') {
        $tip.attr('role', 'region')
          .attr('aria-labelledby', elmId);
        
        this.$element.attr('id', elmId);

        if (!!this.$element.hasClass('cb-popover-adjust-height')) {
          $tip.attr('tabindex', '-1');
          this.$element.attr('aria-controls', tipId);
        } else if (!!this.$element.hasClass('cb-popover-list-menu')) {
          $tip.attr('role', 'menu')
            .attr('tabindex', '-1');
          this.$element.attr('aria-controls', tipId);

          var $ul = $('.cb-popover-list', $tip);
          $ul.attr('role', 'presentation');

          $('li', $ul).each (function (index) {
            var self = $(this);
              self.attr('role' , 'presentation')
              $('a', self).attr('role', 'menuitem');
          });
        }
        this.$element.attr('aria-expanded', true);
      }

      if (this.options.animation) $tip.addClass('fade')

      var placement = typeof this.options.placement == 'function' ?
        this.options.placement.call(this, $tip[0], this.$element[0]) :
        this.options.placement

      var autoToken = /\s?auto?\s?/i
      var autoPlace = autoToken.test(placement)
      if (autoPlace) placement = placement.replace(autoToken, '') || 'top'

      $tip
        .detach()
        .css({ top: 0, left: 0, display: 'block' })
        .addClass(placement)
        .data('bs.' + this.type, this)

      this.options.container ? $tip.appendTo(this.options.container) : $tip.insertAfter(this.$element)
      this.$element.trigger('inserted.bs.' + this.type)

      var pos          = this.getPosition()
      var actualWidth  = $tip[0].offsetWidth
      var actualHeight = $tip[0].offsetHeight

      if (autoPlace) {
        var orgPlacement = placement
        var viewportDim = this.getPosition(this.$viewport)

        placement = placement == 'bottom' && pos.bottom + actualHeight > viewportDim.bottom ? 'top'    :
                    placement == 'top'    && pos.top    - actualHeight < viewportDim.top    ? 'bottom' :
                    placement == 'right'  && pos.right  + actualWidth  > viewportDim.width  ? 'left'   :
                    placement == 'left'   && pos.left   - actualWidth  < viewportDim.left   ? 'right'  :
                    placement

        $tip
          .removeClass(orgPlacement)
          .addClass(placement)
      }

      var calculatedOffset = this.getCalculatedOffset(placement, pos, actualWidth, actualHeight)

      this.applyPlacement(calculatedOffset, placement)

      var complete = function () {
        var prevHoverState = that.hoverState
        that.$element.trigger('shown.bs.' + that.type)
        that.hoverState = null

        if (prevHoverState == 'out') that.leave(that)
        if (!!that.$element.hasClass('cb-popover-list-menu')) {
          $('a:first', that.tip())
            .focus();
        }
      }

      $.support.transition && this.$tip.hasClass('fade') ?
        $tip
          .one('bsTransitionEnd', complete)
          .emulateTransitionEnd(150) :
        complete()
    }
  }

  $.fn.tooltip.Constructor.prototype.hide = function (callback) {
    var that = this
    var $tip = $(this.$tip)
    var e    = $.Event('hide.bs.' + this.type)

    function complete() {
      if (that.hoverState != 'in') $tip.detach()
      if (that.$element) { // TODO: Check whether guarding this code with this `if` is really necessary.
        that.$element
          .removeAttr('aria-describedby')
          .removeAttr('aria-controls')
          .trigger('hidden.bs.' + that.type)
      }
      callback && callback()
    }

    this.$element.trigger(e)

    if (e.isDefaultPrevented()) return

    $tip.removeClass('in')

    if (this.type === 'popover') {
      this.$element.attr('aria-expanded', false);
    }

    $.support.transition && $tip.hasClass('fade') ?
      $tip
        .one('bsTransitionEnd', complete)
        .emulateTransitionEnd(150) :
      complete()

    this.hoverState = null

    return this
  }

  $.fn.tooltip.Constructor.prototype.init = function (type, element, options) {
    this.enabled   = true
    this.type      = type
    this.$element  = $(element)
    this.options   = this.getOptions(options)
    this.$viewport = this.options.viewport && $($.isFunction(this.options.viewport) ? this.options.viewport.call(this, this.$element) : (this.options.viewport.selector || this.options.viewport))
    this.inState   = { click: false, hover: false, focus: false }

    if (this.$element[0] instanceof document.constructor && !this.options.selector) {
      throw new Error('`selector` option must be specified when initializing ' + this.type + ' on the window.document object!')
    }

    var triggers = this.options.trigger.split(' ')

    for (var i = triggers.length; i--;) {
      var trigger = triggers[i]

      if (trigger == 'click') {
        this.$element.on('click.' + this.type, this.options.selector, $.proxy(this.toggle, this))
      } else if (trigger != 'manual') {
        var eventIn  = trigger == 'hover' ? 'mouseenter' : 'focusin'
        var eventOut = trigger == 'hover' ? 'mouseleave' : 'focusout'

        this.$element.on(eventIn  + '.' + this.type, this.options.selector, $.proxy(this.enter, this))
        this.$element.on(eventOut + '.' + this.type, this.options.selector, $.proxy(this.leave, this))
      }
    }

    // Accessibility improvment
    if (!this.$element.is('a') && !this.$element.is('button')) {
      this.$element.attr('role', 'link')
        .attr('aria-label', 'tooltip')
        .attr('tabindex', 0);
    }
    if (this.type === 'popover') {
      if (!!this.$element.hasClass('cb-popover-adjust-height')) {
        this.$element.removeAttr('aria-haspopup');
      } else if (!!this.$element.hasClass('cb-popover-button-list')) {
        this.$element.attr('aria-haspopup','menu');
      }
      this.$element.attr('aria-expanded', false);
    }

    this.options.selector ?
      (this._options = $.extend({}, this.options, { trigger: 'manual', selector: '' })) :
      this.fixTitle()
  };

  // Activate cb-tooltip-dark class
  // ==============
  $(window).on('load.cbTooltip', function () {
    $('.cb-tooltip-dark').each(function () {
      var
        self = $(this),
        darkTemplate = '<div class="tooltip cb-tooltip-dark"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>';

      self.tooltip({
        template: darkTemplate
      });
    });
  });

}(jQuery);
