/* ========================================================================
 * Custom Scrollbars
 * ========================================================================
 *
 * $.fn.cbCustomScrollbar
 *
 * Adds horizontal scrollbars to responsive tables
 *
 * This plugin is written based on jQuery Custom Scrollbar plugin
 * https://github.com/mzubala/jquery-custom-scrollbar
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  cb.apricot.customScrollbar = function (element, options) {
    var
    defaultOptions = {
      scrollableClass: 'cb-scrollbar-container',
      scrollbarClass: 'cb-scrollbar',
      scrollbarTopClass: 'top',
      scrollbarBottomClass: 'bottom',
      scrollbarThumbClass: 'cb-scrollbar-thumb',
      scrollbarTop: true,
      scrollbarBottom: true,
      rowComplexity: 2
    },

    plugin = this;

    plugin.$el = $(element);
    plugin.$parent = plugin.$el.parent();// .table-responsive
    plugin.$container = {}; // scrollable: main scrollbar container, will be build in buildScrollbar
    plugin.id = cb.apricot.utils.uniqueID(10, 'customScrollbar_'),
    plugin.width = null;

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, options);

      //Only activate functionality if element is a table
      if (!plugin.$el.is('table')) {
        return false;
      }

      // if no ID found add new id
      if (!!plugin.$el.attr('id')) {
        plugin.id = plugin.$el.attr('id');
      } else {
        plugin.$el.attr('id', plugin.id);
      }

      if (!!plugin.options.scrollbarTop || !!plugin.options.scrollbarBottom ) {
        buildScrollbar();

        //List of all scrollbars
        plugin.hScrollbars = [];

        if (!!plugin.options.scrollbarTop) {
          plugin.hScrollbarTop = new Scrollbar(plugin, new HSizing(), 'top');
          plugin.hScrollbars.push(scrollbarObj('top', plugin.hScrollbarTop));
          plugin.hScrollbarTop.resize();
        }

        if (!!plugin.options.scrollbarBottom ) {
          plugin.hScrollbarBottom = new Scrollbar(plugin, new HSizing(), 'bottom');
          plugin.hScrollbars.push(scrollbarObj('bottom', plugin.hScrollbarBottom));
          plugin.hScrollbarBottom.resize();
        }

        initKeyboardScrolling();
      }
    };

    var
    scrollbarObj = function (position, barObj) {
      return {position: position, bar: barObj};
    },

    buildScrollbar = function () {
      var
        $scrollable = $('<div>', {'class': plugin.options.scrollableClass}), //scrollable
        $scrollbarTop = $('<div>', {'class': plugin.options.scrollbarClass}),
        $scrollbarBottom = $('<div>', {'class': plugin.options.scrollbarClass});

      plugin.$parent.wrap($scrollable);
      plugin.$container = plugin.$parent.parent();

      //Add top scrollbar
      if (!!plugin.options.scrollbarTop) {
        $scrollbarTop.addClass(plugin.options.scrollbarTopClass);
        $scrollbarTop.append($('<div>', {'class': plugin.options.scrollbarThumbClass}));
        $scrollbarTop.insertBefore(plugin.$parent);
      }

      //Add bottom scrollbar
      if (!!plugin.options.scrollbarBottom ) {
        $scrollbarBottom.addClass(plugin.options.scrollbarBottomClass);
        $scrollbarBottom.append($('<div>', {'class': plugin.options.scrollbarThumbClass}));
        plugin.$container.append($scrollbarBottom);
      }
    },

    initKeyboardScrolling = function () {
      var
        _this = plugin,
        browser = cb.apricot.utils.browser();

      plugin.elementKeydown = function (event) {
        var
          bars = {};

        if (!!browser.msie) {
          for (bars in plugin.hScrollbars){
            _this.hScrollbars[bars].bar.keyScroll(event);
          }
        } else {
          // we need this for $.fn.cbStickyColumn accessibility
          if (document.activeElement === _this.$container[0] || $('.cb-sticky-nav-bar', _this.$container[0])) {
            for (bars in plugin.hScrollbars){
              _this.hScrollbars[bars].bar.keyScroll(event);
            }
          }
        }
      };

      plugin.$container
        .attr('tabindex', '-1')
        .keydown(plugin.elementKeydown);
    },

    Scrollbar = function (scrollable, sizing, position) {
      this.scrollable = scrollable;
      this.sizing = sizing;
      this.position = position;

      this.$scrollbar = this.sizing.scrollbar(this.scrollable, position); //$scrollbar

      this.$thumb = this.$scrollbar.find('.' + this.scrollable.options.scrollbarThumbClass); //$thumb

      this.setScrollPosition(0, 0);
      this.resize();

      this.initMouseMoveScrolling();
      this.initMouseWheelScrolling();
      this.initTouchScrolling();
      this.initMouseClickScrolling();
      this.initWindowResize();
    };

    Scrollbar.prototype = {
      resize: function (keepPosition) {
        var
          browser = cb.apricot.utils.browser(),
          calculatedHeight = this.scrollable.$el.height() + 2;

        if (!!browser.firefox) { //FF is not adding caption height to table.height()
          if (this.scrollable.$el.find('caption').length > 0) {
            calculatedHeight += this.scrollable.$el.find('caption').outerHeight();
          }
        }
        //Adjust height
        this.scrollable.$parent.height(calculatedHeight);
        this.sizing.size(this.scrollable.$parent, this.sizing.size(this.scrollable.$container));
        this.viewPortSize = this.sizing.size(this.scrollable.$parent);
        this.overviewSize = this.sizing.size(this.scrollable.$el);

        // in some browsers(safari), outerWidth of the table is not calculated correctly
        if (parseInt(this.calculateTableWidth() - this.overviewSize) > 10 ) {
          this.overviewSize = this.calculateTableWidth();
        }

        //Add border
        // this.overviewSize += 2;

        this.ratio = this.viewPortSize / this.overviewSize;

        this.sizing.size(this.scrollable.$parent, this.viewPortSize);
        this.ratio = this.viewPortSize / this.overviewSize;
        this.sizing.size(this.$scrollbar, this.viewPortSize);

        this.thumbSize = this.calculateThumbSize();
        this.sizing.size(this.$thumb, this.thumbSize);

        this.maxThumbPosition = this.calculateMaxThumbPosition();
        this.maxOverviewPosition = this.calculateMaxOverviewPosition();

        this.enabled = (this.overviewSize > this.viewPortSize);

        if (this.scrollPercent === undefined) {
          this.scrollPercent = 0.0;
        }

        if (this.enabled) {
          this.rescroll(keepPosition);
        } else {
          this.setScrollPosition(0, 0);
        }

        this.$scrollbar.toggle(this.enabled);
        this.scrollable.$container.toggleClass('active', this.enabled);
      },

      calculateViewPortSize: function () {
        var elementSize = this.sizing.size(this.scrollable.$container);
        if (elementSize > 0 && !this.maxSizeUsed) {
          this.viewPortSize = elementSize;
          this.maxSizeUsed = false;
        }
        else {
          var maxSize = this.sizing.maxSize(this.scrollable.$container);
          this.viewPortSize = Math.min(maxSize, this.overviewSize);
          this.maxSizeUsed = true;
        }
      },

      calculateTableWidth: function () {
        var
          width = 0,
          childrenWidth = 0,
          rows = [];

        if (plugin.width !== null) return plugin.width;

        // in some browsers, outerWidth of the table is not calculated correctly
        $('tr', this.scrollable.$el).each(function (index) {

          if (index === parseInt(plugin.options.rowComplexity, 10) ) {return false;}

          childrenWidth = 0;
          $(this).find('th, td').each(function () {
            childrenWidth += parseInt($(this).outerWidth(), 10);
          });
          rows.push(childrenWidth);
        });

        width = Math.max.apply(Math, rows);

        return width;
      },

      adjustCaption: function (type, width) {
        if (!!$('caption', this.scrollable.$el)) {
          if (!!type) {
            //caption is not getting adjusted
            $('caption', this.scrollable.$el).css('width', width + 1 + 'px'); //border included
          } else {
            $('caption', this.scrollable.$el).css('width', 'auto');
          }
        }
      },

      calculateThumbSize: function () {
        var size = this.ratio * this.viewPortSize;
        return Math.max(size, this.sizing.minSize(this.$thumb));
      },

      initMouseMoveScrolling: function () {
        var _this = this;
        this.$thumb.mousedown(function (event) {
          if (_this.enabled){
            _this.startMouseMoveScrolling(event);
          }
        });
        this.documentMouseup = function (event) {
          _this.stopMouseMoveScrolling(event);
        };
        $(document).mouseup(this.documentMouseup);
        this.documentMousemove = function (event) {
          _this.mouseMoveScroll(event);
        };
        $(document).mousemove(this.documentMousemove);
        this.$thumb.click(function (event) {
          event.stopPropagation();
        });
      },

      initMouseWheelScrolling: function () {
        var _this = this;
        this.scrollable.$container.mousewheel(function (event, delta, deltaX, deltaY) {
          if (_this.enabled) {
            if (_this.mouseWheelScroll(deltaX, deltaY)) {
              event.stopPropagation();
              event.preventDefault();
            }
          }
        });
      },

      initTouchScrolling: function () {
        if (document.addEventListener) {
          var _this = this;
          this.elementTouchstart = function (event) {
            if (_this.enabled)
              _this.startTouchScrolling(event);
          };

          this.scrollable.$container[0].addEventListener('touchstart', this.elementTouchstart);
          this.documentTouchmove = function (event) {
            _this.touchScroll(event);
          };

          document.addEventListener('touchmove', this.documentTouchmove);
          this.elementTouchend = function (event) {
            _this.stopTouchScrolling(event);
          };

          this.scrollable.$container[0].addEventListener('touchend', this.elementTouchend);
        }
      },

      initMouseClickScrolling: function () {
        var _this = this;
        this.scrollbarClick = function (event) {
          _this.mouseClickScroll(event);
        };
        this.$scrollbar.click(this.scrollbarClick);
      },

      initWindowResize: function () {
        var _this = this;
        this.windowResize = function () {
          plugin.width = null;
          _this.resize();
        };
        $(window).on('resize.customScrollbar.' + plugin.id, this.windowResize);
      },

      isKeyScrolling: function (key) {
        return this.keyScrollDelta(key) !== null;
      },

      keyScrollDelta: function (key) {
        for (var scrollingKey in this.sizing.scrollingKeys)
          if (scrollingKey == key)
            return this.sizing.scrollingKeys[key](this.viewPortSize);
        return null;
      },

      startMouseMoveScrolling: function (event) {
        this.mouseMoveScrolling = true;
        $('html').addClass('not-selectable');
        this.setUnselectable($('html'), 'on');
        this.setScrollEvent(event);
      },

      stopMouseMoveScrolling: function () {
        this.mouseMoveScrolling = false;
        $('html').removeClass('not-selectable');
        this.setUnselectable($('html'), null);
      },

      setUnselectable: function (element, value) {
        if (element.attr('unselectable') != value) {
          element.attr('unselectable', value);
          element.find(':not(input)').attr('unselectable', value);
        }
      },

      mouseMoveScroll: function (event) {
        if (this.mouseMoveScrolling) {
          var delta = this.sizing.mouseDelta(this.scrollEvent, event);

          this.scrollThumbBy(delta);
          this.setScrollEvent(event);

          //we have more than one scrollbar
          if (!!this.hasOtherScrollbarObj) {
            var
              bar = this.otherScrollableObj();
            if (!$.isEmptyObject(bar)) {
              bar.scrollThumbBy(delta);
              bar.setScrollEvent(event);
            }
          }
        }
      },

      hasOtherScrollbarObj: function() {
        if (this.scrollable.hScrollbars.length > 1) {
          return true;
        } else {
          return false;
        }
      },

      otherScrollableObj: function() {
        for (var bar in this.scrollable.hScrollbars) {
          if (this.scrollable.hScrollbars[bar].position !== this.position) {
            return this.scrollable.hScrollbars[bar].bar;
          }
        }
        return {};
      },

      startTouchScrolling: function (event) {
        if (event.touches && event.touches.length == 1) {
          this.setScrollEvent(event.touches[0]);
          this.touchScrolling = true;
          event.stopPropagation();
        }
      },

      touchScroll: function (event) {
        if (this.touchScrolling && event.touches && event.touches.length == 1) {
          var delta = -this.sizing.mouseDelta(this.scrollEvent, event.touches[0]);
          var scrolled = this.scrollOverviewBy(delta);
          if (scrolled) {
            event.stopPropagation();
            event.preventDefault();
            this.setScrollEvent(event.touches[0]);
          }
        }
      },

      stopTouchScrolling: function (event) {
        this.touchScrolling = false;
        event.stopPropagation();
      },

      mouseWheelScroll: function (deltaX, deltaY) {
        deltaY = 0;
        var delta = -this.sizing.wheelDelta(deltaX) * 40;
        if (delta !== 0)
          return this.scrollOverviewBy(delta);
      },

      mouseClickScroll: function (event) {
        var delta = this.viewPortSize - 20;
        if (event['page' + this.sizing.scrollAxis()] < this.$thumb.offset()[this.sizing.offsetComponent()])
        // mouse click over thumb
          delta = -delta;
        this.scrollOverviewBy(delta);

        //we have more than one scrollbar
        if (!!this.hasOtherScrollbarObj) {
          var
            bar = this.otherScrollableObj();
          if (!$.isEmptyObject(bar)) {
            bar.scrollOverviewBy(delta);
          }
        }
      },

      keyScroll: function (event) {
        var keyDown = event.which;

        if (this.enabled && this.isKeyScrolling(keyDown)) {
          if (this.scrollOverviewBy(this.keyScrollDelta(keyDown)))
            event.preventDefault();
        }
      },

      scrollThumbBy: function (delta) {
        var thumbPosition = this.thumbPosition();
        thumbPosition += delta;
        thumbPosition = this.positionOrMax(thumbPosition, this.maxThumbPosition);
        var oldScrollPercent = this.scrollPercent;
        this.scrollPercent = thumbPosition / this.maxThumbPosition;
        var overviewPosition = (thumbPosition * this.maxOverviewPosition) / this.maxThumbPosition;

        this.setScrollPosition(overviewPosition, thumbPosition);
        if (oldScrollPercent != this.scrollPercent) {
          this.triggerCustomScroll(oldScrollPercent);
          return true;
        }
        else {
          return false;
        }
      },

      scrollOverviewTo: function (overviewPosition) {
        overviewPosition = this.positionOrMax(overviewPosition, this.maxOverviewPosition);
        var oldScrollPercent = this.scrollPercent;
        this.scrollPercent = overviewPosition / this.maxOverviewPosition;
        var thumbPosition = this.scrollPercent * this.maxThumbPosition;

        this.setScrollPosition(overviewPosition, thumbPosition);
        if (oldScrollPercent != this.scrollPercent) {
          this.triggerCustomScroll(oldScrollPercent);
          return true;
        }
        else {
          return false;
        }
      },

      thumbPosition: function () {
        return this.$thumb.position()[this.sizing.offsetComponent()];
      },

      scrollOverviewBy: function (delta) {
        var overviewPosition = this.overviewPosition() + delta;
        return this.scrollOverviewTo(overviewPosition);
      },

      overviewPosition: function () {
        return -this.scrollable.$el.position()[this.sizing.offsetComponent()];
      },

      positionOrMax: function (p, max) {
        if (p < 0)
          return 0;
        else if (p > max)
          return max;
        else
          return p;
      },

      triggerCustomScroll: function (oldScrollPercent) {
        this.scrollable.$container.trigger('customScroll', {
            scrollAxis: this.sizing.scrollAxis(),
            direction: this.sizing.scrollDirection(oldScrollPercent, this.scrollPercent),
            scrollPercent: this.scrollPercent * 100
          }
        );
      },

      rescroll: function (keepPosition) {
        var
          overviewPosition = 0,
          thumbPosition = 0;

        if (keepPosition) {
          overviewPosition = this.positionOrMax(this.overviewPosition(), this.maxOverviewPosition);
          this.scrollPercent = overviewPosition / this.maxOverviewPosition;

          thumbPosition = this.scrollPercent * this.maxThumbPosition;
          this.setScrollPosition(overviewPosition, thumbPosition);
        }
        else {
          thumbPosition = this.scrollPercent * this.maxThumbPosition;
          overviewPosition = this.scrollPercent * this.maxOverviewPosition;
          this.setScrollPosition(overviewPosition, thumbPosition);
        }
      },

      setScrollPosition: function (overviewPosition, thumbPosition) {
        this.$thumb.css(this.sizing.offsetComponent(), thumbPosition + 'px');
        this.scrollable.$el.css(this.sizing.offsetComponent(), -overviewPosition + 'px');
      },

      setScrollPositionWithAnimation: function (overviewPosition, thumbPosition) {
        var thumbAnimationOpts = {};
        var overviewAnimationOpts = {};
        thumbAnimationOpts[this.sizing.offsetComponent()] = thumbPosition + 'px';
        this.$thumb.animate(thumbAnimationOpts, 300);
        overviewAnimationOpts[this.sizing.offsetComponent()] = -overviewPosition + 'px';
        this.scrollable.$el.animate(overviewAnimationOpts, 300);
      },

      calculateMaxThumbPosition: function () {
        return this.sizing.size(this.$scrollbar) - this.thumbSize;
      },

      calculateMaxOverviewPosition: function () {
        var
          calculatedWidth = this.calculateTableWidth(),
          actualWidth = this.sizing.size(this.scrollable.$el),
          maxPosition = 0;

         // in some browsers(safari), outerWidth of the table is not calculated correctly
        if (parseInt(calculatedWidth - actualWidth) > 10 ){
          maxPosition = calculatedWidth - this.sizing.size(this.scrollable.$parent);
          this.adjustCaption(true, calculatedWidth);
        } else {
          maxPosition = this.sizing.size(this.scrollable.$el) - this.sizing.size(this.scrollable.$parent);
          this.adjustCaption(false);
        }

        return maxPosition;
      },

      setScrollEvent: function (event) {
        var attr = 'page' + this.sizing.scrollAxis();
        if (!this.scrollEvent || this.scrollEvent[attr] != event[attr])
          this.scrollEvent = {pageX: event.pageX, pageY: event.pageY};
      }
    };

    //----------------
    var
    HSizing = function () {};
    HSizing.prototype = {
      scrollingKeys: {
        37: function () {
          return -10; //arrow left
        },
        39: function () {
          return 10; //arrow right
        }
      },

      size: function ($el, arg) {
        if (arg)
          return $el.width(arg);
        else
          return $el.width();
      },

      minSize: function ($el) {
        return parseInt($el.css('min-width')) || 0;
      },

      scrollbar: function ($el, position) {
        return $el.$container.find('.' + $el.options.scrollbarClass + '.' + position);
      },

      mouseDelta: function (event1, event2) {
        return event2.pageX - event1.pageX;
      },

      offsetComponent: function () {
        return 'left';
      },

      wheelDelta: function (deltaX) {
        return deltaX;
      },

      scrollAxis: function () {
        return 'X';
      },

      scrollDirection: function (oldPercent, newPercent) {
        return oldPercent < newPercent ? 'right' : 'left';
      }
    };

    plugin.adjustTable = function () {
      if (!!plugin.$el.data('cbCustomScrollbar')) {
        if (!!plugin.options.scrollbarTop) {
          plugin.hScrollbarTop.resize();
        }

        if (!!plugin.options.scrollbarBottom ) {
          plugin.hScrollbarBottom.resize();
        }
      }
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      $('.' + plugin.options.scrollbarClass, plugin.$container).remove();
      plugin.$parent.unwrap();
      plugin.$parent.css({
        'width': '',
        'height': ''
      });
      // only remove ID if the plugin has added it
      if (plugin.$el.attr('id').indexOf('customScrollbar') >= 0) {
        plugin.$el.removeAttr('id');
      }

      $(window).off('resize.customScrollbar.' + plugin.id);
      plugin.$el.removeData('cbCustomScrollbar');
    };

    plugin.init();
  };

  $.fn.cbCustomScrollbar = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbCustomScrollbar')) {
          $(this).data('cbCustomScrollbar', new cb.apricot.customScrollbar(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbCustomScrollbar');
        if (instance instanceof cb.apricot.customScrollbar && typeof instance[options] === 'function') {
          instance[options].apply(instance, Array.prototype.slice.call(args, 1));
        }
      });
    }
  };

  //------------ Events
  var types = ['DOMMouseScroll', 'mousewheel'];

  if ($.event.fixHooks) {
    for (var i = types.length; i;) {
      $.event.fixHooks[ types[--i] ] = $.event.mouseHooks;
    }
  }

  $.event.special.mousewheel = {
    setup: function () {
      if (this.addEventListener) {
        for (var i = types.length; i;) {
          this.addEventListener(types[--i], handler, false);
        }
      } else {
        this.onmousewheel = handler;
      }
    },

    teardown: function () {
      if (this.removeEventListener) {
        for (var i = types.length; i;) {
          this.removeEventListener(types[--i], handler, false);
        }
      } else {
        this.onmousewheel = null;
      }
    }
  };

  $.fn.extend({
    mousewheel: function (fn) {
      return fn ? this.bind('mousewheel', fn) : this.trigger('mousewheel');
    },

    unmousewheel: function (fn) {
      return this.unbind('mousewheel', fn);
    }
  });

  function handler(event) {
    var orgEvent = event || window.event, args = [].slice.call(arguments, 1), delta = 0, deltaX = 0, deltaY = 0;
    event = $.event.fix(orgEvent);
    event.type = 'mousewheel';

    // Old school scrollwheel delta
    if (orgEvent.wheelDelta) {
      delta = orgEvent.wheelDelta / 120;
    }
    if (orgEvent.detail) {
      delta = -orgEvent.detail / 3;
    }

    // New school multidimensional scroll (touchpads) deltas
    deltaY = delta;

    // Gecko
    if (orgEvent.axis !== undefined && orgEvent.axis === orgEvent.HORIZONTAL_AXIS) {
      deltaY = 0;
      deltaX = delta;
    }

    // Webkit
    if (orgEvent.wheelDeltaY !== undefined) {
      deltaY = orgEvent.wheelDeltaY / 120;
    }
    if (orgEvent.wheelDeltaX !== undefined) {
      deltaX = orgEvent.wheelDeltaX / 120;
    }

    // Add event and delta to the front of the arguments
    args.unshift(event, delta, deltaX, deltaY);

    return ($.event.dispatch || $.event.handle).apply(this, args);
  }

  // Activate custom scrollbar for responsive tables
  // ==============
  $(window).on('load', function () {
    if (!!cb.apricot.setup.isCb('table')) {
      if ($('.table').length > 0) {
        // Activate for all responsive tables
        $('.table').each(function () {
          if ($(this).data('cb-element') !== 'no-cb') {
            if (!!$(this).parent().hasClass('table-responsive')){
              $(this).cbCustomScrollbar();
            }
          }
        });
        // trigger custom event
        $(document).trigger('cb_table_finished');
      }
    } else if (!!cb.apricot.setup.hasSelector('table')) {
      //apply CB's style to desired elements
      $.each(cb.apricot.setup.selector('table'), function(index, value) {
        if (!!$(value).parent().hasClass('table-responsive')){
          $(value).cbCustomScrollbar();
        }
      });
      // trigger custom event
      $(document).trigger('cb_table_finished');
    }
  });

}(jQuery, cb);
