/* ========================================================================
 * Apricot's Timed Modal Module
 * ========================================================================
 *
 * cb.apricot.cbTimedModal
 *
 * This module displays a modal after specific time frame
 * the functionality can be use to extend user session(server-side)
 * by calling an API service
 *
 * ======================================================================== */

+function ($, cb) {
  'use strict';
  function _cbTimedModal(opt) {
    var
      defaultOptions = {
        popupTime: 8,
        modalTime: 2,
        secondTime: 1,

        modalId: 'timedModal',
        externalModal: {},

        firstMsg: 'You have {{time}} minute(s) left to finish working on this page. Click OK to extend your time and avoid being logged out.',
        firstPrimaryBtn: 'OK',
        firstSecondaryBtn: 'Close',
        firstPrimaryAction: $.noop,
        firstSecondaryAction: $.noop,

        singleState: false,

        secondMsg: 'We are sorry, the time to work on this page has expired. Please click Ok to login again.',
        secondSecondaryBtn: 'Ok',
        secondSecondaryAction: $.noop,

        fade: true,
        close: false,
        backdrop: false
      },
      milliseconds = 60000,
      interval = {},
      $modal = {},
      options = {};

    function _init () {
      options = $.extend({}, defaultOptions, opt);
      // start counting down
      _startPopupTimer();
      if (!!$.isEmptyObject(options.externalModal)) {
        _buildModal();
      } else {
        $modal = options.externalModal;
        $modal.on('shown.bs.modal', function () {
          var
            time = parseInt(options.modalTime) * milliseconds;
            _timer(
              time, // milliseconds
              function() {
                $(document).trigger('cb_timedModal_finished');
              }
          );
        }).on('hidden.bs.modal', function () {
          clearInterval(interval);
        });
      }
    }

    function _buildModal () {
      var
        template = '',
        $template = {};

          // Build modal
      $modal = $('<div>', {
        'class': 'modal',
        'id': options.modalId,
        'tabindex': '-1',
        'role': 'dialog'
      });

      if (!!options.fade) {
        $modal.addClass('fade');
      }

      if (!options.backdrop) {
        $modal.attr('data-backdrop', 'static');
      }

      template = '<div class="modal-dialog">';
      template += '<div class="modal-content">';
      if (!!options.close) {
        template += '<div class="modal-header">';
        template += '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>';
        template += '</div>';
      }
      template += '<div class="modal-body">';
      template += '<p class="st-msg" id="stMainMessage">' + options.firstMsg.replace('{{time}}', options.modalTime) + '</p>';
      template += '</div>';
      template += '<div class="modal-footer">';
      template += '<button type="button" class="btn btn-sm btn-secondary st-secondary-btn" aria-describedby="stMainMessage">' + options.firstSecondaryBtn + '</button>';
      template += '<button type="button" class="btn btn-sm btn-primary st-primary-btn">' + options.firstPrimaryBtn + '</button>';
      template += '</div>';
      template += '</div>';
      template += '</div>';

      $template = $(template);
      $template.appendTo($modal);

      $modal.appendTo($('body'));
      _mainModalEvents();
    }

    function _startPopupTimer () {
      var
        time = parseInt(options.popupTime) * milliseconds;

      // Start main timer
      _timer(
          time, // milliseconds
          function() {
            $modal.modal('show');
          }
      );
    }

    //Events for main modal
    function _mainModalEvents () {
      var
        time = parseInt(options.secondTime) * milliseconds;

      $modal.on('shown.bs.modal', function () {
        // Trigger custom show event
        $(document).trigger('cb_timedModal_show_first');

        $('.st-primary-btn', $modal).focus();

        if (!options.singleState && options.secondTime > 0) {
          //Start second timer
          _timer(
            time, // milliseconds
            function() {
              $('.st-msg', $modal).html(options.secondMsg);
              $('.st-secondary-btn', $modal).html(options.secondSecondaryBtn);
              $('.st-primary-btn', $modal).remove();

              $(document).trigger('cb_timedModal_show_second');
              _secondModalEvents();
            }
          );
        } else {
          time = parseInt(options.modalTime) * milliseconds;
          _timer(
            time, // milliseconds
            function() {
              $(document).trigger('cb_timedModal_finished');
            }
          );
        }

        var
          $extend = $('.st-primary-btn', $modal),
          $ok = $('.st-secondary-btn', $modal);

        $extend.keydown(function (e) {
          if (!!cb.apricot.utils.isKey(e, 'TAB') && e.shiftKey) { //shift & tab
            e.preventDefault();
            $ok.focus();
          }
          if (!!cb.apricot.utils.isKey(e, 'TAB') && !e.shiftKey) { //tab
            e.preventDefault();
            $ok.focus();
          }
        });

        $ok.keydown(function (e) {
          if (!!cb.apricot.utils.isKey(e, 'TAB') && e.shiftKey) { //shift & tab
            e.preventDefault();
            $extend.focus();
          }
          if (!!cb.apricot.utils.isKey(e, 'TAB') && !e.shiftKey) { //tab
            e.preventDefault();
            $extend.focus();
          }
        });

       //main button function
        $extend.unbind('click');
        $extend.click( function (e) {
          e.preventDefault();

          // reset timer
          clearInterval(interval);
          _startPopupTimer();
          if($.isFunction(options.firstPrimaryAction)) {
            //call user provided method
            options.firstPrimaryAction.call($modal);
          }
        });

       //main button function
        $ok.unbind('click');
        $ok.click( function (e) {
          e.preventDefault();

          // Stop timer
          clearInterval(interval);
          if($.isFunction(options.firstSecondaryAction)) {
            //call user provided method
            options.firstSecondaryAction.call($modal);
          }
        });
      });
    }

    //Events for second modal state
    function _secondModalEvents () {
      var
        $ok = $('.st-secondary-btn', $modal),
        time = (parseInt(options.modalTime) - parseInt(options.secondTime)) * milliseconds;

      _timer(
        time, // milliseconds
        function() {
          $(document).trigger('cb_timedModal_finished');
        }
      );
     //second state, main button function
      $ok.unbind('click');
      $ok.click( function (e) {
        e.preventDefault();

        clearInterval(interval);
        if($.isFunction(options.secondSecondaryAction)) {
          //call user provided method
          options.secondSecondaryAction.call($modal);
        }
      });
      $('.st-secondary-btn', $modal).focus();
    }

    //Timer
    function _timer(time, complete) {
      var
        start = new Date().getTime();

      interval = setInterval(function() {
        var
          now = time - (new Date().getTime() - start);

        if( now <= 0) {
            clearInterval(interval);
            complete();
        }
      }, 100);
    }

    _init();
  }

  cb.apricot.cbTimedModal  = _cbTimedModal;
}(jQuery, cb);
