/* ========================================================================
 * Menu Bar
 * ========================================================================
 *
 * Style and behavior adjustment for Menu Bar
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  cb.apricot.menuBar = function (element, options) {
    var
      defaultOptions = {
        desktopClass: 'cb-desktop-menu-bar',
        mobileClass: 'cb-mobile-menu-bar',
        stickyClass: 'sticky-menu-bar',
        localNavClass: 'cb-local-navigation',
        menuName: 'Menu',
        menuHeight: 60
      },
      $desktopMenu = {},
      $mobileMenu = {},
      $localNav = {},
      hasLocalNav = false,
      localNavHeight = 0,
      navMenuTopPosition = 0,
      menuTopPosition = 0,
      activeItemName = '',
      plugin = this;

    plugin.$el = $(element);

    var
    getMenuItems = function () { //Populate dropDowns
      var
        mainItems = [];

      $('li', $desktopMenu).each(function () {
        var
          self = $(this);

        if (!self.hasClass('active')) {
          mainItems.push($(this).clone());
        } else {
          activeItemName = $('a', self).html();
        }
      });

      // build Mobile navigation
      buildMobileNav(mainItems);

      // add keyboard navigation
      addEvents();
    },

    addEvents = function () {
      $('a', $desktopMenu).each(function () {
        $(this).on('keydown', function (e) {
          var
            k = e.which || e.keyCode,
            self = $(this),
            $nextItem = {},
            $items = $('a', $desktopMenu),
            index = $items.index($items.filter(':focus'));


          //left/right/up/down
          if (!/(37|39|38|40)/.test(k)){
            return;
          }

          if (k === 37 || k === 38) {
            index--; //left, up
          }
          if (k === 39 || k === 40) {
            index++; //right, down
          }

          if(index < 0) {
            index = $items.length -1;
          }
          if(index === $items.length) {
            index = 0;
          }

          $nextItem = $items.eq(index);
          $nextItem.focus();

          e.preventDefault();
          // e.stopPropagation();

        });
      });
    },

    buildMobileNav = function (items) {
      var
        idValue = cb.apricot.utils.uniqueID(5, 'apricot_'),
        ariaLabel = $('.' + plugin.options.desktopClass, plugin.$el).attr('aria-label');

      $mobileMenu = $('<nav/>')
        .addClass(plugin.options.mobileClass)
        .addClass('container')
        .addClass('hidden')
        // .attr('role', 'navigation')
        .attr('aria-label', ariaLabel)
        .insertAfter($desktopMenu);

      var $row = $('<div/>')
        .addClass('row')
        .appendTo($mobileMenu);

      var $dropdown = $('<div/>')
        .addClass('dropdown')
        .addClass('cb-dropdown')
        .appendTo($row);

      var $a = $('<a/>')
        .addClass('dropdown-toggle')
        .attr('data-toggle', 'dropdown')
        .attr('href', '#')
        .attr('role', 'button')
        .attr('aria-haspopup', true)
        .attr('aria-expanded', false)
        .attr('id', idValue)
        .appendTo($dropdown);

      var $span = $('<span/>')
        .html(plugin.options.menuName)
        .appendTo($a);

      if (activeItemName !== '') {
        $span.html(activeItemName);
      }

      $('<i/>')
        .addClass('cb-glyph')
        .addClass('cb-down')
        .attr('aria-hidden', true)
        .appendTo($a);

      $('<i/>')
        .addClass('cb-glyph')
        .addClass('cb-up')
        .addClass('hidden')
        .attr('aria-hidden', true)
        .appendTo($a);

      var $ul = $('<ul/>')
        .addClass('dropdown-menu')
        .attr('aria-labelledby', idValue);

      for (var item in items) {
        items[item].appendTo($ul);
      }

      $ul.appendTo($dropdown);

       // set accessibility for dropdowns
      $dropdown.cbDropdownExtension();
    },

    breakpointChanges = function () {
      var
        eTop = plugin.$el.offset().top;

      // check desktop vs. mobile
      checkMenuStatus();

      //Check breakpoint status on resize
      $(window).on('resize.cbmenuBar', function () {
        checkMenuStatus();
      });

      // activate sticky header
      $(window).on('scroll.cbMenuBar', function() {
        var
          bPos = 0;

        // logic to handel Local Nav + Anchor menu
        if (!!hasLocalNav) {
          if ($(this).scrollTop() > navMenuTopPosition){
            if ($(this).scrollTop() > menuTopPosition) {
              plugin.$el.addClass(plugin.options.stickyClass);
            } else {
              bPos = eTop  - $(window).scrollTop();
              $localNav.css('top', (bPos -localNavHeight) + 'px');
            }
          } else{
            plugin.$el.removeClass(plugin.options.stickyClass);
            $localNav.css('top', '0');
          }
        } else { // No local Nav, just stick
          if ($(this).scrollTop() > menuTopPosition){
            plugin.$el.addClass(plugin.options.stickyClass);
          } else{
            plugin.$el.removeClass(plugin.options.stickyClass);
          }
        }
      });
    },

    // figure out which structure we need (mobie/desktop)
    checkMenuStatus = function () {
      var
        currentViewport = cb.apricot.utils.viewport();

      changeMenuStructure(currentViewport.width);
    },

    // switch between mobie/desktop structure
    changeMenuStructure = function (width) {
      var
        maxWidth = cb.apricot.data.viewports.tablet.max;

      if (maxWidth === 0) {
        return false;
      }

      if (width <= maxWidth) {
        switchNavigation(1);
      } else {
        switchNavigation(0);
      }
    },

    //0: desktop
    //1: mobile
    switchNavigation = function (mode) {
      if (!!mode) {
        $desktopMenu.addClass('hidden');
        $mobileMenu.removeClass('hidden');
      } else {
        $mobileMenu.addClass('hidden');
        $desktopMenu.removeClass('hidden');
      }
    };

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, options);

      menuTopPosition = parseInt(plugin.$el.offset().top, 10);
      hasLocalNav = ($('.' + plugin.options.localNavClass).length > 0) ? true : false;

      if (!!hasLocalNav) {
        $localNav = $('.' + plugin.options.localNavClass);
        localNavHeight = $localNav.height();
        navMenuTopPosition = menuTopPosition - localNavHeight;
      }

      $desktopMenu = $('.' + plugin.options.desktopClass, plugin.$el);
      getMenuItems();

      // anchore band is ready
      plugin.$el.trigger('finished.cbMenuBar');

      // track breakpoint changes
      breakpointChanges();
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      $mobileMenu.remove();
      plugin.$el.removeClass('.' + plugin.options.stickyClass);

      $(window).off('resize.cbMenuBar');
      $(window).off('scroll.cbMenuBar');
      plugin.$el.removeData('cbMenuBar');
    };

    plugin.init();
  };

  $.fn.cbMenuBar = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbMenuBar')) {
          $(this).data('cbMenuBar', new cb.apricot.menuBar(this, options));
        }
       });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbMenuBar');
        if (instance instanceof cb.apricot.menuBar && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call(args, 1) );
        }
      });
    }
  };
}(jQuery, cb);
