/* ========================================================================
 * Print Background Image
 * ========================================================================
 *
 * $.fn.cbPrintBackgroundImage
 *
 * Add image tag to element for print mode
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  cb.apricot.printBackgroundImage = function (element, options) {
    var
    defaultOptions = {
      image: '',
      className: 'no-screen',
      width: 0,
      height: 0,
      onFinish: {},
    },
    plugin = this;

    plugin.$el = $(element);
    plugin.id = cb.apricot.utils.uniqueID(10, 'printBkImage_');
    plugin.image = '';

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, plugin.$el.data(), options);

      plugin.image = (plugin.options.image !== '') ?
                      plugin.options.image :
                      (!!plugin.$el.css('background-image') ?
                        plugin.$el.css('background-image').replace(/(url\(|\)|'|")/gi, '') :
                         '' );

      if (plugin.image !== '' || plugin.image !== 'none') {
        addImage();
      }
    };


    var
    addImage = function () {
    	var
    		dimension = {};

    	dimension.width = (plugin.options.width > 0) ? plugin.options.width : plugin.$el.width();
    	dimension.height = (plugin.options.height > 0) ? plugin.options.height : plugin.$el.height();

      var $img = $('<img>', {
      	'class': plugin.options.className,
      	'src': plugin.image,
      	'id': plugin.id
      }).css ({
      	'width': dimension.width + 'px',
      	'height': dimension.height + 'px'
      });

      plugin.$el.prepend($img);

      if($.isFunction(plugin.options.onFinish)) {
        plugin.options.onFinish.call(plugin.$el); //call callback
      }

    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      plugin.$el.removeData('cbPrintBackgroundImage');
      $('#' + plugin.id, plugin.$el).remove();
    };

    plugin.init();
  };

  $.fn.cbPrintBackgroundImage = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbPrintBackgroundImage')) {
          $(this).data('cbPrintBackgroundImage', new cb.apricot.printBackgroundImage(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbPrintBackgroundImage');
        if (instance instanceof cb.apricot.printBackgroundImage && typeof instance[options] === 'function') {
          instance[options].apply(instance, Array.prototype.slice.call(args, 1));
        }
      });
    }
  };
}(jQuery, cb);
