/* ========================================================================
 * Loader, Progress Bar
 * ========================================================================
 *
 * $.fn.cbProgressBar
 *
 * Progress animation for Apricot's Progress Bar
  * ======================================================================== */

+function ($, cb) {
  'use strict';

  cb.apricot.progressBar = function (element, options) {
    var
    defaultOptions = {
      targetPos: 100,
      scale: 100,
      speed: 5000,
      loop: false,
      barClass: 'progress-bar',
      onFinishMoving: $.noop
    },
    plugin = this,
    $bar = {},
    $sr = {};

    plugin.$el = $(element);

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, plugin.$el.data(), options);
      plugin.options.targetPos = calculateScale(plugin.options.targetPos);

      $bar = $('.' + plugin.options.barClass, plugin.$el);

      // accessibility rules
      $bar.attr('aria-valuemin', 0);
      $bar.attr('aria-valuemax', convertScale(plugin.options.targetPos));
      if ($('.sr-only', plugin.$el).length > 0) {
        $sr = $('.sr-only', plugin.$el);
      } else {
        $sr = $('<span>', {'class': 'sr-only'});
        $bar.append(plugin.$sr);
      }

      // start animation
      if (!!plugin.options.loop) {
        animateBar();
      } else {
        // start progress
        progressBar();
      }
    };

    var
    // animate progress
    progressBar = function(){
      // reset bar
      $bar.css('width', 0);

      $bar.animate({
        width: plugin.options.targetPos + '%'
      }, {
        duration: plugin.options.speed,
        step: function(now, fx) {
          var
            value = convertScale(Math.round(now));

          $sr.html(value);
          $bar.attr('aria-valuenow', value);
        },
        complete: function() {
          // Animation complete
          if($.isFunction(plugin.options.onFinishMoving)) {
            plugin.options.onFinishMoving.call($bar); //call callback
          }
        }
      });
    },

    // infinite loop
    animateBar = function () {
      // accessibility rules
      $bar.attr('aria-valuetext', cb.apricot.lang.get('progressBar1'));
      $sr.html(cb.apricot.lang.get('progressBar1'));

      // reset bar
      $bar.css('width', 0);

      $bar.animate({
          width: '100%'  //bar width is always 100%
      }, {
        duration: plugin.options.speed,
        complete: function() {
          animateBar();
        }
      });
    },

    convertScale = function(degrees){
      var divider = 100 / plugin.options.scale;

      return (Math.floor(degrees / divider));
    },

    calculateScale = function(position) {
      var multiplier = 100 / plugin.options.scale;

      return (Math.ceil(position * multiplier));
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      var
        $bar = $('.' + plugin.options.barClass, plugin.$el);

      $bar.stop(true, true);
      $bar.css('width', 0);
      if (!!plugin.options.loop) {
        $bar.removeAttr('aria-valuetext');
      } else {
        $bar.removeAttr('aria-valuemin');
        $bar.removeAttr('aria-valuemax');
        $bar.removeAttr('aria-valuenow');
      }

      $('.sr-only', plugin.$el).html('');
      plugin.$el.removeData('cbProgressBar');
    };

    plugin.init();
  };

  $.fn.cbProgressBar = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbProgressBar')) {
          $(this).data('cbProgressBar', new cb.apricot.progressBar(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbProgressBar');
        if (instance instanceof cb.apricot.progressBar && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
        }
      });
    }
  };
}(jQuery, cb);
