/*!
 *  Apricot v3.4.0
 *  By:             The College Board
 *  App:            apricot
 *  Build Time:     2019-04-02 [10:28:53 AM] EDT
 *  Build Number:   unknown
 *  SVN Revision:   unknown
 *  Jenkins Job:    unknown
 *  This version of Apricot includes Bootstrap v3.7.0
 */

/* ========================================================================
 * Tabs Extension
 * ========================================================================
 *
 * This extension removes aria-expanded and adds aria-selected instead
 * also adds left/right key navigation for tabs
 * ======================================================================== */

+function ($) {
  'use strict';

  if (!$.fn.tab) throw new Error('tab-extension requires tab.js');

  $.fn.tab.Constructor.prototype.activate = function (element, container, callback) {

    var $active    = container.find('> .active')
    var transition = callback
      && $.support.transition
      && (($active.length && $active.hasClass('fade')) || !!container.find('> .fade').length)

      if (!!element.hasClass('disabled')) {
        var $a = $('a', element);
        
        if (!!$a.attr('href')) {
          var c = $a.attr('href').replace('#', '')

          $('#'+ c).addClass('disabled')
        }

        return false;
      }
    function next() {


      // Reset
      $active
        .removeClass('active')
        .find('> .dropdown-menu > .active')
          .removeClass('active')
        .end()
        .find('[data-toggle="tab"]:not(li)')
          .attr('aria-selected', false)
        .end()
        .find('[data-toggle="dropdown"]')
          .attr('aria-selected', false)
        .end()
        .find('.dropdown-menu a[data-toggle="tab"]')
          .attr('aria-current', false)
          .removeAttr('aria-selected')
          .end()

      element
        .addClass('active')
        .find('[data-toggle="tab"]')
          .attr('aria-selected', true)
          
      if (transition) {
        element[0].offsetWidth // reflow for transition
        element.addClass('in')
      } else {
        element.removeClass('fade')
      }

      if (element.parent('.dropdown-menu')) {
        element
          .closest('li.dropdown')
            .addClass('active')
          .end()
      }

      // Accessibility
      // Active tab/item
      container.find('[data-toggle="tab"]:not([data-toggle="dropdown"])').each (function () {
        var self = $(this),
        controls = (!!self.attr('aria-controls')) ? self.attr('aria-controls') : self.attr('data-cb-controls')

        if (!!self.parent().hasClass('active')) {
          self.attr('aria-controls', controls)
        }
      })


      // in-Active tab/item
      container.find('[data-toggle="tab"]:not([data-toggle="dropdown"])').each (function () {
        var self = $(this);
        if (!self.parent().hasClass('active')) {
          self.attr('aria-controls', '')
        }
      })

      
      // dropdowns
      container.find('> li.active').each (function () {
        var self = $(this)

        if (!!self.hasClass('dropdown')){
          var $ul = $('ul.dropdown-menu', self),
          $a = $('li.active a[role="menuitem"]', $ul),
          controls = (!!$a.attr('aria-controls')) ? $a.attr('aria-controls') : $a.attr('data-cb-controls')

          $a.attr('aria-controls', controls)
            .attr('aria-current', true)
            .removeAttr('aria-selected')

          $('a.dropdown-toggle', self)
            .attr('aria-selected', true)
            .attr('aria-controls', controls)
        }
      });

      callback && callback()
    }

    $active.length && transition ?
      $active
        .one('bsTransitionEnd', next)
        .emulateTransitionEnd(Tab.TRANSITION_DURATION) :
      next()

    $active.removeClass('in')
  };

  $.fn.tab.Constructor.prototype.keydown = function (e) {
    var
      $this = $(this),
      $items,
      $tablistContainer = $this.closest('[role=tablist] '),
      index,
      k = e.which || e.keyCode;

    $this = $(this);
    //left/right
    if (!/(37|39)/.test(k)){
      return;
    }

    $items = $tablistContainer.find('[role=tab]:visible, [role=button][data-toggle=dropdown]').not('ul ul li a');
    index = $items.index($items.filter(':focus'));

    if (k === 37) {
      index--; //left
    }
    if (k === 39) {
      index++; //right
    }

    if(index < 0) {
      index = $items.length -1;
    }
    if(index === $items.length) {
      index = 0;
    }

    var $nextTab = $items.eq(index);
    if($nextTab.attr('role') === 'tab' || ($nextTab.attr('role') === 'button' && $nextTab.attr('data-toggle') === 'dropdown')) {
      $nextTab.focus();
    }

    e.preventDefault();
    e.stopPropagation();
  };

  $(document).on('keydown.tab.data-api','[data-toggle="tab"], [data-toggle="dropdown"]' , $.fn.tab.Constructor.prototype.keydown);

  $(window).on('load', function() {
    $('a[data-toggle="tab"]:not([role="menuitem"])').each( function () {
      var self = $(this);

      if (!!self.parent().hasClass('active')) {
        self.attr('aria-selected', true)
          .attr('data-cb-controls', self.attr('aria-controls'))
      } else {
        self.attr('data-cb-controls', self.attr('aria-controls'))
          .attr('aria-controls', '')
          .attr('aria-selected', false)
      }
    });

    $('a[data-toggle="tab"][role="menuitem"]').each( function () {
      var self = $(this);

      if (!!self.parent().hasClass('active')) {
        self.attr('aria-current', true)
          .attr('data-cb-controls', self.attr('aria-controls'))
      } else {
        self.attr('data-cb-controls', self.attr('aria-controls'))
          .attr('aria-controls', '')
          .attr('aria-current', false)
      }
    });

    // in-Active tab/dropdown
    $('.nav-tabs [data-toggle="dropdown"]').each (function (index) {
      var self = $(this);
      
      if (!self.parent().hasClass('active')) {
        self.attr('aria-selected', false)
      }
    })


    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
      var
        panelId = $(e.target).attr('href'),
        $tbl = $('table', $(panelId));

      if ($tbl.length > 0) {
        if (!!$tbl.data('cbCustomScrollbar')) {
          $tbl.cbCustomScrollbar('adjustTable');
        }
      }
    });
  });
}(jQuery);
