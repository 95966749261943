/* ========================================================================
 * Hero
 * ========================================================================
 *
 * Style and behavior adjustment for Hero elements
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  $(window).on('load', function () {

    // IE 11, min-height bug
    cb.apricot.utils.addClassIE($('body'));
    $('.cb-hero').each( function () {
      var self = $(this),
      $btn = $('.btn', self);

      if ($btn.length <= 0) {
        self.addClass('cb-hero-no-btn');
      }
    });
  });
}(jQuery, cb);
