/* ========================================================================
 * Local Navigation 2.0
 * ========================================================================
 *
 * $.fn.cbLocalNavigationSplit
 *
 * This plugin adds responsive and accessible functionality to CB's
 * local navigation following Apricot's grid system
 *
 * ======================================================================== */

+ function ($, cb) {
  'use strict';

  cb.apricot.localNavSplit = function (element, options) {
    var
      defaultOptions = {
        desktopClass: 'cb-desktop-navigation',
        mobileClass: 'cb-mobile-navigation',
        mobileHeaderClass: 'cb-mobile-local-header',
        mobilePanelClass: 'cb-mobile-local-panels',
        panelClass: 'cb-menu-panel',

        siteNameClass: 'cb-site-name',
        programNameClass: 'cb-program-name',
        multiLineClass: 'cb-multi',
        anchorMenuClass: 'cb-anchor-menu',

        megaMenuGrid: 4,

        addDesktopParentLabel: true,
        addMobileParentLabel: false,

        desktopLayoutOnly: false,

        desktopHomeLabel: 'Home',
        desktopLabelPrefix: true,

        mobileHomeLabel: 'Home',
        mobileLabelPrefix: true,

        customHomeLabel: '',

        moreLabel: 'More',

        mobilePanelTopOffset: 0,
        mobileMenuHeight: 54,

        activePage: false,
        activePageType: true,

        moreFeature: true,
        mobileLayout: 'tablet'
      },
      plugin = this,
      $menu = {},
      $desktopMenu = {},
      $mobileMenu = {},

      $slideDownMenu = {},

      $more = {},
      menuItems = [],
      mainItems = [],
      linkItems = [],
      panels = [],
      nestedPanelArr = [],
      parentCount = 0,
      ariaLabel = '',
      navigationTopPosition = 0;

    plugin.$el = $(element);
    plugin.el = element;

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, plugin.$el.data(), options);
      var
        $link = {},
        children = [],
        item = {},
        parentId = 1;

      // get all items in the local navigation
      $menu = $('.cb-promotional, .cb-procedural', plugin.$el)
      $desktopMenu = $('nav', plugin.$el);

      navigationTopPosition = parseInt(plugin.$el.offset().top, 10);

      // this is needed for accessibility
      ariaLabel = !!$desktopMenu.attr('aria-label') ? $desktopMenu.attr('aria-label') : '';

      $('ul > li', plugin.$el).each(function () {
        var
          $li = $(this),
          $parent = $li.parent();

        item = {};
        item.obj = $li;
        item.width = parseInt(getWidth($(this)), 10);

        if (!$li.attr('data-cb-list')) {
          if ($('ul', $li).length > 0) {
            $li.attr('data-cb-parent', parentId);
            item.type = 'nested';
            item.parent = parentId;

            item.list = getChildrenList($li, parentId);

            if (item.list.length > 0) {
              parentId += parentCount + 1;
              parentCount = 0;
            }
          } else {
            item.type = 'simple';
          }

          menuItems.push(item);

          // for more slide-down
          if (!$parent.hasClass('cb-promotional')) {
            mainItems.push(item);
          }
        }
      });


      $('[data-cb-list="true"]', plugin.$el).removeAttr('data-cb-list');

      // get URLs for active menu item
      if (!!plugin.options.activePage) {
        for (var item in menuItems) {
          var
            li = menuItems[item],
            self = li.obj;

          item = {};
          if (!!self.hasClass('slidedown')) { //nested navigation
            children = [];

            $('a', self).each(function () {
              var
                $child = $(this);

              item = {};
              item.type = 'child';
              item.obj = $child;
              item.url = $child.attr('href');
              children.push(item);
            });

            $link = $('a', self);
            item.type = 'nested';
            item.obj = self;
            item.url = $link.attr('href');
            item.list = children;
          } else {
            $link = $('a', self);
            item.type = 'single';
            item.obj = $link.parent();
            item.url = $link.attr('href');
            item.list = [];
          }

          if (!$.isEmptyObject(item)) {
            linkItems.push(item);
          }
        }
      }

      //Add additional link for parent item
      if (!!plugin.options.addDesktopParentLabel) {
        addAdditionalTopLinks();
      }

      // build Mobile navigation together
      if (!plugin.options.desktopLayoutOnly) {
        buildMobileNavigation();
      }

      // build more slide-down
      if (!!plugin.options.moreFeature) {
        buildMoreSlidedown();
      }

      // build slide-down container
      slideDownMenuBuild();

      // hover/focus
      if ($menu.length > 0) {
        addExtraClass($('a', $menu[0]));
      }
      if ($menu.length >= 1) {
        addExtraClass($('a', $menu[1]));
      }
      addExtraClass($('a.title', plugin.$el));

      // track breakpoint changes
      if (!plugin.options.desktopLayoutOnly) {
        breakpointChanges();
      }

      // set accessibility for slidedown
      $('.slidedown', plugin.$el).not('.panel-sub').cbSlidedownExtension();

      // add class to active menu item
      if (!!plugin.options.activePage) {
        setActivePage();
      }
    };

    var
      breakpointChanges = function () {

        var
          currentViewport = cb.apricot.utils.viewport();

        checkMenuStatus();

        //Check breakpoint status on resize
        $(window).on('resize.cbLocalNavigationSplit', function () {
          checkMenuStatus();

          if (!$mobileMenu.hasClass('hidden')) {
            calculateMobileNavHeight();
          }
        });

        // check sticky header option
        plugin.checkSticky();
      },

      // find nested list items
      getChildrenList = function ($elm, parentId) {
        var
          list = [],
          item = {};

        $('ul:first', $elm).children().each(function () {
          var
            self = $(this);

          item = {};
          item.obj = self.clone();
          item.child = parentId;
          item.list = getChildrenList(self, parentId + 1);

          if (item.list.length > 0) {

            item.parent = parentId + 1;
            parentCount += 1;
          }

          list.push(item);
          self.attr('data-cb-list', true);
        });

        return list;
      },

      // figure out what the main structure we need (mobie/desktop)
      checkMenuStatus = function () {
        var
          currentViewport = cb.apricot.utils.viewport();

        changeMenuStructure(currentViewport.width);

        if (plugin.options.mobileLayout === 'tablet') {
          if (currentViewport.prefix !== 'sm' && currentViewport.prefix !== 'xs' && !!plugin.options.moreFeature) {
            checkMoreStatus();
          }
        } else if (plugin.options.mobileLayout === 'mobile') {
          if (currentViewport.prefix !== 'xs' && !!plugin.options.moreFeature) {
            checkMoreStatus();
          }
        }
      },

      // Add Link for main items
      // 1: main
      // 0: sub
      addMobileHomeLink = function (mode, label, href) {
        var
          $item = {},
          $a = {},
          $span = {},
          labelText = !!mode ? plugin.options.mobileHomeLabel : ((!!plugin.options.mobileLabelPrefix) ? plugin.options.mobileHomeLabel + ' ' + label : label + ' ' + plugin.options.mobileHomeLabel),
          hrefValue = !!mode ? $('a.' + plugin.options.programNameClass, plugin.$el).attr('href') : href,
          customLbl = false;

        // Only add if parent has an actual URL/Hash value
        if (cb.apricot.utils.isBlank(hrefValue) || hrefValue === '#') {
          return {};
        }

        if (plugin.options.customHomeLabel !== '') {
          labelText = plugin.options.customHomeLabel;
          customLbl = true;
        }

        $item = $('<li/>');

        $a = $('<a/>')
          .attr('href', hrefValue)
          .attr('tabindex', 0)
          .appendTo($item);

        $span = $('<span/>')
          .html(labelText)
          .appendTo($a);

        if (!!customLbl) {
          $('<span/>')
            .addClass('sr-only')
            .html(' ' + label)
            .appendTo($a);
        }

        return $item;
      },

      // find obj in object array
      findObj = function (items, attr, value) {
        for (var item in items) {
          if (items[item][attr] === value) {
            return items[item];
          }
        }

        return {};
      },

      // calculate sub-panel ID
      checkParentId = function (obj) {
        var
          tmp = findObj(nestedPanelArr, 'id', obj.parent),
          count = 0,
          removeIndex = 0;

        if (!!$.isEmptyObject(tmp)) {
          tmp.id = obj.parent;
          tmp.count = 1;
          nestedPanelArr.push(tmp);
        } else {
          removeIndex = nestedPanelArr.map(function (item) {
            return item.id;
          }).indexOf(obj.parent);
          nestedPanelArr.splice(removeIndex, 1);
          count = parseInt(tmp.count) + 1;
          tmp.count = count;
          nestedPanelArr.push(tmp);
        }

        return tmp.count;
      },

      // generate HTML for Mobile navigation
      buildMobileNavigation = function () {
        var
          $divHeader = {},
          $divPanel = {},
          $item = {},
          $a = {},
          $span = {},
          $program = $('.' + plugin.options.programNameClass, plugin.$el),
          mainPanelItems = [],
          count = 0;

        // Mobile elements don't exist
        if (!!$.isEmptyObject($mobileMenu)) {
          $mobileMenu = $('<nav/>')
            .addClass(plugin.options.mobileClass)
            .addClass('hidden')
            // .attr('role', 'navigation')
            .appendTo(plugin.$el);

          if (!!$('.' + plugin.options.siteNameClass, plugin.$el).hasClass(plugin.options.multiLineClass)) {
            $mobileMenu.addClass(plugin.options.multiLineClass);
          }

          $divHeader = $('<div/>')
            .addClass(plugin.options.mobileHeaderClass)
            .appendTo($mobileMenu);

          $divPanel = $('<div/>')
            .addClass(plugin.options.mobilePanelClass)
            .appendTo($mobileMenu);

          $a = $('<a/>')
            .addClass('local-menu-title')
            .attr('tabindex', 0)
            .attr('data-cb-header-title', $('span', $program).html())
            .attr('href', $($program).attr('href'))
            .html($program.html())
            .appendTo($divHeader);

          $a = $('<a/>')
            .addClass('local-menu-icon')
            .attr('href', '#')
            .attr('tabindex', 0)
            .attr('data-cb-panel-for', 'panel-main')
            .attr('data-cb-panel-nesting', '0')
            .html('<span class="sr-only">' + cb.apricot.lang.get('localNav1') + '</span>')
            .appendTo($divHeader);

          var $icon = $('<span />')
            .addClass('cb-glyph')
            .addClass('cb-down')
            .addClass('cb-menu')
            .attr('aria-hidden', 'true');

          $a.append($icon);

          // trigger custom event
          $a.on('click.cbLocalNavigationSplit', function () {

            triggerCustomEvents(2, $(this));
          });


          // add tab order for accessibility
          $a.on('keydown.cbLocalNavigationSplit', function (e) {
            if (!!cb.apricot.utils.isKey(e, 'TAB') && e.shiftKey) { //shift & tab
              e.preventDefault();

              $('.local-menu-title', plugin.$el).focus();
            }

            if (!!cb.apricot.utils.isKey(e, 'TAB') && !e.shiftKey) { //tab
              e.preventDefault();

              var $panel = $('.' + plugin.options.panelClass + '.show', plugin.$el).last();


              if (!!$(e.target).hasClass('local-menu-icon')) {
                // No panel is open
                if ($('.' + plugin.options.panelClass + '.show', plugin.$el).length > 0) {
                  $('a', $panel).first().focus();
                } else {
                  $('.' + plugin.options.mobilePanelClass, $mobileMenu).find('a').last().focus();
                }
              } else {
                $('a', $panel).first().focus();
              }
            }
          });

          for (var item in menuItems) {
            var
              obj = menuItems[item],
              $elm = obj.obj,
              $clone = $elm.clone();

            if (obj.type === 'nested') {
              $item = {};

              count = checkParentId(obj);
              $item = $('<li/>')
                .attr('data-cb-panel-for', 'panel-sub-' + obj.parent + '-' + count)
                .addClass('panel-sub')
                .addClass('mobile-dropdown');

              $a = $('<a/>')
                .attr('href', $('a', $elm).attr('href'))
                .attr('tabindex', 0)
                .appendTo($item);

              $span = $('<span/>')
                .html($('span', $elm).first().html())
                .appendTo($a);

              $('<span/>')
                .addClass('sr-only')
                .addClass('cb-sub-items')
                .html('has sub-navigation items')
                .appendTo($a);


              mainPanelItems.push($item);

              // find all nested items
              subPanelItems(obj);
            } else if (obj.type === 'simple') {

              mainPanelItems.push($clone);
            }
          }

          buildMenuPanel(mainPanelItems, 'main');
          // order panels
          panels.sort(function (a, b) {
            return parseFloat(a.order) - parseFloat(b.order);
          });
          for (var item in panels) {
            panels[item].panel.appendTo($divPanel);
          }
          mobileEvents();
          $(document).trigger('localnavsplit_mobileLayout_finished', new Date());
        }
      },

      // put together Main subPanelItems
      subPanelItems = function (object) {
        var
          panelItems = [],
          items = object.list,
          $elm = object.obj,
          $item = {},
          $span = {},
          $a = {},
          label = '',
          href = '',
          count = 0;

        label = $('span:first', $elm).html();
        href = $('a:first', $elm).attr('href');

        if (!!plugin.options.addMobileParentLabel) {
          $elm = addMobileHomeLink(0, label, href);
          if (!$.isEmptyObject($elm)) {
            panelItems.push($elm);
          }
        }

        for (var item in items) {
          var
            obj = items[item];

          $elm = {};
          $elm = obj.obj;

          if (obj.list.length > 0) {
            $item = {};
            count = checkParentId(obj);

            $item = $('<li/>')
              .attr('data-cb-panel-for', 'panel-sub-' + obj.parent + '-' + count)
              .addClass('panel-sub')
              .addClass('mobile-dropdown');

            $a = $('<a/>')
              .attr('href', $('a', $elm).attr('href'))
              .attr('tabindex', 0)
              .appendTo($item);

            $span = $('<span/>')
              .html($('span', $elm).first().html())
              .appendTo($a);

            $('<span/>')
              .addClass('sr-only')
              .addClass('cb-sub-items')
              .html('has sub-navigation items')
              .appendTo($a);

            panelItems.push($item);

            subPanelItems(obj);
          } else {
            panelItems.push($elm);
          }
        }

        if (panelItems.length > 0) {
          buildMenuPanel(panelItems, object.parent);
        }
      },

      // build HTML for Mobile sliding panels
      buildMenuPanel = function (items, panel) {
        var
          $ul = {},
          $back = {},
          item = {},
          count = findObj(nestedPanelArr, 'id', panel).count,
          dataPanel = (!!isNaN(panel)) ? 'panel-main' : 'panel-sub-' + panel,
          nesting = (panel === 'main') ? 0 : panel,
          $panel = $('<div/>')
          .addClass(plugin.options.panelClass)
          .attr('data-cb-panel', dataPanel)
          .attr('data-cb-panel-nesting', nesting);

        if (dataPanel !== 'panel-main') {
          $panel.attr('data-cb-panel', dataPanel + '-' + count);
        }

        $ul = $('<ul/>')
          .appendTo($panel)
          .addClass('link-panel');


        if (dataPanel === 'panel-main') {
          $ul.addClass('first-panel');

          //  Close
          var $closeBtn = $('<a/>')
            .addClass('local-menu-icon')
            .attr('href', '#')
            .attr('tabindex', 0)
            .html('<span class="sr-only">' + cb.apricot.lang.get('localNav3') + '</span>')

          var $icon = $('<span />')
            .addClass('cb-glyph')
            .addClass('cb-down')
            .addClass('cb-x-mark')
            .attr('aria-hidden', 'true');

          $closeBtn.append($icon);
          $ul.append($('<li />', {
            'class': 'cb-close-home'
          }).append($closeBtn))

          $closeBtn.on('click', function (e) {
            e.preventDefault()

            triggerCustomEvents(0, $closeBtn);
            plugin.mobileCloseAll();
          })
        }

        if (dataPanel !== 'panel-main') {
          $back = $('<li/>', {
            'class': 'cb-nav-back'
          })

          $ul
            .append($back)
            .append(
              $('<li />', {
                'class': 'cb-mobile-panel-title'
              })
            )
        }

        for (var item in items) {
          $ul.append(items[item]);
        }

        item = {};
        item.order = (!isNaN(panel)) ? panel : 0;
        item.panel = $panel;

        panels.push(item);
      },

      // Calculate mobile panel height
      calculateMobileNavHeight = function () {
        var
          pageHeight = $(window).height(),
          activePanleHeight = 0,
          navigationHeight = 0,
          $panelsBlock = $('.' + plugin.options.mobilePanelClass, $mobileMenu);

        activePanleHeight = calculateMobileItemsHeight();
        navigationHeight = (pageHeight > activePanleHeight) ? pageHeight : activePanleHeight;

        $('.' + plugin.options.panelClass, plugin.$el).css('min-height', navigationHeight);

        if (navigationHeight > pageHeight) {
          $panelsBlock.addClass('cb-overflow-y');
        } else {
          $panelsBlock.removeClass('cb-overflow-y');
        }

        // panels are open
        if (!!$('[data-cb-panel="panel-main"]', plugin.$el).hasClass('show')) {
          setBodyRestrictions();
        }
      },

      // Calculate mobile items height
      calculateMobileItemsHeight = function () {
        var
          $activeOpenPanel = $('.' + plugin.options.panelClass + '.show', plugin.$el),
          $links = $('.link-panel li', $activeOpenPanel.last()),
          itemHeight = parseInt($links.first().height(), 10),
          panelHeight = ($links.length * itemHeight) + navigationTopPosition;


        panelHeight += plugin.$el.height();

        return (!!$.isNumeric(panelHeight)) ? panelHeight : 0;
      },

      // switch between mobie/desktop structure
      changeMenuStructure = function (width) {
        var
          maxWidth = (plugin.options.mobileLayout === 'tablet') ? cb.apricot.data.viewports.tablet.max : ((plugin.options.mobileLayout === 'mobile') ? cb.apricot.data.viewports.mobile.max : 0);

        if (maxWidth === 0) {
          return false;
        }

        if (width <= maxWidth) {
          resetMoreSlidedown();
          resetSlideDown();
          switchNavigation(0);
        } else if (!$.isEmptyObject($mobileMenu)) {
          switchNavigation(1);
        }
      },

      //0: desktop
      //1: mobile
      switchNavigation = function (mode) {
        resetBody();
        if (!!mode) {
          $desktopMenu.removeClass('hidden');
          if (!cb.apricot.utils.isBlank(ariaLabel)) {
            $desktopMenu.attr('aria-label', ariaLabel);
          }

          // Reset Mobile
          $('.cb-mobile-local-header .local-menu-icon .cb-glyph', $mobileMenu)
            .removeClass('cb-x-mark')
            .addClass('cb-menu');
          $('.cb-mobile-local-header .local-menu-icon .sr-only', $mobileMenu).html(cb.apricot.lang.get('localNav1'));

          $mobileMenu.addClass('hidden');
          $mobileMenu.removeAttr('aria-label');
          changeMobileTitle(1);
          $('.' + plugin.options.panelClass + '.show', plugin.$el).removeClass('show');
        } else {
          $mobileMenu.removeClass('hidden');
          if (!cb.apricot.utils.isBlank(ariaLabel)) {
            $mobileMenu.attr('aria-label', ariaLabel);
          }

          $desktopMenu.addClass('hidden');
          $desktopMenu.removeAttr('aria-label');
        }
      },

      // find active menu item
      // activePageType: 1 ,filename
      // activePageType: 0 ,hash
      setActivePage = function () {
        var
          hashValue = window.location.hash,
          url = window.location.pathname,
          filename = '',
          items = [];

        //0: file name
        //1: hash
        if (!!plugin.options.activePageType) {
          filename = getFileName(url);
        } else {
          filename = '#' + hashValue.replace(/^#/, '');
        }

        if (!!cb.apricot.utils.isBlank(filename) || filename === '#') {
          return false;
        }

        for (var link in linkItems) {
          if (linkItems[link].type === 'single') {
            if (!plugin.options.activePageType) { // check for hash
              if (linkItems[link].url === filename) {
                linkItems[link].obj.addClass('active');
              }
            } else { // match name
              if (linkItems[link].url.indexOf(filename) >= 0) {
                linkItems[link].obj.addClass('active');
              }
            }
          } else {
            items = linkItems[link].list;

            for (var item in items) {
              if (!plugin.options.activePageType) { // check for hash
                if (items[item].url === filename) {
                  linkItems[link].obj.addClass('active-trail');
                }
              } else { // match name
                if (items[item].url.indexOf(filename) >= 0) {
                  linkItems[link].obj.addClass('active');
                }
              }
            }
          }
        }

        $('.active a', plugin.$el)
          .prepend('<span class="sr-only">' + cb.apricot.lang.get('localNav2') + '</span>');
      },

      //strip filename from url
      getFileName = function (url) {

        return !!url ? url.split('/').pop().split('#').shift().split('?').shift() : '';
      },

      // add events to the local navigation
      mobileEvents = function ($elm, $back) {
        var
          $selector = (!!$back && $elm) ? $back : $('[data-cb-panel-for^="panel"]', plugin.$el);


        $selector.off('click.cbLocalNavigationSplit').on('click.cbLocalNavigationSplit', function (e) {
          e.preventDefault();

          var
            self = (!!$back && $elm) ? $elm : $(this),
            panelName = self.attr('data-cb-panel-for'),
            $openPanels = $('.' + plugin.options.panelClass + '.show', plugin.$el),
            $selPanel = $('div[data-cb-panel="' + panelName + '"]', plugin.$el),
            item = {},
            // topPosition = 0,
            cEvent = true,
            nested = [],
            eventObj = {};

          $selPanel.css('top', '');

          if (!panelName) { //go back one step
            panelName = self.attr('data-cb-panel-close');
            $('div[data-cb-panel="' + panelName + '"]', plugin.$el).removeClass('show');

            cEvent = false;
            if (!!self.attr('data-cb-panel-prev')) {
              nested = self.attr('data-cb-panel-prev').split('-');
              if (!!nested[2]) {
                eventObj.level = parseInt(nested[2], 10);
              }
            } else {
              eventObj.level = 0;
            }
            eventObj.type = 'close';
            // trigger custom event
            triggerCustomEvents(3, eventObj);
            changeMobileTitle(2);

            // accessibility
            var $panel = $('.' + plugin.options.panelClass + '.show').last();
            $('a', $panel).first().focus();
            setAriaHidden($panel);
          } else if (panelName === 'panel-main') { //Show/close main panel
            if ($openPanels.length > 0) {} else {
              $selPanel.addClass('show');
              setBodyRestrictions();
              // trigger custom event
              triggerCustomEvents(1, self);
              setAriaHidden($('.cb-menu-panel[data-cb-panel="panel-main"]', plugin.$el));
            }

            changeMobileTitle(1);
          } else { //go to next level
            $selPanel.addClass('show');

            item.panelName = panelName;
            item.title = $('span', self).html();
            item.href = $('a', self).attr('href');

            // Create back label
            changeMobileTitle(0, item);
          }
          calculateMobileNavHeight();

          if (!!$selPanel.attr('data-cb-panel-nesting') && !!cEvent) {
            eventObj.level = parseInt($selPanel.attr('data-cb-panel-nesting'), 10);
            eventObj.type = 'show';
            // trigger custom event

            triggerCustomEvents(3, eventObj);
          }
        });

        // accessibility
        setAriaHidden($('.' + plugin.options.panelClass + '.show').last());

        keyBoardUpDown();
      },

      // accessibility, keyboard navigation
      keyBoardUpDown = function () {
        var $panel = ($('.cb-menu-panel.show', plugin.$el).length > 0) ?
          $('.cb-menu-panel.show', plugin.$el).last() :
          $('[data-cb-panel="panel-main"]', plugin.$el),
          $ul = $('ul', $panel).first(),
          $items = $('li > a', $ul);

        $items.each(function (count) {

          var self = $(this);

          self.off('keydown.cbLocalNavigationSplit.key').on('keydown.cbLocalNavigationSplit.key', function (e) {
            var k = e.which || e.keyCode,
              index = $('li > a', $ul).index($items.filter(':focus'));

            // APRICOT-2504
            if (!!/(13)/.test(k) || !!/(32)/.test(k)) { //enter
              $(this).trigger('click.cbLocalNavigationSplit');
            } else if (!/(38|40)/.test(k)) { //up/down

              return;
            }

            // up
            if (k === 38) {
              index--;
            } else if (k === 40) { // DOWN
              index++;
            }

            if (index < 0) {
              index = $items.length - 1;
            }
            if (index === $items.length) {
              index = 0;
            }

            var $nextItem = $items.eq(index);
            $nextItem.focus();

            return;
          });
        });

        $items.last().off('keydown.cbLocalNavigationSplit.tab').on('keydown.cbLocalNavigationSplit.tab', function (e) {
          if (!!cb.apricot.utils.isKey(e, 'TAB') && !e.shiftKey) { //tab
            if ($('.' + plugin.options.panelClass + '.show', plugin.$el).length > 0) {
              e.preventDefault();
              $('.local-menu-title', plugin.$el).focus();
            }
          }
        });

        $items.first().off('keydown.cbLocalNavigationSplit.shiftTab').on('keydown.cbLocalNavigationSplit.shiftTab', function (e) {
          if (!!cb.apricot.utils.isKey(e, 'TAB') && e.shiftKey) { //shift & tab
            e.preventDefault();
            $('.local-menu-icon', plugin.$el).focus();
          }
        });
      },

      // accessibility, add ARIA rules to all hidden panels
      setAriaHidden = function ($panel) {
        var
          panelID = (!!$panel) ? $panel.attr('data-cb-panel') : 'all';

        $('.cb-menu-panel', plugin.$el).each(function () {
          var self = $(this);

          if (self.attr('data-cb-panel') !== panelID) {
            self.attr('aria-hidden', 'true')
              .attr('tabindex', '-1');

            $('a', self).each(function () {
              $(this).attr('aria-hidden', 'true')
                .attr('tabindex', '-1');
            });
          } else {
            $('a', self).each(function () {
              $(this).attr('aria-hidden', 'false')
                .attr('tabindex', '0');

              self.attr('aria-hidden', 'false')
                .attr('tabindex', '0');
            });
          }
        });
      },


      // 1: main
      // 0: sub panel next
      // 2: sub panel prev
      changeMobileTitle = function (mode, item) {
        var
          $header = $('.local-menu-title', plugin.$el),
          $headerBack = $('.' + plugin.options.panelClass + '.show .cb-nav-back').last(),
          $headerTitle = $('.' + plugin.options.panelClass + '.show .cb-mobile-panel-title').last(),
          prevList = [],
          prev = '',
          titleList = [],
          title = '';

        if (mode === 1) { // reset to main title
          $header
            .removeAttr('data-cb-panel-close')
            .removeAttr('data-cb-panel-prev')
            .removeAttr('data-cb-panel-title');
        } else if (mode === 0) { //go to next level
          prevList = (!!$header.attr('data-cb-panel-prev')) ? $header.attr('data-cb-panel-prev').split(',') : [];
          titleList = (!!$header.attr('data-cb-panel-title')) ? $header.attr('data-cb-panel-title').split(',') : [];

          if (!!$header.attr('data-cb-panel-close')) {
            if ($.inArray($header.attr('data-cb-panel-close'), prevList) < 0) {
              prevList.push($header.attr('data-cb-panel-close'));
            }
            if ($.inArray($headerBack.html(), titleList) < 0) {
              titleList.push($headerBack.html());
            }
          }

          $header
            .attr('data-cb-panel-title', titleList.join())
            .attr('data-cb-panel-prev', prevList.join())
            .attr('data-cb-panel-close', item.panelName);

          //  Back Link
          var $a = $('<a />');

          $('<span />')
            .addClass('sr-only')
            .html(cb.apricot.lang.get('localNav4'))
            .appendTo($a);

          $('<span />')
            .html('Back')
            .appendTo($a);

          $headerTitle.html(item.title);

          $a.attr('tabindex', 0)
            .attr('href', item.href)
            .attr('aria-label', cb.apricot.lang.get('localNav4'));

          //  Close
          var $closeBtn = $('<a/>')
            .addClass('local-menu-icon')
            .attr('href', '#')
            .attr('tabindex', 0)
            .html('<span class="sr-only">' + cb.apricot.lang.get('localNav3') + '</span>')

          var $icon = $('<span />')
            .addClass('cb-glyph')
            .addClass('cb-down')
            .addClass('cb-x-mark')
            .attr('aria-hidden', 'true');

          $closeBtn.append($icon);

          $headerBack
            .empty()
            .append($a)
            .append($closeBtn)


          mobileEvents($header, $headerBack);

          $closeBtn.on('click', function (e) {
            e.preventDefault()

            triggerCustomEvents(0, $closeBtn);
            plugin.mobileCloseAll();
          })

          $a.focus();
        } else { // go back one level
          prevList = (!!$header.attr('data-cb-panel-prev')) ? $header.attr('data-cb-panel-prev').split(',') : [];
          prev = prevList[prevList.length - 1];
          prevList.splice($.inArray(prev, prevList), 1);

          titleList = (!!$header.attr('data-cb-panel-title')) ? $header.attr('data-cb-panel-title').split(',') : [];
          title = titleList[titleList.length - 1];
          titleList.splice($.inArray(title, titleList), 1);

          if (!prev) {
            changeMobileTitle(1);
          } else {
            $header
              .attr('data-cb-panel-prev', prevList.join())
              .attr('data-cb-panel-title', titleList.join())
              .attr('data-cb-panel-close', prev);

            mobileEvents($header);
          }
        }
      },

      // add class to main navigation for style
      addExtraClass = function ($this) {
        var parent = '';

        if ($this.hasClass('title')) {
          parent = 'div';
        } else {
          parent = 'li';
        }
        $this.on('mouseover.cbLocalNavigationSplit', function () {
          $(this).parent(parent).addClass('hover');
        });
        $this.on('mouseleave.cbLocalNavigationSplit', function () {
          $(this).parent(parent).removeClass('hover');
        });

        $this.on('focus.cbLocalNavigationSplit', function () {
          $(this).parent(parent).addClass('focus');
        });

        $this.on('blur.cbLocalNavigationSplit', function () {
          $(this).parent(parent).removeClass('focus');
          if (!!$(this).parent(parent).hasClass('slidedown')) {
            if ($(this).parent(parent).attr('data-cb-slidedown') == 'true') {
              $(this).trigger('click')
            }
          }
        });

        if (!!$this.parent(parent).hasClass('slidedown')) {
          var
            $slidedown = $this.parent('li');

          $slidedown.on('shown.bs.slidedown', function () {

            $(this).removeClass('focus');
          });
        }
      },

      // add top link to slidedown items
      addAdditionalTopLinks = function () {
        var
          $item = {},
          $a = {},
          $span = {},
          $firstChild = {},
          label = '',
          href = '',
          customLbl = false;

        $('ul > li', plugin.$el).each(function () {
          var
            self = $(this);

          if (!!self.hasClass('slidedown')) {
            href = $('a', self).attr('href');
            // Only add if parent has an actual URL/Hash value
            if (cb.apricot.utils.isBlank(href) || href === '#') {
              return false;
            }

            $firstChild = $('li:first', self);
            label = (!!plugin.options.desktopLabelPrefix) ?
              plugin.options.desktopHomeLabel + ' ' + $('span:first', self).html() :
              $('span:first', self).html() + ' ' + plugin.options.desktopHomeLabel;

            if (plugin.options.customHomeLabel !== '') {
              label = plugin.options.customHomeLabel;
              customLbl = true;
            }

            $item = $('<li/>')
              .addClass('cb-desktop-label');

            $a = $('<a/>')
              .attr('href', href)
              .attr('tabindex', 0)
              .appendTo($item);

            $span = $('<span/>')
              .html(label)
              .appendTo($a);

            if (!!customLbl) {
              $('<span/>')
                .addClass('sr-only')
                .html(' ' + $('span:first', self).html())
                .appendTo($a);
            }

            $item.insertBefore($firstChild);
          }
        });
      },

      // check if we need a more slidedown
      checkMoreStatus = function () {
        var
          containerWidth = $('.' + plugin.options.desktopClass, plugin.$el).innerWidth(),
          titleWidth = ($('.title', plugin.$el).length > 0) ? $('.title', plugin.$el).innerWidth() : 0,
          promotional = ($('.cb-promotional', plugin.$el).length > 0) ? $('.cb-promotional', plugin.$el).innerWidth() : 0,
          breakPointIndex = 0,
          itemsWidth = 0,
          moreWidth = 110, //80,
          //we have to take the width of more drop down into consideration
          limit = parseInt(containerWidth, 10) - parseInt(titleWidth, 10) - parseInt(promotional, 10);

        limit = (limit - (45 + moreWidth));

        for (var item in mainItems) {
          itemsWidth += mainItems[item].width;
          if (itemsWidth <= limit) {
            breakPointIndex += 1;
          }
        }

        if (itemsWidth > limit) {
          resetMoreSlidedown();
          activateMoreSlidedown(breakPointIndex);

          $('.last-item', plugin.$el).remove('last-item');
        } else {
          $('.exp-more', plugin.$el).prev().addClass('last-item');
          $('.exp-more', plugin.$el).addClass('hidden');
          $('.exp-less', plugin.$el).removeClass('hidden')
            .removeClass('exp-less');
        }
      },

      // put together HTML for more slidedown
      buildMoreSlidedown = function () {
        var
          $menu = ($('.cb-procedural', plugin.$el).length > 0) ?
          $('.cb-procedural', plugin.$el) :
          $('.' + plugin.options.desktopClass + ' ul:first', plugin.$el),
          $a = {},
          $span = {},
          $i = {},
          $ul = {};

        if (!!$.isEmptyObject($more)) {
          $more = $('<li/>')
            .addClass('slidedown')
            .addClass('exp-more');

          $menu.append($more);

          $a = $('<a/>')
            .addClass('slidedown-toggle')
            .attr('data-toggle', 'slidedown')
            .attr('href', '#')
            .attr('role', 'button')
            .attr('aria-expanded', 'false')
            .appendTo($more);

          $span = $('<span/>')
            .text(plugin.options.moreLabel)
            .appendTo($a);
          $span = $('<span/>')
            .addClass('sr-only')
            .text(cb.apricot.lang.get('localNav5'))
            .appendTo($a);

          $i = $('<i/>')
            .addClass('cb-glyph')
            .addClass('cb-down')
            .attr('aria-hidden', 'true')
            .appendTo($a);
          $i = $('<i/>')
            .addClass('cb-glyph')
            .addClass('cb-up')
            .addClass('hidden')
            .attr('aria-hidden', 'true')
            .appendTo($a);

          $ul = $('<ul/>')
            .addClass('slidedown-menu')
            .attr('role', 'menu')
            .appendTo($more);
        } else {
          $more.removeClass('hidden');
        }

        return $more;
      },

      // add items to more slidedown
      activateMoreSlidedown = function (index) {
        var
          moreList = [],
          $more = buildMoreSlidedown(),
          $tmp = {},
          $ul = $('ul', $more),
          $item = {},
          $a = {},
          $span = {};

        for (var i = index; i < mainItems.length; i++) {
          $tmp = mainItems[i].obj;

          if (!!$tmp.hasClass('slidedown') || $('ul', $tmp).length > 0) {
            $item = {};
            $item = $('<li/>');

            $a = $('<a/>')
              .attr('href', $('a:first', $tmp).attr('href'))
              .appendTo($item);

            $span = $('<span/>')
              .html($('span:first', $tmp).first().html())
              .appendTo($a);

            moreList.push($item);
          } else {
            moreList.push($tmp.clone());
          }

          $tmp.addClass('hidden')
            .addClass('exp-less');
        }

        $ul.empty();
        for (var item in moreList) {
          $tmp = moreList[item].removeClass('hidden');
          $ul.append($tmp);
        }

        $more.cbSlidedownExtension('destroy');
        $more.cbSlidedownExtension();
        pushDownMenuEvent($more)

        addExtraClass($('a', $more));
      },

      // reset more slidedown
      resetMoreSlidedown = function () {
        if (!$.isEmptyObject($more)) {
          $more.remove();
          $more = {};
        }

        $('.exp-less', plugin.$el).removeClass('hidden')
          .removeClass('exp-less');

        // Reset SlideDown
      },

      // calculate elements width
      getWidth = function ($this) {
        var
          result = 0,
          isHidden = (!!$this.hasClass('hidden')) ? true : false;

        // this check is based on the logic used in this plugin
        if (!!isHidden) {
          $this.removeClass('hidden');
          result = $this.outerWidth(true);
          $this.addClass('hidden');
        } else {
          result = $this.outerWidth(true);
          if (!!$.isNumeric(result)) {
            result = Math.ceil(result);
          } else {
            result = 0;
          }
        }

        return result;
      },

      resetBody = function () {
        $('body').removeClass('cb-no-overflow');
      },

      setBodyRestrictions = function () {
        $('body').addClass('cb-no-overflow');
      },

      triggerCustomEvents = function (type, obj) {
        switch (type) {
          case 0: // close all panels
            plugin.$el.trigger('localnavsplit_close', obj);
            break;
          case 1: // open main panel
            plugin.$el.trigger('localnavsplit_open', obj);
            break;
          case 2: // click on main panel title
            plugin.$el.trigger('localnavsplit_mainTitle', obj);
            break;
          case 3: // change in panels
            plugin.$el.trigger('localnavsplit_panel', obj);
            plugin.$el.data('_localnavsplit', obj);
        }
      },


      // ---------------------------------------------------------------- Build slidedown container
      slideDownMenuBuild = function () {
        $slideDownMenu = $('<div/>', {
          'class': 'cb-slidedown-items',
          'role': 'region'
        }).append(
          $('<div/>', {
            'class': 'container'
          }).append(
            $('<div/>', {
              'class': 'row'
            }).append(
              $('<div/>', {
                'class': 'col-xs-12'
              }).append(
                $('<div/>', {
                  'class': 'cb-slidedown-navigation'
                }).append(
                  $('<div/>', {
                    'class': 'cb-slidedown-container transition'
                  }).append(
                    $('<div/>', {
                      'class': 'cb-slidedown-inner'
                    }, {
                      'role': 'menu'
                    })
                  )
                )
              )
            )
          )
        );

        plugin.$el.append($slideDownMenu);

        pushDownMenuEvent()
        megaMenuEvent()
      },

      slideDownMenuEvents = function ($elm) {
        var
          $toggle = $('.slidedown-toggle', $elm),
          $items = $('li:not(.disabled):visible a', $slideDownMenu)

        if (!$items.length) return

        $items.on('keydown.slidedown', function (e) {
          var index = $items.index(e.target)

          if (e.which == 9 && e.shiftKey) { //tab + shift
            e.preventDefault();
            if (index > 0) {
              index--
              $items.eq(index).trigger('focus')
            } else {
              $toggle
                .focus()
            }
          } else if (e.which == 9 && !e.shiftKey) { //tab
            e.preventDefault();

            if (index < $items.length - 1) {
              index++
              $items.eq(index).trigger('focus')
            } else {
              $toggle
                .focus()
            }
          } else {

            if (e.which == 37 && index > 0) index-- // left
            if (e.which == 39 && index < $items.length - 1) index++ // right
            if (!~index) index = 0

            $items.eq(index).trigger('focus')

          }
        })
      },

      resetSlideDown = function () {
        var
          $open = $('.slidedown.open', plugin.$el)

        if ($open.length > 0) {
          $('.slidedown-toggle', $open).trigger('click');
          $('.cb-slidedown-container', $slideDownMenu)
            .css('height', '0')
            .css('overflow', 'auto')
          $('.cb-slidedown-inner', $slideDownMenu)
            .attr('class', 'cb-slidedown-inner')
            .empty()
        }
      },

      pushDownMenuEvent = function ($elm) {
        var
          $slidedownList = (!!$elm) ? $elm : $('.slidedown:not(.cb-slidedown-mega)', plugin.$el),
          $slidedownNav = $('.cb-slidedown-navigation', $slideDownMenu),
          $slidedownPanel = $('.cb-slidedown-container', $slideDownMenu);

        $slidedownList.each(function (index) {
          var self = $(this),
          spanId = cb.apricot.utils.uniqueID(5, 'cbLocalNavSplit_');


          self.on('show.bs.slidedown', function () {
            self.attr('data-cb-slidedown', false);

            pushDownOpen(self, $slidedownNav, $slidedownPanel, 'pushdown' + index)

          $('.slidedown-toggle span', self).attr('id', spanId)
          $slideDownMenu.attr('aria-labelledby', spanId)

          }).on('hide.bs.slidedown', function () {
            self.removeAttr('data-cb-slidedown');
            $('.cb-slidedown-inner', $slideDownMenu).empty();
            $slideDownMenu.removeAttr('aria-labelledby')

            $('a', self).focus();

            $slidedownPanel.css('height', '0');
            $('div', $slidedownPanel).first().css('top', '');

            $slidedownNav.css('overflow', 'hidden');
          })
        });
      },

      pushDownOpen = function ($elm, $pushDownNav, $pushDownPanel, index) {
        pushDownMenuUpdate($elm)
        pushDownMenuHeight($pushDownNav, $pushDownPanel, index);

        // Accessibility
        setTimeout(function () {
          $('.cb-pushdown-list li', $pushDownNav).first().find('a')
            .focus();
          
          $elm.attr('data-cb-slidedown', true);
          slideDownMenuEvents($elm)
        }, 200);
      },

      pushDownMenuUpdate = function ($elm) {
        var
          $slideDownInner = $('.cb-slidedown-inner', $slideDownMenu),
          $ul = $('<ul/>', {
            'class': 'cb-pushdown-list'
          });

        $slideDownInner
          .removeClass('cb-slidedown-megamenu')
          .addClass('cb-slidedown-pushdown')
          .empty()
          .append($ul)

        $('ul.slidedown-menu > li', $elm).each(function () {
          var $li = $(this),
            $a = $('a', $li),
            $span = $('span', $a);

          $ul.append(
            $('<li />').append(
              $('<a />', {
                'href': $a.attr('href'),
                'target': $a.attr('target'),
                'tabIndex': 0
              }).html($span.html())
            )
          )
        });
      },

      pushDownMenuHeight = function ($menu, $panel, index) {
        var
          panelHeight = $('.cb-slidedown-inner', $menu).outerHeight(),
          height = $(window).innerHeight() - $('.cb-local-navigation-split').height();

        if (panelHeight < 48) {
          resetSlideDown();
          return
        }
        if (panelHeight > height) {
          $panel.css('height', height + 'px')
            .css('overflow-y', 'scroll');

          $('div', $panel).first()
            .css('top', '0')
        } else {
          $panel.css('height', panelHeight + 'px')
            .css('overflow', 'auto')
        }
      },
      // Mega Menu
      megaMenuEvent = function () {
        var
          $slidedownNav = $('.cb-slidedown-navigation', $slideDownMenu),
          $slidedownPanel = $('.cb-slidedown-container', $slideDownMenu);

        $('.cb-slidedown-mega', plugin.$el).each(function (index) {
          var self = $(this),
          spanId = cb.apricot.utils.uniqueID(5, 'cbLocalNavSplit_');

          self.on('show.bs.slidedown', function () {
            self.attr('data-cb-slidedown', false)

            $('.slidedown-toggle span', self).attr('id', spanId)
            $slideDownMenu.attr('aria-labelledby', spanId)

            megaMenuOpen(self, $slidedownNav, $slidedownPanel, 'mega' + index)
          }).on('hide.bs.slidedown', function () {
            self.removeAttr('data-cb-slidedown');
            $('.cb-slidedown-inner', $slideDownMenu).empty();
            $slideDownMenu.removeAttr('aria-labelledby');
            
            $('a', self).focus();

            $slidedownPanel.css('height', '0');
            $('div', $slidedownPanel).first().css('top', '');

            $slidedownNav.css('overflow', 'hidden')
          });
        });
      },

      megaMenuOpen = function ($elm, $megaNav, $megaPanel, index) {
        $.when(updateMenuMega($elm)).then(function () {
          $.when(megaMenuHeight($megaNav, $megaPanel, index)).then(function () {
            $('.cb-slidedown-inner', $slideDownMenu).cbMasonryGrid('destroy');

            $('.cb-slidedown-inner', $slideDownMenu).cbMasonryGrid({
              lgColumns: plugin.options.megaMenuGrid,
              mdColumns: plugin.options.megaMenuGrid,
              smColumns: plugin.options.megaMenuGrid
            }).on('masonry.ready', function (e, data) {
              var mHeight = parseInt(data.height, 10) + 24;

              if (mHeight <= 24) {
                mHeight = 0
              }
              $megaPanel
                .css('height', mHeight + 'px')
            });

            // Accessibility
            setTimeout(function () {
              $('.cb-masonry-grid a', $megaNav).first()
                .focus();

              
              $elm.attr('data-cb-slidedown', true);

              slideDownMenuEvents($elm)
            }, 400);
          });
        });
      },

      updateMenuMega = function ($elm) {
        var
          $slideDownInner = $('.cb-slidedown-inner', $slideDownMenu),
          $megaGrid = $('<div/>', {
            'class': 'row cb-masonry-grid'
          }),
          col = 12 / parseInt(plugin.options.megaMenuGrid, 10);

        $slideDownInner
          .removeClass('cb-slidedown-pushdown')
          .addClass('cb-slidedown-megamenu')
          .empty()
          .append($megaGrid)

        $('ul.slidedown-menu > li', $elm).each(function (index) {
          var self = $(this),
            label = $('a > span', self).first().html(),
            $grid = $('<div/>', {
              'class': 'cb-masonry-item col-xs-12 col-md-' + col
            }).append(
              $('<div/>', {
                'class': 'cb-menu-list'
              }, {
                'role': 'navigation'
              }, {
                'aria-label': 'TBD'
              }).append(
                $('<ul/>', {
                  'class': 'cb-menu-list-ul'
                }, {
                  'data-cb-item': (index + 1)
                }).append(
                  $('<li/>').append(
                    $('<h3/>').html(label)
                  )
                )
              )
            ).appendTo($megaGrid)

          var $ul = $('ul', $grid);

          //  First nest
          $('ul:first > li', self).each(function (index) {
            var
              $li = $(this),
              $a = $('a', $li),
              label = ($('span', $a).length > 0) ? $('span', $a).html() : $a.html();

            $ul.append(
              $('<li />').append(
                $('<a />', {
                  'href': $a.attr('href'),
                  'target': $a.attr('target')
                }).html(label)
              )
            )
          })
        })
      },

      megaMenuHeight = function ($menu, $panel, index) {
        var
          height = $(window).innerHeight() - 72;


        $panel.css('height', height + 'px')
          .css('overflow-y', 'auto')

        $('div', $panel).first()
          .css('top', '0');
      };


    // ----------------------------------------------------------------

    plugin.checkSticky = function () {
      // only check this is no anchor menu is on the page
      if ($('.' + plugin.options.anchorMenuClass + '.cb-anchor-link li').length === 0) {
        // activate sticky header
        $(window).on('scroll.cbLocalNavigation', function () {
          if ($(this).scrollTop() > navigationTopPosition && !$mobileMenu.hasClass('hidden')) {
            $('body').addClass('cb-adjust-local-nav');
            plugin.$el.addClass('sticky-local-nav');
            calculateMobileNavHeight();
          } else {
            $('body').removeClass('cb-adjust-local-nav');
            plugin.$el.removeClass('sticky-local-nav');
          }
        });
      } else {
        $('body').removeClass('cb-adjust-local-nav');
        plugin.$el.removeClass('sticky-local-nav');
      }
    };

    plugin.mobileCloseAll = function () {
      if (!!plugin.$el.data('cbLocalNavigationSplit')) {
        var
          $openPanels = $('.' + plugin.options.panelClass + '.show', plugin.$el);

        resetBody();
        $openPanels.removeClass('show');

        changeMobileTitle(1);
      }
    };

    plugin.mobileClosePanel = function () {
      if (!!plugin.$el.data('cbLocalNavigationSplit')) {
        var
          $panel = $('.' + plugin.options.mobileHeaderClass + ' .menu-back', plugin.$el);

        if ($panel.length > 0) {
          $panel.trigger('click');
        } else {
          plugin.mobileCloseAll();
        }
      }
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      $('.' + plugin.options.mobileClass, plugin.$el).remove();
      $('.' + plugin.options.desktopClass, plugin.$el).removeClass('hidden');
      $('li', plugin.$el).removeAttr('data-cb-parent');
      $('.exp-more', plugin.$el).remove();
      $('.cb-slidedown-items', plugin.$el).remove();

      $('.exp-less', plugin.$el).each(function () {
        var
          self = $(this);

        self.removeClass('hidden').removeClass('exp-less');
      });
      $('li.cb-desktop-label', plugin.$el).remove();

      $(window).off('resize.cbLocalNavigationSplit');
      $(window).off('scroll.cbLocalNavigationSplit');
      plugin.$el.removeData('cbLocalNavigationSplit');
    };

    plugin.init();
  };

  $.fn.cbLocalNavigationSplit = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbLocalNavigationSplit')) {
          $(this).data('cbLocalNavigationSplit', new cb.apricot.localNavSplit(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbLocalNavigationSplit');
        if (instance instanceof cb.apricot.localNavSplit && typeof instance[options] === 'function') {
          instance[options].apply(instance, Array.prototype.slice.call(args, 1));
        }
      });
    }
  };
}(jQuery, cb);