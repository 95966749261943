/*!
 *  Apricot v3.4.0
 *  By:             The College Board
 *  App:            apricot
 *  Build Time:     2019-04-02 [10:28:53 AM] EDT
 *  Build Number:   unknown
 *  SVN Revision:   unknown
 *  Jenkins Job:    unknown
 *  This version of Apricot includes Bootstrap v3.7.0
 */

/* ========================================================================
 * Collapse Extension
 * ========================================================================
 *
 * This extension adds ARIA code to the collapse elements
 * also adds left/right key navigation to the accordion list
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  if (!$.fn.collapse) throw new Error('accordion-extension requires collapse.js');

  $.fn.collapse.Constructor.prototype.keydown = function (e) {
    var
      $this = $(this),
      $items,
      $accordionlistContainer = $this.closest('.panel-group'),
      index,
      k = e.which || e.keyCode;


    //left/right/space/up/down
    if (!/(37|39|32|38|40)/.test(k)){
      return;
    }

    if (k === 32) {
      $this.trigger('click');
    }

    $items = $accordionlistContainer.find('.panel-heading:visible a');
    index = $items.index($items.filter(':focus'));


    if (k === 37 || k === 38) {
      index--; //left, up
    }
    if (k === 39 || k === 40) {
      index++; //right, down
    }

    if(index < 0) {
      index = $items.length -1;
    }
    if(index === $items.length) {
      index = 0;
    }

    var $nextTab = $items.eq(index);
    if($nextTab.attr('data-toggle') === 'collapse') {
      $nextTab.focus();
    }

    e.preventDefault();
    e.stopPropagation();
  };

  $(document).on('keydown.collapse.data-api','[data-toggle="collapse"]' , $.fn.collapse.Constructor.prototype.keydown);

  //--------- Apricots Accordion plugin (collapse all/expand all option)

  cb.apricot.accordion = function (element) {
    var
      plugin = this;

    plugin.$el = $(element);

    var
    accordionstatus = function($this) {
      var
        $parent = $this.closest('.panel'), //.panel .panel-default
        $heading = $parent.find('.panel-heading a').first(),
        mode = $heading.hasClass('cb-accordion-show') ? 1 : 0;
        // mode 0: open
        // mode 1: close

      if (!!mode) {
        $heading.removeClass('cb-accordion-show')
          .attr({
            'aria-expanded': 'false'
          });

        if (!!plugin.$el.hasClass('cb-see-more')) {
          $heading.attr('aria-label', 'show more');
        }
        $this.attr('tabindex', '0');
        $this.attr('aria-hidden', 'true');
      } else {
        $heading.addClass('cb-accordion-show')
          .attr({
            'aria-expanded': 'true'
          });

        if (!!plugin.$el.hasClass('cb-see-more')) {
          $heading.attr('aria-label', 'show less');
        }
        $this.attr('tabindex', '-1');
        $this.attr('aria-hidden', 'false');
      }

      $('.panel-heading', $parent).trigger('accordion_change', mode);
    },

    addEvent = function () {
      $('.collapse-all', plugin.$el).on('click.cbAccordion',function() {
        $('.panel-title >[data-toggle="collapse"]', plugin.$el).each(function() {
          var
            $heading = $(this);

          if (!!$heading.hasClass('cb-accordion-show')) {
            $heading.trigger('click');
          }
        });
      });

      $('.expand-all', plugin.$el).on('click.cbAccordion', function() {
        $('.panel-title > [data-toggle="collapse"]', plugin.$el).each(function() {
          var
            $heading = $(this);

          if (!$heading.hasClass('cb-accordion-show')) {
            $heading.trigger('click');
          }
        });
      });
    };

    plugin.init = function () {
      // nested accordion
      $('.panel-body', plugin.$el).each(function() {
        var
          $body = $(this);

        if ($('.cb-see-more', $body).length > 0) {
          $body.addClass('has-see-more');
        }
      });

      $('.panel-collapse', plugin.$el).each(function(index) {
        var
          self = $(this),
          $parent = self.parent(),
          $panelHeading = self.prev('.panel-heading'),
          $heading = $('a', $panelHeading),
          $headingContent = $('.cb-panel-heading-content', $panelHeading),
          $title = $parent.find('.panel-title'),
          tmpId = '';

        // Setup the accordion
        if (!!$panelHeading.attr('id')) {

          self.attr('aria-labelledby', $panelHeading.attr('id'));
        } else {
          tmpId = cb.apricot.utils.uniqueID(10, 'apricot_');
          $panelHeading.attr('id', tmpId);
          self.attr('aria-labelledby', tmpId);
        }

        if (!!self.attr('id')) {
          $heading.attr('href', '#' + self.attr('id'));
          $heading.attr('aria-controls', self.attr('id'));
        } else {
          tmpId = cb.apricot.utils.uniqueID(10, 'apricot_');
          self.attr('id', tmpId);
          $heading.attr('href', '#' + tmpId);
          $heading.attr('aria-controls', tmpId);
        }

        // if heading content exsists
        if ($headingContent.length > 0) {
          var headingContentID = (!!$headingContent.attr('id')) ? $headingContent.attr('id')
          : cb.apricot.utils.uniqueID(10, 'apricot_');

          $headingContent.attr('id', headingContentID)
            .attr('tabindex', '-1');
          $heading.attr('aria-describedby', headingContentID);
        }

        if (!!self.hasClass('in') || !!self.hasClass('collapsing')) {
          $heading.addClass('cb-accordion-show')
            .attr({
              'aria-expanded': 'true'
            });
          self.attr('tabindex', '-1')
            .attr('aria-hidden', 'false');
        } else {

          $heading.removeClass('cb-accordion-show')
            .attr({
              'aria-expanded': 'false'
            });
          self.attr('tabindex', '0')
            .attr('aria-hidden', 'true');

        }

        // show/hide accordion Events
        $heading.on('click.cbAccordion', function(e) {
          e.preventDefault();

          if (!!self.hasClass('collapsing')) return;

          self.collapse('toggle');
          accordionstatus(self);
        }).on('focus', function () {

          $title.addClass('cb-focus');
        }).on ('blur', function () {

          $title.removeClass('cb-focus');
        });

        //  Tabel adjustment
        self.on('shown.bs.collapse', function() {
          var
            self = $(this),
            $tbl = $('table', self);

          if ($tbl.length > 0) {
            if (!!$tbl.data('cbCustomScrollbar')) {
              $tbl.cbCustomScrollbar('adjustTable');
            }
          }
        });
      });

      addEvent();
      plugin.$el.attr('data-cb-element', 'accordion');
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      $('.expand-all', plugin.$el).off('click.cbAccordion');
      $('.collapse-all', plugin.$el).off('click.cbAccordion');
      $('.panel-heading a', plugin.$el).off('click.cbAccordion');

      plugin.$el.removeData('cbAccordion')
        .removeAttr('data-cb-element', 'accordion');
    };

    plugin.init();
  };

  $.fn.cbAccordion = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbAccordion')) {
          $(this).data('cbAccordion', new cb.apricot.accordion(this, options));
        }
       });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbAccordion');
        if (instance instanceof cb.apricot.accordion && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call(args, 1) );
        }
      });
    }
  };

  // ----- Activate on page load
  $(window).on('load', function () {
    // Accordion
    $('.cb-accordion').each(function () {
      var
        self = $(this);

      if (self.data('cb-element') !== 'no-cb') {
        self.cbAccordion();
      }
    });
  });

  // ----- Trigger plugin on document click
  $(document).on('click.cbAccordion', '[data-toggle="collapse"], [class*="cb-accordion-btn"]', function (e) {
    var $this = $(this);

    if ($this.closest('.cb-accordion').length > 0) {
      var $ac = $this.closest('.cb-accordion');

      if (!$ac.data('cbAccordion')) {
        e.preventDefault();

        $.when($ac.cbAccordion()).done(function() {
          $this.trigger('click.cbAccordion');
        });
      }
    }
  });
}(jQuery, cb);

