/* ========================================================================
 * Highlighted Article Card
 * ========================================================================
 *
 * behavior adjustment for Highlighted Article Card element
 * ======================================================================== */

+function ($) {
  'use strict';

  $(window).on('load', function () {
    $('.cb-article-band .cb-article-item, .cb-article-card .cb-article-item').each( function () {
    	var self = $(this),
    		$a = $('a', self).first();

      $a.on('focus', function () {
        var $parend = $(this).parent();

        $parend.addClass('focus');
      }).on('blur', function () {
        var $parend = $(this).parent();

        $parend.removeClass('focus');
      });
    });
  });
}(jQuery);
