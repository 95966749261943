/* ========================================================================
 * Architectural Views
 * ========================================================================
 *
 * behavior adjustment for Architectural View elements
 * ======================================================================== */

+function ($) {
  'use strict';

  $(window).on('load', function () {
    $('.cb-arch-view, .cb-nav-card').each( function () {
    	var self = $(this),
        $parent = self.parent('.cb-arch-view-list'),
    		$a = $('a', self).first(),
    		$span = $('span', $a),
    		$p = $('p', self).first(),
    		id = (!!$p.attr('id')) ? $p.attr('id') : cb.apricot.utils.uniqueID(5, 'apricot_'),
        browser = cb.apricot.utils.browser();

      if (!!browser.msie) {
        $p.attr('tabindex', 1);
      }

    	$a.attr('aria-describedby', id);
    	$p.attr('id', id);

      if ($parent.length > 0) {
        if ($('.cb-arch-view p', $parent).length === 0) {
          $parent.addClass('cb-arch-view-no-des');
        }
      }
    });
  });
}(jQuery);
