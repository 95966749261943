/* ========================================================================
 * Event Modules
 * ========================================================================
 *
 * behavior adjustment for Event Modules
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  cb.apricot.dateAlignment = function (element) {
    var
      plugin = this,
      $p = {},
      pw = 0;

    plugin.$el = $(element);

    var
    isTextWrapped = function () {
      var
        cw = containerWidth() - 36;

      if (pw > cw) {
        $p.addClass('cb-multi');
      } else {
        $p.removeClass('cb-multi');
      }
    },

    itemWidth = function () {

      return $('.cb-event-date p', plugin.$el).outerWidth(true);
    },

    containerWidth = function () {

      return $('.cb-event-date', plugin.$el).outerWidth(true);
    };

    plugin.init = function () {

      $p = $('.cb-event-date p', plugin.$el);
      pw = itemWidth();

      isTextWrapped();

      $(window).on('resize.cbDateAlignment', function () {
        isTextWrapped();
      });
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      if (!!plugin.$el.data('cbDateAlignment')) {
        plugin.$el.removeData('cbDateAlignment');
        window.$el.off('resize.cbDateAlignment');
      }
    };

    plugin.init();
  };

  $.fn.cbDateAlignment = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbDateAlignment')) {
          $(this).data('cbDateAlignment', new cb.apricot.dateAlignment(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbDateAlignment');
        if (instance instanceof cb.apricot.dateAlignment && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
        }
      });
    }
  };

  // ----- Activate on page load
  $(window).off('load.cbEventModule').on('load.cbEventModule', function () {
    $('.cb-event').each( function () {
    	var self = $(this),
        $prev = (!!self.parent().hasClass('cb-event-band')) ?
          ((self.prev().hasClass('cb-event')) ? self.prev() : null) : null,
    		$a = $('a', self),

        $date = $('.cb-event-date', $a),

        $title = $('h2, h3, h4', $a),

        $des = ($('.cb-event-description', $a).length > 0) ? $('.cb-event-description', $a) : false,
    		id_d = (!!$date.attr('id')) ? $date.attr('id') : cb.apricot.utils.uniqueID(5, 'apricot_'),
        id_t = (!!$title.attr('id')) ? $title.attr('id') : cb.apricot.utils.uniqueID(5, 'apricot_'),
        id_des = '';


      // adjust date line
      self.cbDateAlignment();

    	$a.attr('aria-labelledby', id_d + ' ' + id_t);
      $date.attr('id', id_d);
    	$title.attr('id', id_t);

      if (!!$des) {
        id_des = (!!$des.attr('id')) ? $des.attr('id') : cb.apricot.utils.uniqueID(5, 'apricot_');
        $a.attr('aria-describedby', id_des);
        $des.attr('id', id_des);
      }

      // adjust focus
      $a.on('focus', function () {
        var $event = $(this).parent();

        $event.addClass('focus');
        if (!!$prev) {
          $prev.addClass('prev-focus');
        }
      }).on('blur', function () {
        var $event = $(this).parent();

        $event.removeClass('focus');
        if (!!$prev) {
          $prev.removeClass('prev-focus');
        }
      });
    });

    // Event Module Band, height/width adjustment
    adjustEventHeight();

    $(window).off('resize.cbEventModule').on('resize.cbEventModule', function () {
      adjustEventHeight();
    });
  });

  function adjustEventHeight () {
    var currentViewport = cb.apricot.utils.viewport();
    if (currentViewport.prefix !== 'xs') {
      $('.cb-event-band').each( function () {
        // reset all;
        $('.cb-event-content', $(this)).css('margin-bottom', '0');

        var
          res = cb.apricot.utils.tallestElm($('.cb-event', $(this)));

        cb.apricot.utils.adjustHeight(res, '.cb-event-content');
      });
    } else {
      $('.cb-event-band').each( function () {
        $('.cb-event', $(this)).each(function () {

          $('.cb-event-content', $(this)).css('margin-bottom', '0');
        });
      });
    }
  }
}(jQuery, cb);
