/* ========================================================================
 * Loader
 * ========================================================================
 *
 * $.fn.cbSpinner
 *
 * Progress animation and counter for Apricot's Loader
 *
 * This plugin is written based on jQuery circular progress bar
 * https://github.com/andysellick/circular-progress
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  cb.apricot.spinner = function(elem,options){
    this.elem = elem;
    this.$elem = $(elem);
    this.options = options;
    this.init();
  };

  cb.apricot.spinner.prototype = {
    init: function(){
      this.settings = $.extend({
        rotateBy: 1,
        initialPos: 0,
        targetPos: 100,
        scale: 100,
        speed: 100,
        delayAnimation: 0,

        showProgress: true,
        progPreText: '',
        progPostText: '%',

        loop: false,
        onFinishMoving: $.noop
      }, this.defaults, this.options);

      this.rpanel = {}; //right
      this.lpanel = {}; //left
      this.overallpos = 0;
      this.inner = {};
      this.innerprogress = {};
      this.timer = 0;

      //create required variables and normalise settings
      this.settings.rotateBy = Math.min(this.settings.rotateBy, 360);
      this.settings.initialPos = Math.min(this.settings.initialPos, this.settings.scale);
      this.settings.targetPos = Math.min(this.settings.targetPos, this.settings.scale);

      this.settings.initialPos = this.calculateScale(this.settings.initialPos);
      this.settings.targetPos = this.calculateScale(this.settings.targetPos);
      this.rotateBy = this.settings.rotateBy; //fixme this is currently used but probably isn't needed

      //create required elements
      var prog = $('<div/>').addClass('cb-progressinner').appendTo(this.$elem);
      var lpane = $('<div/>').addClass('cb-lpane').appendTo(prog);
      var rpane = $('<div/>').addClass('cb-rpane').appendTo(prog);
      this.rpanel = $('<div/>').addClass('cb-cover').appendTo(rpane);
      this.lpanel = $('<div/>').addClass('cb-cover').appendTo(lpane);

      this.inner = $('<div/>').addClass('cb-display').appendTo(prog);
      this.innerprogress = $('<div/>').addClass('cb-displayprogress').appendTo(this.inner);

      //now get the plugin started
      this.initialSpinner();

      // accessibility rules
      this.$elem.attr('aria-valuemin', this.convertScale(this.settings.initialPos));
      this.$elem.attr('aria-valuemax', this.convertScale(this.settings.targetPos));
    },

    initialSpinner: function () {
       //now get the plugin started
      var me = this;
      //option 1 - progress animates from initial to target
      if(this.settings.initialPos && this.settings.initialPos !== this.settings.targetPos){
        if(this.settings.initialPos > this.settings.targetPos){ //if target is less than initial, we need to rotate backwards
          this.rotateBy = -this.rotateBy;
        }
        this.setTargetPos(this.settings.initialPos);
        this.timer = setTimeout(function() {
          me.animateCircle(me.settings.initialPos, me.settings.targetPos);
        }, this.settings.speed + this.settings.delayAnimation);

      } else if(this.settings.initialPos !== this.settings.targetPos){
        //option 2 - progress animates from 0 to target (no initial value)
        this.timer = setTimeout(function() {
          me.animateCircle(me.settings.initialPos, me.settings.targetPos);
        }, this.settings.speed + this.settings.delayAnimation);
      } else {
        //option 3 - progress appears immediately at target (no initial value, no animate)
        this.setTargetPos(this.settings.initialPos);
      }
    },

    //given a scale and a position, work out actual degrees
    calculateScale: function(position){
      var multiplier = 360 / this.settings.scale;

      return (Math.ceil(position * multiplier));
    },

    convertScale: function(degrees){
      var divider = 360 / this.settings.scale;

      return (Math.floor(degrees / divider));
    },

    //set the position of the circle, no animation
    setTargetPos: function(targ){
      this.overallpos = targ;
      this.renderCircle();
    },

    //given a starting point and an end point, animate the progress
    //self calls itself until complete
    animateCircle: function(orig, targ){
      var rotateby = this.settings.rotateBy;
      if(targ < orig){
        rotateby = -rotateby;
      }
      var newpos = orig + rotateby;

      if(orig < targ){
        newpos = Math.min(newpos, targ);
      } else {
        newpos = Math.max(newpos, targ);
      }
      var me = this;
      this.overallpos = newpos;
      if(newpos !== targ) {
        this.timer = setTimeout(function() {
            me.animateCircle(newpos, targ);
        }, this.settings.speed);
      } else {
        var output = this.convertScale(this.overallpos);

        if (!!this.settings.loop) {
          this.initialSpinner();
        } else {
          if($.isFunction(this.settings.onFinishMoving)) {
            this.settings.onFinishMoving.call(this, output); //call callback
          }
        }
      }
      this.renderCircle();
    },

    //draws the circular progress using the current position
    renderCircle: function(){
      if (this.overallpos < 180) {
        this.rotateElement(this.rpanel, this.overallpos);
        this.rotateElement(this.lpanel, 0);
      } else {
        this.rotateElement(this.rpanel, 180);
        this.rotateElement(this.lpanel, this.overallpos - 180);
      }
      if (this.settings.showProgress) {
        var output = this.settings.progPreText + this.convertScale(this.overallpos) + this.settings.progPostText;
        this.innerprogress.html(output);
      }

      if (!!output) {
        // accessibility rules
        this.$elem.attr('aria-valuenow', output.replace('%', ''));
      }
    },

    //given an element, apply a css transform to rotate it
    rotateElement: function(elem, deg){
      elem.css({
        'transform': 'rotate(' + deg + 'deg)',
        '-ms-transform': 'rotate(' + deg + 'deg)',
        '-moz-tranform': 'rotate(' + deg + 'deg)',
        '-webkit-transform': 'rotate(' + deg + 'deg)',
        '-o-transform': 'rotate(' + deg + 'deg)'
      });
    },

    // Remove plugin instance and clean up
    destroy: function() {
      var me = this;

      clearTimeout(me.timer);

      me.$elem.removeAttr('aria-valuemin');
      me.$elem.removeAttr('aria-valuemax');
      me.$elem.removeAttr('aria-valuenow');
      $('.cb-progressinner', me.$elem).remove();

      me.$elem.removeData('cbSpinner');
    }
  };

  $.fn.cbSpinner = function(options){
    if (options === undefined || typeof options === 'object') {
      //create plugin instance for each element and store reference to the plugin within the data attr
      return this.each(function() {
        if (!$.data(this, 'cbSpinner')) {
          $.data(this, 'cbSpinner', new cb.apricot.spinner(this, options));
        }
      });
    }
  };
}(jQuery, cb);
