/* ========================================================================
 * Anchor Menu
 * ========================================================================
 *
 * Style and behavior adjustment for Anchor Menus
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  cb.apricot.stickyBar = function (element, options) {
    var
      defaultOptions = {
        $trigger: {},
        autoGenerate: false,
        truncate: true
      },
      $mainBar = {},
      $trigger = {},
      stickyTopPosition = 0,
      plugin = this;

    plugin.$el = $(element);

    var
    buildStickyBar =  function () {
    	var
        stickyBarObj = {};

    	if (!$.isEmptyObject(plugin.options.stickyBarObj)) { 
        stickyBarObj = plugin.options.stickyBarObj
      } else { //parse page
        // preamble
        stickyBarObj.preamble = ($('[data-cb-sticky-bar="preamble"]').length > 0) ? $('[data-cb-sticky-bar="preamble"]').html() : '';
        
        // title
        stickyBarObj.title = ($('[data-cb-sticky-bar="title"]').length > 0) ? $('[data-cb-sticky-bar="title"]').html() : '';
        
        // content
        stickyBarObj.$content = ($('[data-cb-sticky-bar="content"]').length > 0) ? $('[data-cb-sticky-bar="content"]').clone() : {};
        
        // cta
        stickyBarObj.$cta = ($('[data-cb-sticky-bar="cta"]').length > 0) ? $('[data-cb-sticky-bar="cta"]').clone() : {};
    	}

      buildMainBar(stickyBarObj);
    },

    buildMainBar = function (data) {
      $mainBar = $('<div/>',{'class': 'cb-sticky-bar-container'})
      var $container = $('<div />', {'class': 'cb-sticky-container'}),
      $cta = $('<div />', {'class': 'cb-sticky-cta'});

      if (data.preamble !== '') {
        $container.append(
          $('<p />', {'class': 'cb-sticky-preamble'}).html(data.preamble)
        )
      }

      if (data.title !== '') {
        $container.append(
          $('<h2 />', {'class': 'cb-sticky-title'}).html(data.title)
        )
      }
      
      if (!$.isEmptyObject(data.$content)) {
        $container.append($('<div/>', {'class': 'cb-sticky-content'}).append(data.$content))
      }
      
      if (!$.isEmptyObject(data.$cta)) {
        $cta.append(data.$cta)
      }

      $mainBar
        .append($container)
        .append($cta);

      plugin.$el.append(
        $('<div />', {'class': 'container'}).append(
          $('<div />', {'class': 'row'}).append(
            $('<div />', {'class': 'col-xs-12'})
              .append($mainBar)
          )
        )
      )

      if (!!plugin.options.truncate) {
        $('.cb-sticky-preamble', plugin.$el).addClass('cb-truncate-xs')
          .addClass('cb-truncate-sm')

        $('.cb-sticky-title', plugin.$el).addClass('cb-truncate-xs')
          .addClass('cb-truncate-sm')
      }
    },

    activateStickyBar = function () {
      var height = parseInt(plugin.$el.height(), 10);
      if (height > 0) {
        plugin.$el.css('top', '-' + (plugin.$el.outerHeight() + 10) + 'px');
      }
      // activate sticky Bar
      $(window).on('scroll.cbStickyBar', function() {
        stickyTopPosition = ($trigger.length > 0 ) ? parseInt($trigger.offset().top, 10) : 0;

        if ($(this).scrollTop() >= stickyTopPosition && stickyTopPosition !== 0){
          plugin.$el
            .addClass('cb-sticky-bar-active')
        } else{
          plugin.$el.removeClass('cb-sticky-bar-active')
        }
      });
    };

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, options);

      $trigger = (plugin.options.$trigger.length > 0 ) ? plugin.options.$trigger : 
        ($('[data-cb-sticky-trigger="true"]').length > 0) ? $('[data-cb-sticky-trigger="true"]') : {};

      if (!!plugin.options.autoGenerate) {
      	buildStickyBar();
      } else {
      	$mainBar = $('.cb-sticky-bar-container', plugin.$el);
      }

      activateStickyBar()      
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
    	if (!!plugin.options.autoGenerate) {
    		$mainBar.remove();
    	}

      $(window).off('scroll.cbStickyBar');
      plugin.$el.removeData('cbStickyBar');
    };

    plugin.init();
  };

  $.fn.cbStickyBar = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbStickyBar')) {
          $(this).data('cbStickyBar', new cb.apricot.stickyBar(this, options));
        }
       });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbStickyBar');
        if (instance instanceof cb.apricot.stickyBar && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call(args, 1) );
        }
      });
    }
  };
}(jQuery, cb);
