/*!
 *  Apricot v3.4.0
 *  By:             The College Board
 *  App:            apricot
 *  Build Time:     2019-04-02 [10:28:53 AM] EDT
 *  Build Number:   unknown
 *  SVN Revision:   unknown
 *  Jenkins Job:    unknown
 *  This version of Apricot includes Bootstrap v3.7.0
 */

/* ========================================================================
 * Modals
 * ========================================================================
 *
 * Extension for bootstrap modal to add additional features to  modals
 * ======================================================================== */

+ function($) {
  'use strict';

  $.fn.modal.Constructor.prototype.show = function(_relatedTarget) {
    var that = this
    var e = $.Event('show.bs.modal', {relatedTarget: _relatedTarget})

    this.$element.trigger(e)

    if (this.isShown || e.isDefaultPrevented()) return;

    // Mitra Assadi: disable scroll bar
    $('body').css('overflow', 'hidden');

    this.isShown = true

    this.checkScrollbar()
    this.setScrollbar()
    this.$body.addClass('modal-open')

    this.escape()
    this.resize()


    this.$element.on('click.dismiss.bs.modal', '[data-dismiss="modal"]', $.proxy(this.hide, this))

    this.$dialog.on('mousedown.dismiss.bs.modal', function () {
      that.$element.one('mouseup.dismiss.bs.modal', function (e) {
        if ($(e.target).is(that.$element)) that.ignoreBackdropClick = true
      })
    })

    this.backdrop(function() {
      var transition = $.support.transition && that.$element.hasClass('fade')

      if (!that.$element.parent().length) {
        that.$element.appendTo(that.$body) // don't move modals dom position
      }

      that.$element
        .show()
        .scrollTop(0)

      that.adjustDialog()

      if (transition) {
        that.$element[0].offsetWidth // force reflow
      }

      that.$element.addClass('in')

      that.enforceFocus()

      // Mitra Assadi: adjust content height
      that.setHeight()

      var e = $.Event('shown.bs.modal', {relatedTarget: _relatedTarget})

      transition ?
        that.$dialog // wait for modal to slide in
        .one('bsTransitionEnd', function() {
          that.$element.trigger('focus').trigger(e)
        })
        .emulateTransitionEnd($.fn.modal.Constructor.TRANSITION_DURATION) :
        that.$element.trigger('focus').trigger(e)
    })
  }

  // Mitra Assadi: calculate modal margin
  $.fn.modal.Constructor.prototype.setMargin = function() {
    var that = this,
      pageWidth = $(window).width(),
      $dialog = that.$element.find('.modal-dialog'),
      dialogWidth = parseInt($dialog.outerWidth(), 10),
      modalSpacing = (!!that.$element.hasClass('cb-video-modal')) ? 16 :
        parseInt($('.modal-body', $dialog).css('padding-left'), 10);

    if ((dialogWidth + (modalSpacing * 2)) >= pageWidth) { //adjust margin
      modalSpacing = parseInt($('.modal-body', $dialog).css('padding-left'), 10) + 'px';

      if (!!that.$element.hasClass('cb-video-modal')) {
        modalSpacing = '16px'
      }
    } else {
      modalSpacing = 'auto';
    }

    return modalSpacing;
  }
  // Mitra Assadi: calculate content height
  $.fn.modal.Constructor.prototype.setHeight = function(mode) {
    var that = this,
      pageHeight = $(window).height(),
      pageWidth = $(window).width(),
      $dialog = that.$element.find('.modal-dialog'),
      dialogMargin = parseInt($dialog.css('margin-top'), 10),
      dialogHeight = parseInt($dialog.css('height'), 10),
      height = parseInt(pageHeight, 10) - (dialogMargin * 2),

      width = parseInt(pageWidth, 10) - (32), //16 * 2
      headerHeight = ($('.modal-header', $dialog).length > 0 ) ? parseInt($('.modal-header', $dialog).outerHeight(), 10) : 30, //45: based on design
      footerHeight = ($('.modal-footer', $dialog).length > 0 ) ? parseInt($('.modal-footer', $dialog).outerHeight(), 10) : 30, //45: based on design
      $dialogBody = $('.modal-body', $dialog),
      margin = 0;

    // video modal has no header or footer
    // extra size treatment
    if (that.$element.hasClass('cb-video-modal')) {
      var
        w = 0,
        h = 0;

      if (pageWidth < pageHeight) {
        w = (width * 98)/100;
        h = Math.round((w * 9)/16);
      } else {
        h = (height * 98)/100;
        w = Math.round((h * 16)/9);
      }

      // extra adjustment for edge cases
      if (w > pageWidth) {
        w = pageWidth - 32;
        h = Math.round((w * 9)/16);
      }

      $dialog.css({
        'width': w + 'px',
        'height': h + 'px'
      });
      $dialog.css('margin', '64px auto');

      // reset value
      dialogHeight = h;
    }


    // just adjust margin top/bottom on page resize
    if (!!that.$element.hasClass('cb-photo-gallery-mt')) {

      $dialog.css('margin', '44px auto auto auto');
    } else {
      if (!!mode) {
        margin = parseInt((pageHeight - dialogHeight) / 2, 10);
        $dialog.css('margin-top', margin + 'px ');
        $dialog.css('margin-bottom', margin + 'px ');
      } else {
        if (dialogHeight < height) { //adjust margin
          margin = parseInt((pageHeight - dialogHeight) / 2, 10);
          $dialog.css('margin', margin + 'px ' + that.setMargin());
        } else { // adjust height
          if (!that.$element.hasClass('cb-photo-gallery-modal')) {
            $dialog.css('height', height + 'px').addClass('cb-modal-dialog');
            $dialogBody.css({
              'height': height - (headerHeight + footerHeight),
              'overflow-y': 'auto'
            });
          }
        }
      }
    }

    // Accessibility
    that.$element.attr('aria-modal', true);
  }
  // Mitra Assadi: reset content height
  $.fn.modal.Constructor.prototype.resetHeight = function() {
      var that = this,
        $dialog = that.$element.find('.modal-dialog'),
        $dialogBody = $('.modal-body', $dialog);

      $dialog.css({
        'margin': '',
        'height': ''
      }).removeClass('cb-modal-dialog');


      // Accessibility
      that.$element.removeAttr('aria-modal');

      $dialogBody.css({
        'height': '',
        'overflow-y': 'auto'
      });
  }
  $.fn.modal.Constructor.prototype.hideModal = function() {
    var that = this

    this.$element.hide()
    this.backdrop(function() {
      that.$body.removeClass('modal-open')
      that.resetAdjustments()
      that.resetScrollbar()
      // Mitra Assadi: reset content height
      that.resetHeight()
      that.$element.trigger('hidden.bs.modal')
    })
  }
  $.fn.modal.Constructor.prototype.resetScrollbar = function() {
    this.$body.css('padding-right', this.originalBodyPad)
      .css('overflow', 'auto') // Mitra Assadi: reset page setting
  }
  $.fn.modal.Constructor.prototype.measureScrollbar = function() {
    // Mitra Assadi: calculate modal height
    if ($('body').height() < $(window).height()) return 0
    var scrollDiv = document.createElement('div')
    scrollDiv.className = 'modal-scrollbar-measure'
    this.$body.append(scrollDiv)
    var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
    this.$body[0].removeChild(scrollDiv)
    return scrollbarWidth
  }

  $(window).on('resize.cbModal', function () {
    var that = $('.modal.cb-photo-gallery-modal.in');

    if (that.length > 0) {
      that.modal('setHeight', true);
    }
  });
}(jQuery);