/* ========================================================================
 * Apricot's localization Module
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  var
  defaultOptions = {
		breadcrumbs1: {
			en: 'show collapsed breadcrumb items',
			es: 'mostrar los elementos de la ruta de navegación'
		},
		breadcrumbs2: {
			en: 'collapse breadcrumb navigation',
			es: 'cerrar ruta de navegación'
		},
		filter1: {
			en: 'applied filters ({value} filter(s) applied)',
			es: 'filtros elegidos({value} filtro(s) elegido(s))'
		},
		filter2: {
			en: 'applied filters (no filters are currently applied)',
			es: 'filtros elegidos (no se ha applicado ningún filtro)'
		},
		filter3: {
			en: 'remove filter',
			es: 'eliminar filtro'
		},
		filter4: {
			en: 'filter modal',
			es: 'módulo para filtrar'
		},
		filter5: {
			en: 'Filters',
			es: 'Filtros'
		},
		creditCard1: {
			en: 'Enter credit card number',
			es: 'Ingrese el número de tarjeta de crédito'
		},
		creditCard2: {
			en: 'Credit card type is ',
			es: 'El tipo de tarjeta de crédito es '
		},
		creditCard3: {
			en: 'Enter valid credit card number',
			es: 'Ingrese un número de tarjeta de crédito válido'
		},
		intPhone1: {
			en: 'Select country code',
			es: 'Elija el código de país'
		},
		intPhone2: {
			en: 'Country code for {value} is selected',
			es: 'Código de país para {value} elegido'
		},
		ctaAccordion1: {
			en: 'See Less ({value})',
			es: 'Ver menos ({value})'
		},
		ctaAccordion2: {
			en: 'See More ({value})',
			es: 'Ver más ({value})'
		},
		itemList1: {
			en: 'See Less ({value})',
			es: 'Ver menos ({value})'
		},
		itemList2: {
			en: 'See More ({value})',
			es: 'Ver más ({value})'
		},
		legend1: {
			en: 'legend modal',
			es: 'Módulo de leyenda'
		},
		localNav1: {
			en: 'Open navigation',
			es: 'Abrir navegación'
		},
		localNav2: {
			en: 'Active Page: ',
			es: 'Página activa: '
		},
		localNav3: {
			en: 'Close navigation',
			es: 'Cerrar navegación'
		},
		localNav4: {
			en: 'go back to previous navigation menu ',
			es: 'volver al menú de navegación anterior '
		},
		localNav5: {
			en: 'Activate to enable dropdown',
			es: 'Elegir para abrir el menu'
		},
		photoGallery1: {
			en: 'next gallery image, show image ',
			es: 'Próxima imagen, mostrar '
		},
		photoGallery2: {
			en: 'previous gallery image, show image ',
			es: 'Imagen anterior, mostrar '
		},
		photoGallery3: {
			en: 'Gallery Image {current} of {count}, {alt}',
			es: 'Imagen {current} de {count}, {alt}'
		},
		photoGallery4: {
			en: 'Gallery Image {current} of {count}',
			es: 'Imagen {current} de {count}'
		},
		photoGallery5: {
			en: 'Gallery Image Description',
			es: 'Descripción de la Imagen de la Galería'
		},
		photoGallery6: {
			en: 'previous gallery image',
			es: 'Imagen anterior'
		},
		photoGallery7: {
			en: 'next gallery image',
			es: 'Próxima imagen'
		},
		progressBar1: {
			en: 'Loading ...',
			es: 'Cargando ...'
		},
		resource1: {
			en: 'See Less ({value})',
			es: 'Ver menos ({value})'
		},
		resource2: {
			en: 'See More ({value})',
			es: 'Ver más ({value})'
		},
		stepper1: {
			en: 'step {step} of {total} {status}',
			es: 'paso {step} de {total} {status}'
		}
	};

  function _extend(options) {
    if (typeof options === 'object') {
      $.extend(true, defaultOptions, options);
    }

    return defaultOptions;
  }

  function _get(key, lang) {
    var
      label = '';

    lang = _getLang(lang);

    if (!!defaultOptions[key]) {
      label = defaultOptions[key][lang];
    }

    return label;
  }

  function _getLang(lang) {
    lang = (lang === undefined) ? cb.apricot.language : lang;

    return lang;
  }

  function _setLang(lang) {

  	cb.apricot.language = lang;
  	$('html').attr('lang', lang);
  }

  //Replace variables
  function _getReplace(key, value, lang) {
    var
      txt = _get(key, lang);

    if (!!$.isArray(value)) {
    	$.each(value, function (i, obj) {
        txt = txt.replace(new RegExp('\\{' + obj.name + '\\}', 'gi'), function () {
          return obj.value;
        });
      });
    } else {
    	txt = txt.replace(new RegExp('{value}', 'g'), value);
    }

    return txt;
  }

  // ----- cb Apricot localization module public API
  var _lang = {
  	extend: _extend,
    get: _get,
    getReplace: _getReplace,
    getLang: _getLang,
    setLang: _setLang
  };

  cb.apricot.lang = _lang;

  // apricot language
	cb.apricot.language = cb.apricot.utils.detectLang();

}(jQuery, cb);
