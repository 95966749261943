/* ========================================================================
 * Dropdown Extension
 * ========================================================================
 *
 * Bootstrap: dropdown.js v3.3.7
 * http://getbootstrap.com/javascript/#dropdowns
 *
 * Mitra Assadi, 5/30/2017
 * Toggle functionality has beed changed to handle right alignment
 *
 * ========================================================================
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * ========================================================================
 *
 * $.fn.cbDropdownExtension
 *
 * This extension handles accessibility features for dropdown inside Tabs
 * Based on Global Header Module, College Board 2015-2017
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  var backdrop = '.dropdown-backdrop'
  var toggle   = '[data-toggle="dropdown"]'
  var Dropdown = function (element) {
    $(element).on('click.bs.dropdown', this.toggle)
  }

  Dropdown.VERSION = '3.3.7'

  function getParent($this) {
    var selector = $this.attr('data-target')

    if (!selector) {
      selector = $this.attr('href')
      selector = selector && /#[A-Za-z]/.test(selector) && selector.replace(/.*(?=#[^\s]*$)/, '') // strip for ie7
    }

    var $parent = selector && $(selector)

    return $parent && $parent.length ? $parent : $this.parent()
  }

  function clearMenus(e) {
    if (e && e.which === 3) return
    $(backdrop).remove()
    $(toggle).each(function () {
      var $this         = $(this)
      var $parent       = getParent($this)
      var relatedTarget = { relatedTarget: this }

      // Mitra Assadi: caret adjustment
      var $down = $('.cb-down', $this)
      var $up = $('.cb-up', $this)

      if (!$parent.hasClass('open')) return

      if (e && e.type == 'click' && /input|textarea/i.test(e.target.tagName) && $.contains($parent[0], e.target)) return

      $parent.trigger(e = $.Event('hide.bs.dropdown', relatedTarget))

      if (e.isDefaultPrevented()) return

      $this.attr('aria-expanded', 'false')
      $parent.removeClass('open').trigger($.Event('hidden.bs.dropdown', relatedTarget))

      // Mitra Assadi: caret adjustment
      $down.removeClass('hidden')
      $up.addClass('hidden')
    })
  }

  Dropdown.prototype.toggle = function (e) {
    var $this = $(this)

    if ($this.is('.disabled, :disabled')) return

    var $parent  = getParent($this)
    var isActive = $parent.hasClass('open')


    // Mitra Assadi: caret adjustment
    var $down = $('.cb-down', $this)
    var $up = $('.cb-up', $this)

    // Mitra Assadi: calculate menu drop downs position
    var $menu = $this.next();
    var mWidth = $menu.width();
    var wWidth = $(window).width();
    var pLeft = $(this).offset().left;


    // Mitra Assadi: if needed right align drop down element
    if (parseInt(mWidth +  pLeft) > parseInt(wWidth)) {
      $parent.addClass('cb-menu-right');
    }

    clearMenus()

    if (!isActive) {
      if ('ontouchstart' in document.documentElement && !$parent.closest('.navbar-nav').length) {
        // if mobile we use a backdrop because click events don't delegate
        $(document.createElement('div'))
          .addClass('dropdown-backdrop')
          .insertAfter($(this))
          .on('click', clearMenus)
      }

      var relatedTarget = { relatedTarget: this }
      $parent.trigger(e = $.Event('show.bs.dropdown', relatedTarget))

      if (e.isDefaultPrevented()) return

      $this
        .trigger('focus')
        .attr('aria-expanded', 'true')

      $parent
        .toggleClass('open')
        .trigger($.Event('shown.bs.dropdown', relatedTarget))

      // Mitra Assadi: caret adjustment
      $down.addClass('hidden')
      $up.removeClass('hidden')

      // Mitra Assadi: accessibility, set focus to first item in the list
      var desc = ' li:not(.disabled):visible a'
      var $items = $parent.find('.dropdown-menu' + desc)

      if (!$items.length) return

      $items.eq(0).trigger('focus');
    }

    return false
  };


  Dropdown.prototype.keydown = function (e) {
    if (!/(38|40|27|32)/.test(e.which) || /input|textarea/i.test(e.target.tagName)) return

    var $this = $(this)

    e.preventDefault()
    e.stopPropagation()

    if ($this.is('.disabled, :disabled')) return

    var $parent  = getParent($this)
    var isActive = $parent.hasClass('open')

    if (!isActive && e.which != 27 || isActive && e.which == 27) {
      if (e.which == 27) $parent.find(toggle).trigger('focus')
      return $this.trigger('click')
    }


    var desc = ' li:not(.disabled):visible a'
    var $items = $parent.find('.dropdown-menu' + desc)

    if (!$items.length) return

    var index = $items.index(e.target)

    if (e.which == 38 && index > 0)                 index--                        // up
    if (e.which == 40 && index < $items.length - 1) index++                        // down
    if (!~index)                                      index = 0

    $items.eq(index).trigger('focus')
  }

  // DROPDOWN PLUGIN DEFINITION
  // ==========================

  function Plugin(option) {
    return this.each(function () {
      var $this = $(this)
      var data  = $this.data('bs.dropdown')

      if (!data) $this.data('bs.dropdown', (data = new Dropdown(this)))
      if (typeof option == 'string') data[option].call($this)
    })
  }

  var old = $.fn.dropdown

  $.fn.dropdown             = Plugin
  $.fn.dropdown.Constructor = Dropdown


  // DROPDOWN NO CONFLICT
  // ====================

  $.fn.dropdown.noConflict = function () {
    $.fn.dropdown = old
    return this
  }


  // APPLY TO STANDARD DROPDOWN ELEMENTS
  // ===================================

  $(document)
    .on('click.bs.dropdown.data-api', clearMenus)
    .on('click.bs.dropdown.data-api', '.dropdown form', function (e) { e.stopPropagation() })
    .on('click.bs.dropdown.data-api', toggle, Dropdown.prototype.toggle)
    .on('keydown.bs.dropdown.data-api', toggle, Dropdown.prototype.keydown)
    .on('keydown.bs.dropdown.data-api', '.dropdown-menu', Dropdown.prototype.keydown)

  // ------- Apricot's extension
  cb.apricot.dropdownExtension = function (element) {
    var
      plugin = this,
      keys = cb.apricot.utils.keys,
      $dropdown = {},
      $dropdownToggle = {},
      $doc = $(document),
      tabbingBack = false;

    plugin.$el = $(element);

    plugin.init = function () {
      if (!!plugin.$el.hasClass('dropdown')) {
        $dropdown = plugin.$el;
      } else {
        $dropdown = $('.dropdown', plugin.$el);
      }

      $dropdownToggle = $('.dropdown-toggle', $dropdown);
      //make sure menus close if user clicks outside of menu
      // can't be called on document because elements with default prevented will not trigger document click
      $doc.on('click.cbDropdownExtension', '*', $.proxy(function(e) {
        closeDropdownMenu($(e.target));
      }, this));

      $doc.on('keydown.cbDropdownExtension', $.proxy(function(e) {
        var
          $target = $(e.target);

        if (e.which === keys.ESC) { //make sure menu is closed on escape
          closeDropdownMenu($target, true);
        } else if(e.which === keys.TAB) { //make sure menus are closed after tab away
          var tabbables = $('a, input:enabled, button:enabled').filter(':visible'),
              targetIndex = tabbables.index(e.target);

          tabbingBack = e.shiftKey;

          if(tabbingBack && targetIndex !== 0) {
            $target = $(tabbables.eq(targetIndex - 1));
            closeDropdownMenu($target);
          } else if(!tabbingBack && targetIndex !== tabbables.length - 1) {
            $target = $(tabbables.eq(targetIndex + 1));
            closeDropdownMenu($target);
          } else {
            closeDropdownMenu($target, true);
          }
        } else if(e.which === keys.UP || e.which === keys.DOWN) { //up/down arrows
          checkActiveDropdown(e);
        }
      }, this));

      //toggle arrows
      $dropdown.on('hide.bs.dropdown show.bs.dropdown', function () {
        $(this).find('.cb-glyph.hidden').removeClass('hidden').siblings('i').addClass('hidden');
      });

      //bootstrap toggle method sets focus on dropdown toggle after it triggers shown event,
      //below will override and set focus on first item of dropdown
      $doc.off('focus.cbDropdownExtension').on('focus.cbDropdownExtension', $dropdownToggle, function() {
        var
          $this = $(this),
          $parent = $this.parent();

        if($parent.hasClass('open') && !tabbingBack) {
          $parent.find('> ul li:first a').focus();
          return false;
        }
      });
    };

    //bootstrap dropdown toggle, doesn't close with keyboard by default
    var closeDropdownMenu = function($target, immediateClose) {
      var
        $openDropdown = !!plugin.$el.hasClass('dropdown') ?
        (!!plugin.$el.hasClass('open') ? plugin.$el : {} ) :
        $('.dropdown.open', plugin.$el);

       if($openDropdown.length > 0) {
        if(!immediateClose) { //user triggered keydown event to close menu only if not focused on an item within it
          $openDropdown = $openDropdown.filter(function() {
            var $this = $(this);
            return !($target.is($this) || $target.closest($this).length > 0 || $this.find($target).length > 0);
          });
        }

        if($openDropdown.length > 0) { //recheck length in case it changed from above if condition
          $openDropdown.find('.dropdown-toggle').dropdown('toggle');
        }
      }
    },

    checkActiveDropdown = function(e) {
      var
        $openDropdown = !!plugin.$el.hasClass('dropdown') ?
        (!!plugin.$el.hasClass('open') ? plugin.$el : {} ) :
        $('.dropdown.open', plugin.$el),
        $dropdownFocus = {};

      if($openDropdown.length > 0) {
        $dropdownFocus = $openDropdown.find(':focus');

        if($openDropdown.length !== 0 && $dropdownFocus.length !== 0) {
          if(($openDropdown.find('ul li a:last').is(':focus') && e.which === keys.DOWN) ||
              $openDropdown.find('ul li a:first').is(':focus') && e.which === keys.UP) {
            e.preventDefault(); //prevent default when user reaches top/bottom of the list using arrows
          }
        }
      }
    };

    // Remove plugin instance
    plugin.destroy = function () {
      var
        $doc = $(document);

      $doc.off('click.cbDropdownExtension');
      $doc.off('keydown.cbDropdownExtension');
      $doc.off('focus.cbDropdownExtension');

      plugin.$el.removeData('cbDropdownExtension');
    };

    plugin.init();
  };

  $.fn.cbDropdownExtension = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbDropdownExtension')) {
          $(this).data('cbDropdownExtension', new cb.apricot.dropdownExtension(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbDropdownExtension');
        if (instance instanceof cb.apricot.dropdownExtension && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
        }
      });
    }
  };

}(jQuery, cb);