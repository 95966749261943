/* ========================================================================
 * Filter List
 * ========================================================================
 *
 * Style and behavior adjustment for Filter List
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  cb.apricot.filterList = function (element, options) {
    var
      defaultOptions = {
        desktopClass: 'cb-filter-list-desktop',
        mobileClass: 'cb-filter-list-mobile',
        filterValue: ''
      },
      $desktopFilter = {},
      $mobileFilter = {},
      $select = {},
      filterValue = '',
      plugin = this;

    plugin.$el = $(element);

    var
    getMenuItems = function () { //Populate dropDowns
    	var
      	mainItems = [];

      $('li', plugin.$el).each(function () {
        var self = $(this);

        mainItems.push(self.clone());

        if (plugin.options.filterValue !== '') {
          filterValue = plugin.options.filterValue;
        } else if (!!self.hasClass('active')) {
          filterValue = $('a', self).html();
        }
      });

      // build Mobile menu
      buildMobileMenu(mainItems);
    },

    buildMobileMenu = function (items) {
    	var
        ariaLabel = ($desktopFilter.attr('aria-label')) ? $desktopFilter.attr('aria-label') : 'Filter List';

      $mobileFilter = $('<div/>')
        .addClass(plugin.options.mobileClass)
        .addClass('hidden')
        .insertAfter($desktopFilter);

    	$select = $('<select/>')
        .addClass('form-control')
        .attr('aria-label', ariaLabel)
        .appendTo($mobileFilter);


    	for (var item in items) {
        var value = $('a', items[item]).html();

        $select.append(
          $('<option />', {'value': value}).html(value)
        )
    	}

      // activate apricots select
      setActiveFilter(filterValue)
      $select.cbCustomSelect();
    },

    setActiveFilter = function (filter) {
      if(filter == undefined) {
        return false;
      }
      // Desktop Layout 
      $('li', plugin.$el).each(function () {
        var self = $(this)

        if ($('a', self).html() === filter) {
          self.addClass('active');
          $('a', self).attr('aria-current', true);
        } else {
          self.removeClass('active');
          $('a', self).removeAttr('aria-current');
        }
      })

      //  Mobile Layout
      $select.val(filter).trigger('value_changed');
    },

    breakpointChanges = function () {
      // check desktop vs. mobile
      checkMenuStatus();

      //Check breakpoint status on resize
      $(window).on('resize.cbAnchorMenu', function () {
        checkMenuStatus();
      });
    },

    // figure out which structure we need (mobile/desktop)
    checkMenuStatus = function () {
      var
        currentViewport = cb.apricot.utils.viewport();

      changeMenuStructure(currentViewport.width);
    },

    // switch between mobile/desktop structure
    changeMenuStructure = function (width) {
      var
        maxWidth = cb.apricot.data.viewports.tablet.max;

      if (maxWidth === 0) {
        return false;
      }

      if (width <= maxWidth) {
        switchNavigation(1);
      } else {
        switchNavigation(0);
      }
    },

    //0: desktop
    //1: mobile
    switchNavigation = function (mode) {
      if (!!mode) {
        $desktopFilter.addClass('hidden');
        $mobileFilter.removeClass('hidden');
      } else {
        $mobileFilter.addClass('hidden');
        $desktopFilter.removeClass('hidden');
      }
    };

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, options);

      	$desktopFilter = $('.' + plugin.options.desktopClass, plugin.$el);
      	getMenuItems();

      // track breakpoint changes
      breakpointChanges();
    };

    plugin.activateFilter = function(val) {
      setActiveFilter(val)
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      $mobileFilter.remove();

      $(window).off('resize.cbFilterList');
      plugin.$el.removeData('cbFilterList');
    };

    plugin.init();
  };

  $.fn.cbFilterList = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbFilterList')) {
          $(this).data('cbFilterList', new cb.apricot.filterList(this, options));
        }
       });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbFilterList');
        if (instance instanceof cb.apricot.filterList && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call(args, 1) );
        }
      });
    }
  };
}(jQuery, cb);
