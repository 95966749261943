/* ========================================================================
 * Popover List Items
 * ========================================================================
 *
 * $.fn.cbPopOverListItems
 *
 * Adds Accessibility to CB's custom popOver with menu items
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  if (!$.fn.popover) throw new Error('cbPopOverListItems requires popover.js');
  cb.apricot.popOverListItems = function (element, options) {
    var
      defaultOptions = {
        onScreen: true,
        focus: false
      },
      plugin = this;

    plugin.$el = $(element);

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, options);

      plugin.$el.addClass('cb-popover-list-menu');

      plugin.$el.click(function (e) {
        e.preventDefault();

        // Only instantiate if the popOver plugin is active
        if (!!plugin.$el.attr('aria-describedby')) {
          var
            popoverParentCode = plugin.$el.attr('aria-describedby'), //This attribute gets added by popOver plugin
            $listItems = $('#' + popoverParentCode).find('.popover-content'),
            popOverOptions = plugin.$el.data('bs.popover').options,
            placement = typeof popOverOptions.placement == 'function' ? popOverOptions.placement() : popOverOptions.placement,
            listHeight = $listItems.height() + 11, //the arrow is 11px
            windowAdjustment = 0;


          // declare key navigation inside the menu
          $listItems.find('li').each(function () {
            var
              item = $(this);

            item.attr('data-cb-popover-parent', popoverParentCode);
            item.keydown(function (e) {
              var
                self = $(this);

              if (!!cb.apricot.utils.isKey(e, 'DOWN')) { //down
                e.preventDefault();
                self.next().find('a').focus();

              } else if (!!cb.apricot.utils.isKey(e, 'UP')) { //up
                e.preventDefault();
                self.prev().find('a').focus();

              } else if (!!cb.apricot.utils.isKey(e, 'TAB') && e.shiftKey) { //shift & tab
                e.preventDefault();
                self.prev().find('a').focus();

              } else if (!!cb.apricot.utils.isKey(e, 'ESC')) { //esc
                e.preventDefault();
                plugin.$el.popover('hide');
                plugin.$el.focus();
              }
            });
          });

          $listItems.find('li:first').keydown(function (e) {
            if (!!cb.apricot.utils.isKey(e, 'TAB') && e.shiftKey) { //shift & tab
              e.preventDefault();
              plugin.$el.popover('hide');
              plugin.$el.focus();
            }
          });

          $listItems.find('li:last').keydown(function (e) {
            if (!!cb.apricot.utils.isKey(e, 'TAB') && !e.shiftKey) { //tab
              e.preventDefault();
              plugin.$el.popover('hide');
              plugin.$el.focus();
            }
          });

          //Set focus to first item in the list
          if (!!plugin.options.focus) {
            $listItems.find('a:first').focus();
          }

          //Make sure the whole list is visible
          if (!!plugin.options.onScreen) {
            windowAdjustment = isOnScreen(placement, listHeight);
            if (windowAdjustment !== 0) {
              var
                browser = cb.apricot.utils.browser();

              if (!!browser.msie && parseInt(browser.version, 10) < 9){
                window.scrollTo(1, windowAdjustment);
              } else {
                $('body, html').animate({
                  scrollTop: windowAdjustment
                }, 1000);
              }
            }
          }
        }
      });

      plugin.$el.keydown(function (e) {
        if (!!cb.apricot.utils.isKey(e, 'TAB')) { //tab
            var
              popoverParentCode = plugin.$el.attr('aria-describedby'),
              $listItems = {};

          if ($('#' + popoverParentCode).is(':visible')) {
            e.preventDefault();
            $listItems = $('#' + popoverParentCode).find('.popover-content');

            $listItems.find('a:first').focus();

          }
        } else if (!!cb.apricot.utils.isKey(e, 'SPACE')) { //space
          e.preventDefault();
          
          plugin.$el.trigger('click');
        }
      });
    };

    var
    isOnScreen = function (placement, height) {
      var
        viewport = {},
        position = {},
        point = 0,
        adjustment = 0;

      viewport.top = $(window).scrollTop();
      viewport.bottom = viewport.top + $(window).height();

      position.top = plugin.$el.offset().top;
      position.bottom = position.top + plugin.$el.outerHeight();

      if (placement.toLowerCase() === 'top') {
        point = position.top - height;

        if (point < viewport.top) {
          adjustment = position.top - (position.top - point);
        }
      } else if (placement.toLowerCase() === 'bottom') {
        point = position.bottom + height;

        if (point > viewport.bottom) {
          adjustment = viewport.top + (point - viewport.bottom);
        }
      }

      return adjustment;
    };

    plugin.init();
  };

  $.fn.cbPopOverListItems = function (options) {
    return this.each(function () {
      if (!$(this).data('cbPopOverListItems')) {
        $(this).data('cbPopOverListItems', new cb.apricot.popOverListItems(this, options));
      }
    });
  };
}(jQuery, cb);
