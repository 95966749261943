/* ========================================================================
 * Form Elements Add-Ons
 * ========================================================================
 *
 * $.fn.cbCustomSelect
 *
 * Custom select element
 * By default this plugin wont generate a custom element for IE 8.0 and below
 *
 * This is based on ideas from a technique described by Alen Grakalic in
 * http://cssglobe.com/post/8802/custom-styling-of-the-select-elements
 * ========================================================================
 *
 * $.fn.cbCustomElement
 *
 * Custom checkbox element
 * Custom radio buttons
 *
 * This plugin wont generate a custom element for IE 8.0 and below
 * ========================================================================
 *
 * $.fn.cbFileUpload
 *
 * Custom file upload button
 * This plugin wont generate a custom button for IE 8.0 and below
 * ========================================================================
 *
 * $.fn.cbInputPlaceholder
 *
 * Add a text placeholder to element
 * By default this plugin adds placeholder text for less-capable browser
 * ========================================================================
 *
 * $.fn.cbInputPlaceholder
 *
 * Add a text placeholder to element
 * By default this plugin adds placeholder text for less-capable browser
 * ========================================================================
 *
 * $.fn.cbTextareaResize
 *
 * This plugin adds auto resize or keyboard resize features to text areas
 * ========================================================================*/

+function ($, cb) {
  'use strict';

  // COLLEGE BOARD'S CUSTOM SELECT
  // =========================================
  cb.apricot.customSelect = function (element, options) {
    var
      defaultOptions = {
        replacedClass: 'replaced', // Class name added to replaced selects
        selectClass: 'cb-select', // Class name of the (outer) inserted span element
        activeClass: 'active', // Class name assigned to the fake select when the real select is in hover/focus state
        wrapperElement: '<div>',
        wrapperClass: 'cb-select-container', // Element that wraps the select to enable positioning
        truncate: true,
        hiddenParent: false,
        parentElm: {},
        ieVersion: 9
      },
      $span = {},
      plugin = this;

    plugin.$el = $(element);

    var
    toggleEntities = function (text) {
      var
        k,
        tagsList = {
          '&': '&amp;',
          '<': '&lt;',
          '>': '&gt;'
        };

      // generate a regex object based on a list of tags, if the flag set
      // to true, it will generate a regex for te the tag, otherwise it
      // will generate a list of entities
      var buildRegex = function (tagFlag) {
        var
          items = [];
        if (tagFlag) {
          for (k in tagsList) {
            items.push(k);
          }
        } else {
          for (k in tagsList) {
            items.push(tagsList[k]);
          }
        }

        return new RegExp(items.join('|'), 'g');
      },

      checkRegex = function (pattern, str) {
        return pattern.test(str);
      },

      replaceToEntity = function (tag) {
        return tagsList[tag] || tag;
      },

      replaceToTag = function (entity) {
        for (var k in tagsList) {
          if (tagsList[k] == entity)
            return k;
        }
        return entity;
      };

      // are do we have html entities?
      if (checkRegex(buildRegex(false), text)) {
        // convert entities to tags
        return (text.replace(buildRegex(false), replaceToTag));
      } else {
        // no entities, convert tags to entities ...
        return (text.replace(buildRegex(true), replaceToEntity));
      }
    },

    update = function() {
      var
        val = toggleEntities($('option:selected', plugin.$el).text());

      val = (!!plugin.options.truncate) ? truncateValue(val) : val;

      $span.find('span span').html('<i class="cb-select-arrow"></i>' + val);
    },

    disable = function() {
      plugin.$el.parent().find('.' + plugin.options.selectClass).addClass('disabled');
    },

    enable = function() {
      plugin.$el.parent().find('.' + plugin.options.selectClass).removeClass('disabled');
    },

    truncateValue = function (value) {
      var
        containerWidth = getRealWidth($span),
        buttonWidth = $span.find('i').outerWidth(true),
        valueWidth = 0,
        maxWidth = 0,
        maxChars = 0,
        tmp = textCleanup(value),
        $tmp = $('<span>', {'class': 'cb-tmp-element'});

      $('body').append($tmp);
      $tmp.html(value);

      valueWidth = $tmp.outerWidth(true);
      maxWidth = parseInt(containerWidth - buttonWidth, 10);

      if (maxWidth <= valueWidth) {
        //Calculate maximum number of characters for the string
        while ($tmp.outerWidth(true) > maxWidth) {
          tmp = textCleanup($tmp.html());
          tmp = tmp.substring(0, tmp.length - 1);
          $tmp.html(tmp);
        }
        maxChars = tmp.length;

        value = cb.apricot.utils.textTruncate(value, maxChars, 'last', '...');
      }
      $tmp.remove();

      return value;
    },

    getRealWidth = function ($this) {
      var
        result = 0,
        parentIsHidden = false;

      if (!!plugin.options.hiddenParent) {
        if (plugin.options.parentElm.length > 0) {
          parentIsHidden = (plugin.options.parentElm.css('display') === 'none') ? true : false;
          //only if parent is hidden
          if (!!parentIsHidden) {
            plugin.options.parentElm.show();
            result = $this.outerWidth();
            plugin.options.parentElm.hide();
          } else {
            result = $this.outerWidth();
          }
        }
      } else {

        result = $this.outerWidth();
      }

      return result;
    },

    // check for entities in string
    textCleanup = function (value) {
      if(!!/&(?:[a-z]+|#\d+);/.test(value)) {
        var
          $txtArea = $('<textarea>');

        value = $txtArea.html(value).text();
        $txtArea.remove();
      }

      return value;
    };

    plugin.init = function () {
      var
        browser = cb.apricot.utils.browser();

      plugin.options = $.extend({}, defaultOptions, options);

      $span = $('<span class="' + plugin.options.selectClass + '" aria-hidden="true"><span><span><i class="cb-select-arrow"></i>' + toggleEntities($('option:selected', plugin.$el).text()) + '</span></span></span>');


      //Don't continue if IE 8.0 or lower
      if (!!browser.msie && parseInt(browser.version, 10) < plugin.options.ieVersion) {
        plugin.$el.addClass('no-cb');
        return false;
      }

      var
        multiselect = (!!plugin.$el.attr('multiple')),
        $parentElm = $(plugin.options.wrapperElement, {'class': plugin.options.wrapperClass});

      if (!multiselect) {
        plugin.$el.addClass(plugin.options.replacedClass);
        plugin.$el.wrap($parentElm);
        plugin.$el.after($span);

        // Update the fake select when the real select value changes
        plugin.$el.on('change', function () {
          update();
        });

        plugin.$el.on('keyup', function () {
          update();
        });

        //Update disabled state
        if (plugin.$el.is(':disabled') || plugin.$el.hasClass('disabled')) {
          disable();
        }

        // Change class names to enable styling
        plugin.$el.bind({
          mouseenter: function () {
            $span.addClass(plugin.options.activeClass);
          },
          mouseleave: function () {
            $span.removeClass(plugin.options.activeClass).removeClass('mouseover');
          },
          focus: function () {
            $span.addClass(plugin.options.activeClass).addClass('focus');
          },
          mouseover: function () {
            $span.addClass(plugin.options.activeClass).addClass('mouseover');
          },
          blur: function () {
            $span.removeClass(plugin.options.activeClass).removeClass('focus');
          }
        });


        //adjust text length based on font-size for current viewport
        $(window).resize( function () {
          update();
        });


        //----- Define Custom events
        // can be called when we want to change the value with code
        plugin.$el.on('value_changed', function(){
          update();
        });

        // will add custom disable style to dropDown
        plugin.$el.on('disable', function(){
          disable();
        });

        // will remove custom disable style from dropDown
        plugin.$el.on('enable', function(){
          enable();
        });

        //Make sure we display the currect value, Update cb-select
        plugin.$el.trigger('value_changed');

      }
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      if (!!plugin.$el.data('cbCustomSelect')) {
        plugin.$el.unwrap();
        plugin.$el.removeClass(plugin.options.replacedClass);
        plugin.$el.siblings('.' + plugin.options.selectClass).remove();
        plugin.$el.addClass('no-cb');
        plugin.$el.removeData('cbCustomSelect');
      }
    };

    plugin.init();
  };

  $.fn.cbCustomSelect = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbCustomSelect')) {
          $(this).data('cbCustomSelect', new cb.apricot.customSelect(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbCustomSelect');
        if (instance instanceof cb.apricot.customSelect && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
        }
      });
    }
  };

  // COLLEGE BOARD'S CUSTOM ELEMENTS
  // =========================================
  cb.apricot.customElement = function (element, options) {
    var
      defaultOptions = {
        type: '',
        classPrefix: 'cb-',
        spanClass: 'cb-span',
        elmClass: '',
        ieVersion: 9
      },
      plugin = this;

    plugin.$el = $(element);
    plugin.el = element;

    plugin.init = function () {
      var
        browser = cb.apricot.utils.browser(),
        $span = {};

      plugin.options = $.extend({}, defaultOptions, options);
      $span = $('<span>', {'class': plugin.options.spanClass});

      //Don't continue if IE 8.0 or lower
      if (!!browser.msie && parseInt(browser.version, 10) < plugin.options.ieVersion) {
        plugin.$el.addClass('no-cb');
        return false;
      }

      plugin.options.type = !!plugin.options.type ? plugin.options.type : plugin.$el.attr('type');
      plugin.options.elmClass = !!plugin.options.elmClass ? plugin.options.elmClass : plugin.options.classPrefix + plugin.options.type;

      if (!plugin.$el.hasClass(plugin.options.elmClass)) {
        plugin.$el.addClass(plugin.options.elmClass);
        plugin.$el.after($span);
      }

      plugin.$el.keydown(function (e) {
        var
          self = $(this);

        if (!!cb.apricot.utils.isKey(e, 'ENTER')) { //Enter keycode
          e.preventDefault();

          // Only continue if we're dealing with a checkbox
          if (plugin.$el.attr('type') === 'checkbox' ) {
            var
              check = self.is(':checked') ? false : true;

            self.prop('checked', check);
            self.change();
          }
        }
      });
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      if (!!plugin.$el.data('cbCustomElement')) {
        plugin.$el.siblings('.' + plugin.options.spanClass).remove();
        plugin.$el.removeClass(plugin.options.elmClass).addClass('no-cb');
        plugin.$el.removeData('cbCustomElement');
      }
    };

    plugin.init();
  };

  $.fn.cbCustomElement = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbCustomElement')) {
          $(this).data('cbCustomElement', new cb.apricot.customElement(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbCustomElement');
        if (instance instanceof cb.apricot.customElement && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
        }
      });
    }
  };

  // COLLEGE BOARD'S CUSTOM FILE BUTTON
  // =========================================

  cb.apricot.FileUpload = function (element, options) {
    var
      defaultOptions = {
        label: 'Choose File',
        btnType: 'secondary',
        btnSize: 'sm',
        btnClass: 'cb-file-button',
        wrapperClass: 'cb-file-upload',

        fdbk: true,
        fdbkElement: '<span>',
        fdbkClass: 'cb-file-element',
        fdbkPath: true,
        fdbkMsg: 'No file selected...',
        fdbkTruncate: true,
        fdbkMaxChars: 'auto',
        fdbkRemove: 1,
        fdbkPosition: 'bottom',
        ellipseText: '...',

        popover: true,
        popoverPlacement: 'top',
        ieVersion: 9
      },
      plugin = this,
      $fileWrapper = null,
      $file = null,
      $fdbk = null;

    plugin.$el = $(element);
    plugin.file = '';

    var adjustText = function (value) {
      var
        position = !!plugin.options.fdbkPosition ? plugin.options.fdbkPosition : 'middle',
        ellipseText = !!plugin.options.ellipseText ? plugin.options.ellipseText : '...';

      //don't truncate value
      if (!plugin.options.fdbk) {
        return value;
      }

      //Check text lenght, compare to containing span
      if (!!isNaN(plugin.options.fdbkMaxChars)) {
        var
          containerWidth = $fileWrapper.outerWidth(),
          buttonWidth = $file.outerWidth(true),
          valueWidth = 0,
          maxWidth = 0,
          fdbkMaxChars = 0,
          tmp = value,
          $tmp = $(plugin.options.fdbkElement, {
            'class': plugin.options.fdbkClass,
            'id': 'tmpFileValue'});

        $tmp.addClass('cb-tmp-element'); // css: position: absolute; left: -9999px;
        $('body').append($tmp);
        $tmp.html(value);

        valueWidth = $tmp.outerWidth(true);
        if (plugin.options.fdbkPosition === 'bottom') {
          maxWidth = parseInt(containerWidth, 10);
        } else {
          maxWidth = parseInt(containerWidth - buttonWidth, 10);
        }

        if (maxWidth <= valueWidth) {
          //Calculate maximum number of characters for the string
          while ($tmp.outerWidth(true) > maxWidth) {
            tmp = $tmp.html();
            tmp = tmp.substring(0, tmp.length - 1);
            $tmp.html(tmp);
          }
          fdbkMaxChars = tmp.length;
          $tmp.remove();
          value = cb.apricot.utils.textTruncate(value, fdbkMaxChars, position, ellipseText);
        }

      } else if (value.length > plugin.options.fdbkMaxChars) {
        value = cb.apricot.utils.textTruncate(value, plugin.options.fdbkMaxChars, position, ellipseText);
      }

      return value;
    },

    addPopover = function () {
      var
        placement = !!plugin.options.popoverPlacement ? plugin.options.popoverPlacement : 'top';

      if (!$.fn.popover) {
        return false;
      }
      $fdbk.attr('data-toggle', 'popover');
      $fdbk.dblclick(function() {
        $fdbk.popover('destroy');

        if ($file.attr('data-cb-file') !== undefined ) {
          //popOver declaration
          $fdbk.popover({
            html: true,
            title: '',
            placement: placement,
            trigger: 'manual',
            content: '<p class="cb-file-name">' + $file.attr('data-cb-file') + '</p>',
            container: 'body'
          });

          //Show popOver
          $fdbk.popover('show');
        }
      });
    };

    plugin.init = function () {
      var
        browser = cb.apricot.utils.browser();

      plugin.options = $.extend({}, defaultOptions, options);

      //Don't continue if IE 8.0 or lower
      if (!!browser.msie && parseInt(browser.version, 10) < plugin.options.ieVersion) {
        plugin.$el.addClass('no-cb');
        return false;
      }

      $fileWrapper = $('<div>', {'class': plugin.options.wrapperClass});

      if (!!plugin.options.fdbk) {
        $fdbk = $(plugin.options.fdbkElement, {'class': plugin.options.fdbkClass});
      }

      plugin.$el.wrap($fileWrapper);
      $fileWrapper = plugin.$el.parent('.' + plugin.options.wrapperClass);

      $file = $('<button>');
      $file.attr('type', 'button')
          .addClass(plugin.options.btnClass)
          .addClass('btn')
          .addClass('btn-' + plugin.options.btnType)
          .addClass('btn-' + plugin.options.btnSize)
          .html(plugin.options.label);

      if (!!plugin.options.fdbk) {
        plugin.$el.after($fdbk);
      }

      plugin.$el.after($file);
      if (!!plugin.options.fdbk) {
        $fdbk.html(plugin.options.fdbkMsg);
      }

      //ADD EVENTS
      //===============

      $file.on('click', function (e) {
        e.preventDefault();
        plugin.$el.click();
      });


      plugin.$el.on('change.cbFileUpload', function () {
        //get file
        var
          file = $(this).val(),
          message = '';

        // Display selected file
        if (!!plugin.options.fdbk) {
          message = plugin.options.fdbkMsg;

          if (!plugin.options.fdbkPath) {
            file = file.split(/\\/).pop();
          }
          if (!!file) {
            message = file;
            plugin.file = file;
          }

          $fdbk.html(adjustText(message));

          if (!!plugin.options.fdbkRemove) {
            var $rm = $('<a>', {'class': 'cb-file-element-rm'})
              .addClass('cb-glyph')
              .addClass('cb-x-mark');

            $fdbk.append($rm);
            $rm.on('click', function (e) {
              e.preventDefault();
              plugin.$el.val('');
              plugin.$el.attr('title', '');
              plugin.$el.removeAttr('data-cb-file');

              if (!!plugin.options.fdbk) {
                $fdbk.html(adjustText(plugin.options.fdbkMsg));
              } else {
                $fdbk.html('');
              }

              plugin.$el.trigger('cb_file_selected', null);
            });
          }
        }

        if (!!file) {
          $file.attr('data-cb-file', file);
          $file.attr('title', file);

          // trigger custom event

          plugin.$el.trigger('cb_file_selected', file);

           //display popOver with file name
          if (!!plugin.options.popover && !!plugin.options.fdbk) {
            addPopover();
          }
        } else {
          $file.removeAttr('data-cb-file');
          $file.attr('title', '');
          plugin.$el.trigger('cb_file_selected', null);
        }
      });

      if (!!plugin.options.fdbk) {
        //adjust text based on font-size for current viewport
        $(window).resize( function () {
          if (!!$fdbk.html()) {
            $fdbk.html(adjustText(plugin.file));
          }
        });
      }
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      var
        $parent = plugin.$el.parent();

      $('.' + plugin.options.btnClass, $parent).remove();
      $('.' + plugin.options.fdbkClass, $parent).remove();

      plugin.$el.unwrap();
      plugin.$el.off('change.cbFileUpload');
      plugin.$el.removeData('cbFileUpload');
    };

    plugin.init();
  };

  $.fn.cbFileUpload = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbFileUpload')) {
          $(this).data('cbFileUpload', new cb.apricot.FileUpload(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbFileUpload');
        if (instance instanceof cb.apricot.FileUpload && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
        }
      });
    }
  };

  // COLLEGE BOARD'S TEXT PLACEHOLDER
  // =========================================
  cb.apricot.inputPlaceholder = function (element, options) {
    var
      defaultOptions = {
        placeholderClass: 'cb-placeholder',
        placeholderText: '',
        handlePassword: true,
        passwordClass: 'cb-password'
      },
      plugin = this;

    plugin.$el = $(element);

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, options);

      //Only continue if browser doesn't support placeholder
      if (!placeholderIsSupported()) {
        addPlaceholder();
      }
    };

    var
    addPlaceholder = function () {
      var
        placeholderText = !cb.apricot.utils.isBlank(plugin.$el.attr('placeholder')) ? plugin.$el.attr('placeholder') : (!cb.apricot.utils.isBlank(plugin.options.placeholderText) ? plugin.options.placeholderText : ''),
        isPassword = (plugin.$el.attr('type') === 'password' && plugin.options.handlePassword),
        browser = cb.apricot.utils.browser(),
        $form = plugin.$el.closest('form');

      // only proceed if we have text for the placeholder
      if (!!cb.apricot.utils.isBlank(placeholderText)) {
        return false;
      }

      // don't continue if IE 8.0 or lower
      // input type manipulation is not supported
      if (!!browser.msie && parseInt(browser.version, 10) < 9 && !!isPassword) {
        return false;
      }

      plugin.$el.val(plugin.$el.attr('placeholder')).addClass(plugin.options.placeholderClass);

      if (!!isPassword) {
        plugin.$el.attr('type', 'text');
        plugin.$el.addClass(plugin.options.passwordClass);
      }

      //bind events
      plugin.$el.on('focus.cbInputPlaceholder', function () {
        var
          self = $(this),
          value = self.val();

        if (value === placeholderText) {
          self.val('').removeClass(plugin.options.placeholderClass);

          if (!!isPassword) {
            self.removeClass(plugin.options.passwordClass);
            self.attr('type', 'password');
          }
        }
      });

      plugin.$el.on('blur.cbInputPlaceholder', function () {
        var
          self = $(this),
          value = self.val();

        if (cb.apricot.utils.isBlank(value) || value === placeholderText) {
          if (!!isPassword) {
            self.addClass(plugin.options.placeholderClass);
            self.attr('type', 'text');
          }

          self.addClass(plugin.options.placeholderClass).val(placeholderText);
        }
      });

      $form.on('submit.cbInputPlaceholder', function () {
        // make sure we're not passing placeholder text as input value
        $(this).find('.' + plugin.options.placeholderClass).each(function () {
          var
            self = $(this);

          if (self.val() === placeholderText) {
            self.val('');
          }
        });
      });
    },

    placeholderIsSupported = function () {
      return 'placeholder' in document.createElement('input');
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      var
        $form = plugin.$el.closest('form'),
        placeholderText = !cb.apricot.utils.isBlank(plugin.$el.attr('placeholder')) ? plugin.$el.attr('placeholder') : (!cb.apricot.utils.isBlank(plugin.options.placeholderText) ? plugin.options.placeholderText : '');

      plugin.$el.off('focus.cbInputPlaceholder');
      plugin.$el.off('blur.cbInputPlaceholder');
      $form.off('submit.cbInputPlaceholder');

      if (plugin.$el.val() === placeholderText) {
        plugin.$el.val('').removeClass(plugin.options.placeholderClass);
      }

      plugin.$el.removeData('cbInputPlaceholder');
    };

    plugin.init();
  };

  $.fn.cbInputPlaceholder = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbInputPlaceholder')) {
          $(this).data('cbInputPlaceholder', new cb.apricot.inputPlaceholder(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbInputPlaceholder');
        if (instance instanceof cb.apricot.inputPlaceholder && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
        }
      });
    }
  };

  // COLLEGE BOARD'S SET VALIDATION STATE
  // =========================================
  cb.apricot.validationState = function (element, options) {
    var
      defaultOptions = {
        success: false,
        error: false,
        warning: false,
        wrapperClass: 'cb-input-state'
      },
      plugin = this;

    plugin.$el = $(element);

    var
    buildValidationBlock = function () {
      var
        $parent = plugin.$el.parent();

      if (!$parent.hasClass(plugin.options.wrapperClass)) {
        plugin.$el.wrap('<div></div>');
        $parent = plugin.$el.parent();
        $parent.addClass(plugin.options.wrapperClass);
      }

      addValidationIcon();
    },

    addValidationIcon = function () {
      var
        iconType = ((!!plugin.options.success) ? 'cb-check' : ((!!plugin.options.error) ? 'cb-x-mark' : ((!!plugin.options.warning) ? 'cb-exclamation' : ''))),
        $icon =  $('<i/>')
          .addClass('cb-glyph')
          .addClass(iconType)
          .attr('aria-hidden', true),
        $parent = plugin.$el.parent();

      $parent.append($icon);
    };

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, options);

      //Only activate functionality if element is an input
      if (!plugin.$el.is('input')) {
        return false;
      }

      buildValidationBlock();
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      var
        $parent = plugin.$el.parent();

      if (!!$parent.hasClass(plugin.options.wrapperClass)) {
        $('i', $parent).remove();
        plugin.$el.unwrap();
      }

      plugin.$el.removeData('cbValidationState');
    };

    plugin.init();
  };

  $.fn.cbValidationState = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!!$(this).data('cbValidationState')) {
          $(this).cbValidationState('destroy');
        }
        $(this).data('cbValidationState', new cb.apricot.validationState(this, options));
       });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbValidationState');
        if (instance instanceof cb.apricot.validationState && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
        }
      });
    }
  };

  // COLLEGE BOARD'S RESIZABLE TEXTAREA
  // =========================================
  cb.apricot.textareaResize = function (element, options) {
    var
      defaultOptions = {
        autoResize: false,
        vertically: 'ENTER', // you can use any key defined in cb.apricot.utils.keys
        resetSizeKey: 'ESC'
      },

      plugin = this,
      elmProperty = {};

    plugin.$el = $(element);

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, options);

      elmProperty.rows = (!!plugin.$el.attr('rows')) ? plugin.$el.attr('rows') : '';
      elmProperty.height = plugin.$el.outerHeight();
      elmProperty.width = plugin.$el.outerWidth();
      elmProperty.scrollHeight = plugin.$el.prop('scrollHeight');

      if (!!plugin.options.resetSizeKey) {
        plugin.$el.on('keydown.cbTextareaResize', function(e) {
          if (cb.apricot.utils.whichKey(e) === plugin.options.resetSizeKey) {
            resetTextarea();
          }
        });
      }

      // Activate auto resize
      if (!!plugin.options.autoResize) {
        addAutoResize();
      } else {
        addKeyboardResize();
      }

      // track textarea resize event
      plugin.$el.on('mouseup.cbTextareaResize', function () {
        plugin.$el.data('cbX', (!isNaN(plugin.$el.data('cbX'))) ? plugin.$el.data('cbX') : plugin.$el.outerWidth());
        plugin.$el.data('cbY', (!isNaN(plugin.$el.data('cbY'))) ? plugin.$el.data('cbY') : plugin.$el.outerHeight());

        if (plugin.$el.outerWidth() !== plugin.$el.data('cbX') || plugin.$el.outerHeight() !== plugin.$el.data('cbY')) {
          elmProperty.scrollHeight = plugin.$el.prop('scrollHeight');
        }

        // set new height/width
        plugin.$el.data('cbX', plugin.$el.outerWidth());
        plugin.$el.data('cbY', plugin.$el.outerHeight());
      });
    };

    var
    addAutoResize = function () {
      plugin.$el.css('height', elmProperty.scrollHeight + 'px');

      plugin.$el.on('input.cbTextareaResize', function () {
        var
          self = $(this),
          scrollHeight = self.prop('scrollHeight');
        self.css('overflow-y', 'hidden');

        if (parseInt(elmProperty.scrollHeight, 10) < parseInt(scrollHeight, 10)) {
          self.css('height', scrollHeight + 'px');
        }

      });
    },

    addKeyboardResize = function () {
       plugin.$el.on('keydown.cbTextareaResize', function(e) {
        var
          rows = parseInt(plugin.$el.attr('rows'), 10);

        plugin.$el.css('height', 'auto');

        if (e.shiftKey && e.ctrlKey && cb.apricot.utils.whichKey(e) === plugin.options.vertically) {
          e.preventDefault();
          rows--;
          if (rows > elmProperty.rows) {
            plugin.$el.attr('rows',  rows);
          }
        } else if (e.ctrlKey && cb.apricot.utils.whichKey(e) === plugin.options.vertically) {
          e.preventDefault();
          if (plugin.$el.outerHeight(true) < $(window).height()) {
            rows++;
            plugin.$el.attr('rows',  rows++);
          }
        }
      });
    },

    // reset textarea
    resetTextarea = function () {
      plugin.$el.css({
        'overflow-y': 'auto',
        'height': elmProperty.height +'px',
        'width': elmProperty.width + 'px'
      }).attr('rows', elmProperty.rows);
    };

    // Remove plugin instance
    plugin.destroy = function () {
      resetTextarea();

      plugin.$el.off('input.cbTextareaResize');
      plugin.$el.off('keydown.cbTextareaResize');
      plugin.$el.removeData('cbTextareaResize');
    };

    plugin.init();
  };

  $.fn.cbTextareaResize = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbTextareaResize')) {
          $(this).data('cbTextareaResize', new cb.apricot.textareaResize(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbTextareaResize');
        if (instance instanceof cb.apricot.textareaResize && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
        }
      });
    }
  };

  // Activate custom elements
  // ==============
  $(window).on('load', function () {
    // ----- Custom Select
    if (!!cb.apricot.setup.isCb('select')) {
      if ($('select').length > 0) {
        //apply CB's style to all select elements
        $('select').each(function () {
          if ($(this).data('cb-element') !== 'no-cb') {
            $(this).cbCustomSelect();
          }
        });
        // trigger custom event
        $(document).trigger('cb_select_finished');
      }
    } else if (!!cb.apricot.setup.hasSelector('select')) {
      //apply CB's style to desired elements
      $.each(cb.apricot.setup.selector('select'), function(index, value) {
        $(value).cbCustomSelect();
      });
      // trigger custom event
      $(document).trigger('cb_select_finished');
    }

    // ----- Custom Checkbox
    if (!!cb.apricot.setup.isCb('checkbox')) {
      if ($('[type=checkbox]').length > 0) {
        //apply CB's style to all checkbox elements
        $('[type=checkbox]').each(function () {
          if ($(this).data('cb-element') !== 'no-cb') {
            $(this).cbCustomElement({
              type: 'checkbox'
            });
          }
        });
        // trigger custom event
        $(document).trigger('cb_checkbox_finished');
      }
    } else if (!!cb.apricot.setup.hasSelector('checkbox')) {
      //apply CB's style to desired elements
      $.each(cb.apricot.setup.selector('checkbox'), function(index, value) {
          $(value).cbCustomElement({
            type: 'checkbox'
          });
      });
      // trigger custom event
      $(document).trigger('cb_checkbox_finished');
    }

    // ----- Custom Radio Buttons
    if (!!cb.apricot.setup.isCb('radio')) {
      if ($('[type=radio]').length > 0) {
        //apply CB's style to all elements of type radio
        $('[type=radio]').each(function () {
          if ($(this).data('cb-element') !== 'no-cb') {
            $(this).cbCustomElement({
              type: 'radio'
            });
          }
        });
        // trigger custom event
        $(document).trigger('cb_radio_finished');
      }
    } else if (!!cb.apricot.setup.hasSelector('radio')) {
      //apply CB's style to desired elements
      $.each(cb.apricot.setup.selector('radio'), function(index, value) {
          $(value).cbCustomElement({
            type: 'radio'
          });
      });
      // trigger custom event
      $(document).trigger('cb_radio_finished');
    }

    // ----- Custom fileUpload button
    if (!!cb.apricot.setup.isCb('fileUpload')) {
      if ($('[type=file]').length > 0) {
        //apply CB's style to all elements of type file
        $('[type=file]').each(function () {
          if ($(this).data('cb-element') !== 'no-cb') {
            $(this).cbFileUpload();
          }
        });
        // trigger custom event
        $(document).trigger('cb_fileUpload_finished');
      }
    } else if (!!cb.apricot.setup.hasSelector('fileUpload')) {
      //apply CB's style to desired elements
      $.each(cb.apricot.setup.selector('fileUpload'), function(index, value) {
          $(value).cbFileUpload();
      });
      // trigger custom event
      $(document).trigger('cb_fileUpload_finished');
    }

    // ----- Add placeholder to IE8.0, 9.0
    if (!!cb.apricot.setup.isCb('placeholder')) {
      if ($('[type=text]').length > 0) {
        //apply CB's style to all input elements of type file
        $('[type=text]').each(function () {
          if ($(this).data('cb-element') !== 'no-cb') {
            $(this).cbInputPlaceholder();
          }
        });
        // trigger custom event
        $(document).trigger('cb_placeholder_finished');
      }
    } else if (!!cb.apricot.setup.hasSelector('placeholder')) {
      //apply CB's style to desired elements
      $.each(cb.apricot.setup.selector('placeholder'), function(index, value) {
        $(value).cbInputPlaceholder();
      });
      // trigger custom event
      $(document).trigger('cb_placeholder_finished');
    }

    // ----- Keyboard options to textarea
    if (!!cb.apricot.setup.isCb('textArea')) {
      if ($('textArea').length > 0) {
        //apply CB's style to all select elements
        $('textArea').each(function () {
          if ($(this).data('cb-element') !== 'no-cb') {
            $(this).cbTextareaResize();
          }
        });
      }
    } else if (!!cb.apricot.setup.hasSelector('select')) {
      //apply CB's style to desired elements
      $.each(cb.apricot.setup.selector('textArea'), function(index, value) {
        $(value).cbTextareaResize();
      });
    }
  });
}(jQuery, cb);
