/* ========================================================================
 * Event Card
 * ========================================================================
 *
 * behavior adjustment for Event Cards
 * ======================================================================== */

+function ($, cb) {
    'use strict';
  
    cb.apricot.lineStyleAdjs = function (element, options) {
      var
      defaultOptions = {
          child: ''
      },
      plugin = this;
      plugin.$el = $(element);
  
      var
      isTextWrapped = function () {
        // First reset
        plugin.$el.removeClass('cb-multi');

        var
          cw = containerWidth(),
          ew = elementWidth();

        if (ew > cw) {
            plugin.$el.addClass('cb-multi');
        }
      },
  
      elementWidth = function () {
        var width = 0;
        $(plugin.options.child, plugin.$el).each ( function () {
          width += $(this).outerWidth(true);
        })
        
        return width;
      },
  
      containerWidth = function () {
        return plugin.$el.outerWidth(true);
      };
  
      plugin.init = function () {
        plugin.options = $.extend({}, defaultOptions, options);


        isTextWrapped();
  
        $(window).on('resize.cbLineStyleAdj', function () {
          isTextWrapped();
        });
      };
  
      // Remove plugin instance and clean up
      plugin.destroy = function () {
        if (!!plugin.$el.data('cbLineStyleAdj')) {
          plugin.$el.removeData('cbLineStyleAdj');
          window.$el.off('resize.cbLineStyleAdj');
        }
      };
  
      plugin.init();
    };
  
    $.fn.cbLineStyleAdj = function (options) {
      var args = arguments;
      if (options === undefined || typeof options === 'object') {
        return this.each(function () {
          if (!$(this).data('cbLineStyleAdj')) {
            $(this).data('cbLineStyleAdj', new cb.apricot.lineStyleAdjs(this, options));
          }
        });
      } else if (typeof options === 'string') {
        return this.each(function () {
          var instance = $.data(this, 'cbLineStyleAdj');
          if (instance instanceof cb.apricot.lineStyleAdjs && typeof instance[options] === 'function') {
            instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
          }
        });
      }
    };
  
    // ----- Activate on page load
    $(window).off('load.cbEventCards').on('load.cbEventCards', function () {

      $('.cb-event-cards').each( function () {
        var $card = $(this);
        var years = [];
        $('.cb-event-year', $card).each( function (index) {
          var self = $(this),
          obj = {};

          obj.elm = self;
          obj.year = self.html().trim()
          years.push(obj);
        });
        adjustYearDisplay(years);


        $('.cb-event-action', $card).each( function (index) {
          $(this).cbLineStyleAdj ({
              child: 'li'
          });
        });
        $('.cb-event-meta', $card).each( function (index) {
          $(this).cbLineStyleAdj ({
              child: 'li'
          });
        });



        // Horizontal event card, height adjustment
        adjustHcardHeight();

        $(window).on('resize.cbEventCards', function () {
          adjustHcardHeight();
        });

        if ($('.cb-event-non-timeline').length > 0) {

          // Safari, flexbox bug
          cb.apricot.utils.addClassSafari($('body'));
        }
      });

      // Accessibility
      $('.cb-event-timeline > ul > li, .cb-event-timeline > ol > li').each(function (index) {
        var $card = $(this),
          $title = $('.cb-event-title', $card),
          $dateInfo = $('.cb-event-date-info', $card),
          $cal = $('.cb-event-cal', $card),
          $more = $('.cb-event-more', $card),
          id_t = '',
          id_d = '',
          id = '';

          if ($dateInfo.length > 0) {
            id_d = (!!$dateInfo.attr('id')) ? $dateInfo.attr('id') : cb.apricot.utils.uniqueID(5, 'apricot_');
            $dateInfo.attr('id', id_d);
          }
          if ($title.length > 0) {
            id_t = (!!$title.attr('id')) ? $title.attr('id') : cb.apricot.utils.uniqueID(5, 'apricot_');
            $title.attr('id', id_t);
          } 
          id_d += ' ';
          id = id_d.concat(id_t);
          ariaDescribedby ($more, id);
          ariaDescribedby ($cal, id);
      })
    });

    
    function adjustYearDisplay(items) {
      var years= [];

      for (var item in items){
        if (($.inArray(items[item].year, years)) == -1) {
          years.push(items[item].year);
        }
      }

      for (var year in years){
        var found = false,
        year = years[year];

        for (var item in items){
          if (items[item].year === year && !!found) {
            items[item].elm.addClass('hidden');
          } else if (items[item].year === year) {
            found = true;
          }
        }
      }
    }
       
    function ariaDescribedby ($elm, id) {
      if ($elm.length > 0) {
        $elm.attr('aria-describedby', id);
      }
    }

    function adjustHcardHeight () {
      var
        currentViewport = cb.apricot.utils.viewport();


      $('.cb-event-cards-horizontal').each( function () {
        if (currentViewport.prefix === 'xs') {
          $(this).css('height', 'auto');
        } else {
          var
            self = $(this),
            height = cb.apricot.utils.tallestElm($('.cb-event-date-info', self)).max;
  
          $('.cb-event-date-info', self).each( function () {
            $(this).css('height', height + 'px')
          })

        }
      });
    }
}(jQuery, cb);





