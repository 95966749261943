/*!
 *  Apricot v3.4.0
 *  By:             The College Board
 *  App:            apricot
 *  Build Time:     2019-04-02 [10:28:53 AM] EDT
 *  Build Number:   unknown
 *  SVN Revision:   unknown
 *  Jenkins Job:    unknown
 *  This version of Apricot includes Bootstrap v3.7.0
 */

/* ========================================================================
 * namespace for Apricot
 * ======================================================================== */

//cb namespace name
this.cb = this.cb || {};
cb.apricot = cb.apricot || {};

// apricot version
cb.apricot.version = '3.4.0';