/* ========================================================================
 * Slidedown, Split Local Navigation
 * ========================================================================
 *
 * Style and behavior adjustment for slidedown
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  var toggle   = '[data-toggle="slidedown"]'
  var browser = cb.apricot.utils.browser();

  var Slidedown = function (element) {

    $(element).on('click.bs.slidedown', this.toggle)
  }


  function getParent($this) {
    var selector = $this.attr('data-target')

    if (!selector) {
      selector = $this.attr('href')
      selector = selector && /#[A-Za-z]/.test(selector) && selector.replace(/.*(?=#[^\s]*$)/, '') // strip for ie7
    }

    var $parent = selector && $(selector)

    return $parent && $parent.length ? $parent : $this.parent()
  }

  function getSliderParent($this) {

  }

  function clearMenus(e) {
    if (e && e.which === 3) return

    // IE fix
    if (e && e.target.tagName === 'A') {
      var $target = $(e.target);
      if ($target.hasClass('slidedown-toggle')) return;
    }

    $(toggle).each(function () {
      var $this         = $(this)
      var $parent       = getParent($this)
      var relatedTarget = { relatedTarget: this }
      var $down = $('.cb-down', $this)
      var $up = $('.cb-up', $this)

      if (!$parent.hasClass('open')) return

      if (e && e.type == 'click' && /input|textarea/i.test(e.target.tagName) && $.contains($parent[0], e.target)) return

      $parent.trigger(e = $.Event('hide.bs.slidedown', relatedTarget))

      if (e.isDefaultPrevented()) return
      

      $down.removeClass('hidden')
      $up.addClass('hidden')
      $this.attr('aria-expanded', 'false')
      $parent
        .removeClass('open')
        .trigger($.Event('hidden.bs.slidedown', relatedTarget))
        .focus()
  
    })
  }

  Slidedown.prototype.toggle = function (e) {
    var $this = $(this)
    
    // IE fix: TBD
    if (!!browser.msie) {
      if (e && e.which == undefined) return
    }
    

    if ($this.is('.disabled, :disabled')) return

    var $parent  = getParent($this)
    var isActive = $parent.hasClass('open')


    var $down = $('.cb-down', $this)
    var $up = $('.cb-up', $this)

    clearMenus()

    if (!isActive) {

      var relatedTarget = { relatedTarget: this }
      $parent.trigger(e = $.Event('show.bs.slidedown', relatedTarget))

      if (e.isDefaultPrevented()) return

      $this
        .trigger('focus')
        .attr('aria-expanded', 'true')

      $parent
        .toggleClass('open')
        .trigger($.Event('shown.bs.slidedown', relatedTarget))

      $down.addClass('hidden')
      $up.removeClass('hidden')
    }

    return false
  };


  Slidedown.prototype.keydown = function (e) {
    if (!/(38|40|27|32)/.test(e.which) || /input|textarea/i.test(e.target.tagName)) return

    var $this = $(this)

    e.preventDefault()
    e.stopPropagation()

    if ($this.is('.disabled, :disabled')) return

    var $parent = {}
    var isActive = {}

    if ($this.parents('.cb-slidedown-items').length > 0 ) {
      isActive = $('.cb-slidedown-inner').children().length > 0
      
      if (!isActive && e.which != 27 || isActive && e.which == 27) {
        if (e.which == 27) $('.slidedown.open').find(toggle).trigger('focus')
        return $('.slidedown.open').find(toggle).trigger('click')
      }
    } else {
      $parent  = getParent($this)
      isActive = $parent.hasClass('open')
  
      if (!isActive && e.which != 27 || isActive && e.which == 27) {
        if (e.which == 27) $parent.find(toggle).trigger('focus')
        return $this.trigger('click')
      }
    }
  }

  // DROPDOWN PLUGIN DEFINITION
  // ==========================

  function Plugin(option) {
    return this.each(function () {
      var $this = $(this)
      var data  = $this.data('bs.slidedown')

      if (!data) $this.data('bs.slidedown', (data = new Slidedown(this)))
      if (typeof option == 'string') data[option].call($this)
    })
  }

  var old = $.fn.slidedown

  $.fn.slidedown             = Plugin
  $.fn.slidedown.Constructor = Slidedown



  // APPLY TO STANDARD DROPDOWN ELEMENTS
  // ===================================

  $(document)
    .on('click.bs.slidedown.data-api', clearMenus)
    .on('click.bs.slidedown.data-api', '.slidedown form', function (e) { e.stopPropagation() })
    .on('click.bs.slidedown.data-api', toggle, Slidedown.prototype.toggle)
    .on('keydown.bs.slidedown.data-api', toggle, Slidedown.prototype.keydown)
    .on('keydown.bs.slidedown.data-api', '.cb-slidedown-inner', Slidedown.prototype.keydown)

    // ------- Apricot's extension
    cb.apricot.slidedownExtension = function (element) {
      var
        plugin = this,
        keys = cb.apricot.utils.keys,
        $slidedown = {},
        $slidedownToggle = {},
        $doc = $(document),
        tabbingBack = false;

      plugin.$el = $(element);

      plugin.init = function () {
        if (!!plugin.$el.hasClass('slidedown')) {
          $slidedown = plugin.$el;
        } else {
          $slidedown = $('.slidedown', plugin.$el);
        }

        $slidedownToggle = $('.slidedown-toggle', $slidedown);
        //make sure menus close if user clicks outside of menu
        // can't be called on document because elements with default prevented will not trigger document click
        $doc.on('click.cbSlidedownExtension', '*', $.proxy(function(e) {
          closeSlidedownMenu($(e.target));
        }, this));

        $doc.on('keydown.cbSlidedownExtension', $.proxy(function(e) {
          var
            $target = $(e.target);

          if (e.which === keys.ESC) { //make sure menu is closed on escape
            closeSlidedownMenu($target, true);
          } 
          
        }, this));

        //toggle arrows
        $slidedown.on('hide.bs.slidedown show.bs.slidedown', function () {
          $(this).find('.cb-glyph.hidden').removeClass('hidden').siblings('i').addClass('hidden');
        });
      };

      //bootstrap slidedown toggle, doesn't close with keyboard by default
      var closeSlidedownMenu = function($target, immediateClose) {
        var
          $openSlidedown = !!plugin.$el.hasClass('slidedown') ?
          (!!plugin.$el.hasClass('open') ? plugin.$el : {} ) :
          $('.slidedown.open', plugin.$el);

        if($openSlidedown.length > 0) {
          if(!immediateClose) { //user triggered keydown event to close menu only if not focused on an item within it
            $openSlidedown = $openSlidedown.filter(function() {
              var $this = $(this);
              return !($target.is($this) || $target.closest($this).length > 0 || $this.find($target).length > 0);
            });
          }

          if($openSlidedown.length > 0) { //recheck length in case it changed from above if condition
            $openSlidedown.find('.slidedown-toggle').slidedown('toggle');
          }
        }
      },

      checkActiveSlidedown = function(e) {
        var
          $openSlidedown = !!plugin.$el.hasClass('slidedown') ?
          (!!plugin.$el.hasClass('open') ? plugin.$el : {} ) :
          $('.slidedown.open', plugin.$el),
          $slidedownFocus = {};

        if($openSlidedown.length > 0) {
          $slidedownFocus = $openSlidedown.find(':focus');

          if($openSlidedown.length !== 0 && $slidedownFocus.length !== 0) {
            if(($openSlidedown.find('ul li a:last').is(':focus') && e.which === keys.DOWN) ||
                $openSlidedown.find('ul li a:first').is(':focus') && e.which === keys.UP) {
              e.preventDefault(); //prevent default when user reaches top/bottom of the list using arrows
            }
          }
        }
      };

      // Remove plugin instance
      plugin.destroy = function () {
        var
          $doc = $(document);

        $doc.off('click.cbSlidedownExtension');
        $doc.off('keydown.cbSlidedownExtension');
        $doc.off('focus.cbSlidedownExtension');

        plugin.$el.removeData('cbSlidedownExtension');
      };

      plugin.init();
    };

    $.fn.cbSlidedownExtension = function (options) {
      var args = arguments;
      if (options === undefined || typeof options === 'object') {
        return this.each(function () {
          if (!$(this).data('cbSlidedownExtension')) {
            $(this).data('cbSlidedownExtension', new cb.apricot.slidedownExtension(this, options));
          }
        });
      } else if (typeof options === 'string') {
        return this.each(function () {
          var instance = $.data(this, 'cbSlidedownExtension');
          if (instance instanceof cb.apricot.slidedownExtension && typeof instance[options] === 'function') {
            instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
          }
        });
      }
    };
}(jQuery, cb);