/* ========================================================================
 * Masonry Grid System
 * ========================================================================
 *
 * Style and behavior adjustment for masonry Grid System
 * ======================================================================== */

+ function($, cb) {
  'use strict';

  cb.apricot.masonryGrid = function (element, options) {
    var
      defaultOptions = {
        masonryGridClass: 'cb-masonry-grid',
        masonryItemClass: 'cb-masonry-item',
        xsColumns: 1,
        smColumns: 1,
        mdColumns: 1,
        lgColumns: 1,
        log: false
      },
      plugin = this;

    plugin.$el = $(element);

    var
    masonryGrid = function (count) {
      var 
        data = {},
        col_heights = [],
        item_heights= [];

      for (var i = 0; i <= count; i++) { 
          col_heights.push(0);
      }
      
      data.height = 0
      // reset grid
      resetGrid();
      $('.' + plugin.options.masonryItemClass, plugin.$el).each( function (index) {
      var
        num = index + 1,
        order = (num) % count || count,
        height = parseFloat($(this).outerHeight()),
        obj = {};

        obj.index = num
        obj.order = order
        obj.height = height

        $(this).css('order', order)
          .attr('data-cb-oder', order)

        item_heights.push(obj)
        col_heights[order - 1] += height;
      });

      var highest=Math.max.apply(Math, col_heights);
      if (!!plugin.options.log) {
        console.log('----------- Masonry')
        console.log('Items')
        console.log(item_heights)
        console.log('Columns')
        console.log(col_heights)
        console.log('Highest: ', highest)
      }

      if (count == 1) {
        $('.' + plugin.options.masonryGridClass, plugin.$el).css('height', 'auto');
      } else {
        $('.' + plugin.options.masonryGridClass, plugin.$el).css('height', highest + 'px');
        data.height = highest;
      }

      setTimeout(function(){
        plugin.$el.trigger('masonry.ready', data);
      }, 100);
    },

    resetGrid = function () {
      $('.' + plugin.options.masonryItemClass, plugin.$el).each( function (index) {
        $(this).removeAttr('order');
      });

      $('.' + plugin.options.masonryGridClass, plugin.$el).css('height', 'auto');
    },

    getColumnCount = function (prefix) {
      var count = 1;

      switch(prefix) {
        case 'xs':
        count = plugin.options.xsColumns;
          break;
        case 'sm':
        count = plugin.options.smColumns;
          break;
        case 'md':
        count = plugin.options.mdColumns;
          break;
        case 'lg':
        count = plugin.options.lgColumns;
          break;
      }

      masonryGrid (count);
    };

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, options);

      
      // track breakpoint changes
      cb.apricot.utils.breakpoints();
      getColumnCount(cb.apricot.utils.viewport().prefix);
 
      $(document).on('breakpoint_change', function (e, data) {
        getColumnCount(data.prefix);
      });
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      plugin.$el.removeData('cbMasonryGrid');
      resetGrid();
    };

    plugin.adjust = function () {
      getColumnCount(cb.apricot.utils.viewport().prefix);
    };

    plugin.init();
  };

  $.fn.cbMasonryGrid = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbMasonryGrid')) {
          $(this).data('cbMasonryGrid', new cb.apricot.masonryGrid(this, options));
        }
       });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbMasonryGrid');
        if (instance instanceof cb.apricot.masonryGrid && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call(args, 1) );
        }
      });
    }
  };
}(jQuery, cb);

