/* ========================================================================
 * Apricot's Utils Module
 * ========================================================================
 *
 * cb.apricot.utils.keys
 *
 * List of common keycodes
 * ========================================================================
 *
 * cb.apricot.utils.isKey
 *
 * Checks if an event is triggered by a specific key
 * ========================================================================
 *
 * cb.apricot.utils.whichKey
 *
 * Determine which key triggered an event
 * ========================================================================
 *
 * cb.apricot.utils.browser
 *
 * A Browser detector
 * returns an object {name, opera|msie|chrome|firefox|safari, version}
 *
 * Based on code from Dustin Diaz
 * https://github.com/ded/bowser
 * ========================================================================
 *
 * cb.apricot.utils.addClassIE
 *
 * If browser is IE, adds a class 'ie+VERSION_NUMBER'
 * ex: 'ie9', 'ie10'
 * ========================================================================
 *
 * cb.apricot.utils.isBlank
 *
 * Check if the string is empty, returns true|false
 * ========================================================================
 *
 * cb.apricot.utils.viewport
 *
 * Returns the viewport size of the browser
 * ========================================================================
 *
 * cb.apricot.utils.breakpoints
 *
 * Triggers a custom event when browser window reaches a new breakpoint
 * ========================================================================
 *
 * cb.apricot.utils.textTruncate
 *
 * Customizable function for truncation of text
 * ========================================================================
 *
 * cb.apricot.utils.uniqueID
 *
 * Generate an unique identifier
 * ========================================================================
 *
 * cb.apricot.utils.isRetina
 *
 * Generate an unique identifier
 * ========================================================================
 *
 * cb.apricot.utils.height
 *
 * Returns elements height
 * ========================================================================
 *
 * cb.apricot.utils.swipe
 *
 * Detecting a swipe (left, right, top or down) using touch
 * ========================================================================
 *
 * cb.apricot.utils.tallestElm
 *
 * Returns an ordered list based on tallest element
 * ========================================================================
 *
 * cb.apricot.utils.adjustHeight
 *
 * Adjusts height of elements based on tallest height
 * ========================================================================
 *
 * cb.apricot.utils.isHighContrast
 *
 * Check if user High Contrast is active (IE)
 * ========================================================================
 *
 * cb.apricot.utils.clickJacking
 *
 * Prevent clickJacking
 * ======================================================================== */


+function ($, cb) {
  'use strict';

  var
    _keys = {
      BACKSPACE: 8,
      TAB: 9,
      ENTER: 13,
      SHIFT: 16,
      CTRL: 17,
      ALT: 18,
      ESC: 27,
      SPACE: 32,
      PAGEUP: 33,
      PAGEDOWN: 34,
      END: 35,
      HOME: 36,
      LEFT: 37,
      UP: 38,
      RIGHT: 39,
      PREV: 37,
      NEXT: 39,
      DOWN: 40,
      PLUS: 187,
      PLUSNUMERICKEYPAD: 107,
      MINUS: 189,
      MINUSNUMERICKEYPAD: 109,
      DEL: 46,
      A: 65,
      Z: 90,
      ZERO: 48,
      NINE: 57
    };

  function _isKey (event, key) {
    return _keys[key] === event.which ? true : false;
  }

  function _whichKey (event) {
    for(var key in _keys) {
      if (_keys[key] === event.which) {
        return key;
      }
    }
    return 'not found';
  }

  function _browser () {
    var
      t = true,
      browser = {},
      detect;

    detect = function (ua) {
      function getFirstMatch(regex) {
        var match = ua.match(regex);
        return (match && match.length > 1 && match[1]) || '';
      }

      var
        versionIdentifier = getFirstMatch(/version\/(\d+(\.\d+)?)/i),
        result = {};

      if (/opera|opr/i.test(ua)) {
        result = {
          name: 'Opera',
          opera: t,
          version: versionIdentifier || getFirstMatch(/(?:opera|opr)[\s\/](\d+(\.\d+)?)/i)
        };
      } else if (/msie|trident/i.test(ua)) {
        result = {
          name: 'Internet Explorer',
          msie: t,
          version: getFirstMatch(/(?:msie |rv:)(\d+(\.\d+)?)/i)
        };
      } else if (/chrome|crios|crmo/i.test(ua)) {
        result = {
          name: 'Chrome',
          chrome: t,
          version: getFirstMatch(/(?:chrome|crios|crmo)\/(\d+(\.\d+)?)/i)
        };
      } else if (/firefox|iceweasel/i.test(ua)) {
        result = {
          name: 'Firefox',
          firefox: t,
          version: getFirstMatch(/(?:firefox|iceweasel)[ \/](\d+(\.\d+)?)/i)
        };
      } else if (/safari/i.test(ua)) {
        result = {
          name: 'Safari',
          safari: t,
          version: versionIdentifier
        };
      } else {
        result = {};
      }

      return result;
    };

    browser = detect(typeof navigator !== 'undefined' ? navigator.userAgent : '');

    return browser;
  }

  function _addClassIE ($elm) {
    var
      browser = _browser();

    if (!!browser.msie) {
      $elm.addClass('ie' + parseInt(browser.version, 10));
    }
  }

  function _addClassSafari ($elm) {
    var
      browser = _browser();

    if (!!browser.safari) {
      $elm.addClass('safari' + parseInt(browser.version, 10));
    }
  }


  function _isBlank (value) {
    if (!value) {
      value = '';
    }

    return (/^\s*$/).test(value);
  }

  function _viewport () {
    var
      viewportType = {},
      getViewPortWidth;

    getViewPortWidth = function (data) {
      var
        result = {name: '', prefix: '', width: 0},
        $body = $('body'),
        viewPortWidth = 0;

      $body.css('overflow', 'hidden');
      viewPortWidth = $(window).width();
      $body.css('overflow', '');

      if ($.isEmptyObject(data)) {
        return result;
      }

      if (viewPortWidth < data.mobile.max) {
        result = {
          name: 'mobile',
          prefix: 'xs'
        };
      } else if ((viewPortWidth >= data.tablet.min) && (viewPortWidth < data.tablet.max)) {
        result = {
          name: 'tablet',
          prefix: 'sm'
        };
      } else if ((viewPortWidth >= data.desktop.min) && (viewPortWidth < data.desktop.max)) {
        result = {
          name: 'desktop',
          prefix: 'md'
        };
      } else {
        result = {
          name: 'oversize',
          prefix: 'lg'
        };
      }
      result.width = viewPortWidth;

      return result;
    };

    viewportType = getViewPortWidth(!$.isEmptyObject(cb.apricot.data.viewports) ? cb.apricot.data.viewports : {});

    return viewportType;
  }

  function _breakpoints (start) {
    //Make sure we only declare custom breakpoint_change event once
    if (!!$(document).data('_cbBreakpoints')) {
      return false;
    }

    var
      viewport = cb.apricot.utils.viewport();

    // Default: false
    start = !!start ? true : false;
    $(document).data('_cbViewport', viewport);

    //If start, trigger event on page load
    if (!!start) {
      $(window).on('load', function () {
        $(document).trigger('breakpoint_change', viewport);
      });
    }

    //Check breakpoint status on resize
    $(window).on('resize', function () {
      var
        currentViewport = cb.apricot.utils.viewport(),
        oldViewport = $(document).data('_cbViewport');

        // If viewport has changed trigger event
        if (oldViewport.name !== currentViewport.name) {
          $(document).trigger('breakpoint_change', currentViewport);
          $(document).data('_cbViewport', currentViewport);
        }
    });

    $(document).data('_cbBreakpoints', true);
  }

  function _textTruncate(value, maxChars, position, ellipseText) {
    if (position === 'last') {
      value = value.substr(0, maxChars - (ellipseText.length )) + ellipseText;
    }
    else if (position === 'first') {
      value = ellipseText + value.substr(value.length - (maxChars - (ellipseText.length)));
    } else {
      var middle = Math.floor(maxChars / 2) - (ellipseText.length);
      value = value.substr(0, middle) + ellipseText + value.substr(value.length - middle, value.length);
    }

    return value;
  }

  function _uniqueID(idLength, prefix) {
    var
      charArr = '_0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split(''),
      id = '';

    if (!idLength) {
      idLength = Math.floor(Math.random() * charArr.length);
    }

    for (var i = 0; i < idLength; i++) {
      id += charArr[Math.floor(Math.random() * charArr.length)];
    }

    if (!!prefix) {
      id = prefix + id;
    }

    if($('#' + id).length > 0) {
      return _uniqueID(idLength);
    } else {
      return id;
    }
  }

  function _isRetina () {
    return (window.devicePixelRatio > 1 || window.matchMedia && window.matchMedia('only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)').matches);
  }

  function _height ($elm) {
    return parseInt($elm.outerHeight(), 10);
  }

  function _tallestElm (arr) {
    var
      result = {},
      heightArr = [];

    result.max = 0;
    result.arr = [];

    if (arr.length > 0) {
      $.each(arr, function () {
        var
          self = $(this),
          obj = {};

        obj.height = cb.apricot.utils.height(self);
        obj.elm = self;

        heightArr.push(obj);
      });

      heightArr.sort(function (a, b) {
        return a.height - b.height;
      });

      result.max = heightArr[heightArr.length - 1].height;
      result.arr = heightArr;
    }

    return result;
  }

  function _adjustHeight (obj, selector) {
    var
      max = obj.max,
      arr = obj.arr;

    $.each(arr, function(index, object) {
      var
        diff = max - object.height,
        $elm = (!!selector) ? ($(selector, object.elm)) : object.elm;

      if (diff > 0) {
        $elm.css('margin-bottom', diff + 'px');
      }
    });
  }
  // based on: https://gist.github.com/hcaz/392afb681f0457fbd124b815c1eaf2f9
  function _swipe ($elm) {
    var
      touchDown = false,
      originalPosition = null,

      swipeInfo = function (event) {
        if ('undefined' !== typeof event.originalEvent.pageX) {
          var
            x = event.originalEvent.pageX,
            y = event.originalEvent.pageY,
            dx, dy;
        } else {
          var
            x = event.originalEvent.touches[0].pageX,
            y = event.originalEvent.touches[0].pageY,
            dx,
            dy;
        }

        dx = (x > originalPosition.x) ? 'right' : 'left';
        dy = (y > originalPosition.y) ? 'down' : 'up';

        return {
          direction: {
            x: dx,
            y: dy
          },
          offset: {
            x: x - originalPosition.x,
            y: originalPosition.y - y
          }
        };
      };

    $elm.on('touchstart mousedown', function(event) {
      touchDown = true;

      if ('undefined' !== typeof event.originalEvent.pageX) {
        originalPosition = {
          x: event.originalEvent.pageX,
          y: event.originalEvent.pageY
        };
      } else {
        originalPosition = {
          x: event.originalEvent.touches[0].pageX,
          y: event.originalEvent.touches[0].pageY
        };
      }
    });

    $elm.on('touchend mouseup', function(event) {
      $elm.trigger('swipe_end', swipeInfo(event));

      touchDown = false;
      originalPosition = null;
    });

    $elm.on('touchmove mousemove', function(event) {
      if (!touchDown) {
        return;
      }

      $elm.trigger('swipe_move', swipeInfo(event));
    });

    return true;
  }

  function _accBookmark($elm) {
    var
      target = '#' + $elm.attr('href').split('#')[1],
      adjust = 0;

    if ($('.cb-local-navigation').length > 0) {
      adjust = $('.cb-local-navigation').height() + 4;
    }

    $elm.on('click', function (e) {
      e.preventDefault();

      if ($(target).length > 0) {
        $('body, html').animate({
          scrollTop: $(target).offset().top - adjust
        }, 1500, function() {

          // Accessibility: Focus on target after animation is complete.
         $(target).attr('tabindex', -1).on('blur focusout', function () {

            $(this).removeAttr('tabindex');
          }).focus();
        });
      }
    });
  }

  function _isHighContrast (color) {
    // option to pass default font-color
    if (!color) {
      color = 'rgb(80, 80, 80)';
    }

    if ($('body').css('color') !== color) {
      return true;
    } else {
      return false;
    }
  }

  function _detectLang () {
    var lang = (!!$('html').attr('lang')) ? $('html').attr('lang') : 'en';

    if (lang.indexOf('-') >= 0) {
      lang = lang.split('-')[0];
    }

    return lang;
  }

  var _utils = {
    keys: _keys,
    isKey: _isKey,
    whichKey: _whichKey,
    browser: _browser,
    addClassIE: _addClassIE,
    addClassSafari: _addClassSafari,
    isBlank: _isBlank,
    viewport: _viewport,
    breakpoints: _breakpoints,
    textTruncate: _textTruncate,
    uniqueID: _uniqueID,
    isRetina: _isRetina,
    height: _height,
    tallestElm: _tallestElm,
    adjustHeight: _adjustHeight,
    swipe: _swipe,
    accBookmark: _accBookmark,
    isHighContrast: _isHighContrast,
    detectLang: _detectLang
  };

  cb.apricot.utils  = _utils;
}(jQuery, cb);
