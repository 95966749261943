/* ========================================================================
 * Multiselector
 * ========================================================================
 *
 * Add/Remove Multiselector
 * ======================================================================== */

+ function($, cb) {
  'use strict';

  cb.apricot.multiselector = function(element, options) {
    var
      defaultOptions = {
        keepItems: false,
        doubleClick: true,
        removeExistingItem: true
      },
      secList = [],
      plugin = this;

    plugin.$el = $(element);

    var
      addEvents = function() {

        // Add items
        $('.cb-right', plugin.$el).on('click.cbMultiselector', function(e) {
          e.preventDefault();

          secList = [];
          secList = $('option', plugin.$sec).length > 0 ? $('option', plugin.$sec) : [];

          // add to list
          getSelected(plugin.$first).each(function() {
            var self = $(this),
              $dup = self.clone();

            if (!plugin.options.keepItems) {
              self.addClass('hidden');
            }

            if ( $('[data-cb-order="' + $dup.attr('data-cb-order') + '"]', plugin.$sec).length > 0) {
              // trigger custom event for error
              plugin.$el.trigger('error.cbMultiselector', $dup);
            } else {

              secList.push($dup);
            }
          });


          // update list
          plugin.$sec.empty();

          secList.sort(function(a,b) {
            a = parseInt($(a).attr('data-cb-order'), 10);
            b = parseInt($(b).attr('data-cb-order'), 10);

            return a - b;
          });

          $.each(secList, function (key, obj) {

            plugin.$sec.append(obj);
          });

          if (!!plugin.options.doubleClick) {
            doubleClickEvents();
          }

          // add focus to second list
          plugin.$sec.focus();
        });

        // Remove items
        $('.cb-left', plugin.$el).on('click.cbMultiselector', function(e) {
          e.preventDefault();

          getSelected(plugin.$sec).each(function() {
            var self = $(this),
              order = self.attr('data-cb-order');

            if (!!self.attr('data-cb-keep')) {
              if (!!plugin.options.removeExistingItem) {
                $('[data-cb-order="' + order + '"]', plugin.$sec).remove();
                plugin.$el.trigger('not_found_remove.cbMultiselector', self);
              } else {
                plugin.$el.trigger('not_found.cbMultiselector', self);
              }
            } else {
              $('[data-cb-order="' + order + '"]', plugin.$first).removeClass('hidden');
              $('[data-cb-order="' + order + '"]', plugin.$sec).remove();
            }
          });


          // add focus to first list
          plugin.$first.focus();
        });

        // accessibility, enter/space
        $('.cb-left', plugin.$el).on('keydown.cbMultiselector', function(e) {
          if (!!cb.apricot.utils.isKey(e, 'ENTER') || !!cb.apricot.utils.isKey(e, 'SPACE')) {
            e.stopPropagation();
            e.preventDefault();

            $('.cb-left', plugin.$el).trigger('click.cbMultiselector');
          }
        });

        $('.cb-right', plugin.$el).on('keydown.cbMultiselector', function(e) {
          if (!!cb.apricot.utils.isKey(e, 'ENTER') || !!cb.apricot.utils.isKey(e, 'SPACE')) {
            e.stopPropagation();
            e.preventDefault();

            $('.cb-right', plugin.$el).trigger('click.cbMultiselector');
          }
        });


        if (!!plugin.options.doubleClick) {
          doubleClickEvents();
        }
      },

      doubleClickEvents = function () {
        // first list dblclick
        $('option', plugin.$first).off('dblclick.cbMultiselector').on('dblclick.cbMultiselector', function() {

          $('.cb-right', plugin.$el).trigger('click.cbMultiselector');
        });

        // second list dblclick
        $('option', plugin.$sec).off('dblclick.cbMultiselector').on('dblclick.cbMultiselector', function() {

          $('.cb-left', plugin.$el).trigger('click.cbMultiselector');
        });
      },

      getSelected = function($select) {
        var tmp = $('option:selected', $select);

        if (tmp.length === 0) {
          plugin.$el.trigger('error_selected.cbMultiselector');
        }

        return tmp;
      },

      setOrder = function() {
        var adjust = (!!plugin.$first.attr('data-cb-total')) ?
          plugin.$first.attr('data-cb-total') : 0,
          num = 0;

        $('option', plugin.$first).each(function(index) {
          num = (index + 1) + adjust;
          $(this).attr('data-cb-order', num);
        });

        plugin.$first.attr('data-cb-total', num);
      };

    plugin.init = function() {
      plugin.options = $.extend({}, defaultOptions, options);

      plugin.$first = {};
      plugin.$sec = {};

      plugin.$first = $('select:first', plugin.$el);
      plugin.$sec = $('select:last', plugin.$el);

      var id1 = (!!plugin.$first.attr('id')) ? plugin.$first.attr('id') :
        cb.apricot.utils.uniqueID(5, 'apricot_'),
        id2 = (!!plugin.$sec.attr('id')) ? plugin.$sec.attr('id') :
        cb.apricot.utils.uniqueID(5, 'apricot_');

      plugin.$first.attr('id', id1);
      plugin.$sec.attr('id', id2);

      $('label:first', plugin.$el).attr('for', id1);
      $('label:last', plugin.$el).attr('for', id2);


      $('.cb-right', plugin.$el).attr('aria-controls', id2);
      $('.cb-left', plugin.$el).attr('aria-controls', id1);

      var browser = cb.apricot.utils.browser();
      if (!!browser.msie || !!browser.safari) {
        plugin.$el.addClass('cb-multiselector-acc');
      }

      // set item order in first list
      setOrder();

      // add Events
      addEvents();
    };

    // reload items in list
    plugin.reload = function(items, keep) {

      if (!!keep) {
        $('option', plugin.$sec).each(function() {
          $(this).attr('data-cb-keep', true);
        });
      } else {
        plugin.$sec.empty();
      }

      plugin.$first.empty();

      for (var item in items) {
        $('<option />')
          .attr('value', items[item].value)
          .text(items[item].name)
          .appendTo(plugin.$first);
      }

      // set item order in first list
      setOrder();

      // add Events
      addEvents();
    };

    // Remove plugin instance and clean up
    plugin.destroy = function() {

      plugin.$sec.empty();
      $('option', plugin.$first).each(function() {
        $(this).removeAttr('data-cb-order');
      });

      $('.cb-left', plugin.$el).off('click.cbMultiselector');
      $('.cb-left', plugin.$el).off('keydown.cbMultiselector');

      $('.cb-right', plugin.$el).off('click.cbMultiselector');
      $('.cb-right', plugin.$el).off('keydown.cbMultiselector');

      if (!!plugin.options.doubleClickEvents) {
        $('option', plugin.$first).off('dblclick.cbMultiselector');
        $('option', plugin.$sec).off('dblclick.cbMultiselector');
      }

      plugin.$el.off('error.cbMultiselector')
        .removeData('cbMultiselector');
    };

    plugin.init();
  };

  $.fn.cbMultiselector = function(options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function() {
        if (!$(this).data('cbMultiselector')) {
          $(this).data('cbMultiselector', new cb.apricot.multiselector(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function() {
        var instance = $.data(this, 'cbMultiselector');
        if (instance instanceof cb.apricot.multiselector && typeof instance[options] === 'function') {
          instance[options].apply(instance, Array.prototype.slice.call(args, 1));
        }
      });
    }
  };
}(jQuery, cb);
