/* ========================================================================
 * Local Navigation
 * ========================================================================
 *
 * $.fn.cbLocalNavigation
 *
 * This plugin adds responsive and accessible functionality to CB's
 * local navigation following Apricot's grid system
 *
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  cb.apricot.localNav = function (element, options) {
    var
      defaultOptions = {
        desktopClass: 'cb-desktop-navigation',
        mobileClass: 'cb-mobile-navigation',
        mobileHeaderClass: 'cb-mobile-local-header',
        mobilePanelClass: 'cb-mobile-local-panels',
        panelClass: 'cb-menu-panel',

        siteNameClass: 'cb-site-name',
        programNameClass: 'cb-program-name',
        multiLineClass: 'cb-multi',
        anchorMenuClass: 'cb-anchor-menu',

        addDesktopParentLabel: true,
        addMobileParentLabel: true,

        desktopLayoutOnly: false,

        desktopHomeLabel: 'Home',
        desktopLabelPrefix: true,

        mobileHomeLabel: 'Home',
        mobileLabelPrefix: true,

        customHomeLabel: '',

        moreLabel: 'More',

        mobilePanelTopOffset: 0,
        mobileMenuHeight: 54,

        activePage: false,
        activePageType: true,

        moreFeature: true,
        stickyHeader: true,
        mobileLayout: 'tablet'
      },
      plugin = this,
      $menu = {},
      $desktopMenu = {},
      $mobileMenu = {},
      $more = {},
      $title = {},
      menuItems = [],
      mainItems = [],
      linkItems = [],
      panels = [],
      nestedPanelArr = [],
      parentCount = 0,
      ariaLabel = '',
      navigationTopPosition = 0;

      plugin.$el = $(element);
      plugin.el = element;

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, plugin.$el.data(), options);
      var
        $link = {},
        children = [],
        item = {},
        parentId = 1;

      // get all items in the local navigation
      $menu = $('ul', plugin.$el).first();
      $desktopMenu = $('nav', plugin.$el);

      $title = $('.title', plugin.$el);

      navigationTopPosition = parseInt(plugin.$el.offset().top, 10);

      // this is needed for accessibility
      ariaLabel = !!$desktopMenu.attr('aria-label') ? $desktopMenu.attr('aria-label') : '';

      $('ul > li', plugin.$el).each(function () {
        var
          self = $(this);

        item = {};
        item.obj = self;
        item.width = parseInt(getWidth($(this)), 10);

        if (!self.attr('data-cb-list')) {
          if ($('ul', self).length > 0) {
            self.attr('data-cb-parent', parentId);
            item.type = 'nested';
            item.parent = parentId;

            item.list = getChildrenList(self, parentId);

            if (item.list.length > 0 ) {
              parentId += parentCount + 1;
              parentCount = 0;
            }
          } else {
            item.type = 'simple';
          }

          menuItems.push(item);
          mainItems.push(item);
        }
      });


      $('[data-cb-list="true"]', plugin.$el).removeAttr('data-cb-list');

      // get url's for active menu item
      if (!!plugin.options.activePage) {
        for (var item in menuItems) {
          var
            li = menuItems[item],
            self = li.obj;

          item = {};
          if (!!self.hasClass('dropdown')) { //nested navigation
            children = [];

            $('a', self).each(function () {
              var
                $child = $(this);

              item = {};
              item.type = 'child';
              item.obj = $child;
              item.url = $child.attr('href');
              children.push(item);
            });

            $link = $('a', self);
            item.type = 'nested';
            item.obj = self;
            item.url = $link.attr('href');
            item.list = children;
          } else {
            $link = $('a', self);
            item.type = 'single';
            item.obj = $link.parent();
            item.url = $link.attr('href');
            item.list = [];
          }

          if (!$.isEmptyObject(item)) {
            linkItems.push(item);
          }
        }
      }

      //Add additional link for parent item
      if (!!plugin.options.addDesktopParentLabel) {
        addAdditionalTopLinks();
      }

      // build Mobile navigation together
      if (!plugin.options.desktopLayoutOnly) {
        buildMobileNavigation();
      }

      // build more dropdown
      if (!!plugin.options.moreFeature) {
        buildMoreDropdown();
      }

      // hover/focus
      addExtraClass($('a', $menu));
      addExtraClass($('a.title', plugin.$el));

      // track breakpoint changes
      if (!plugin.options.desktopLayoutOnly) {
        breakpointChanges();
      }

      // set accessibility for dropdowns
      $('.dropdown', plugin.$el).not('.panel-sub').cbDropdownExtension();

      // add class to active menu item
      if (!!plugin.options.activePage) {
        setActivePage();
      }
    };

    var
    breakpointChanges = function () {
      checkMenuStatus();

      //Check breakpoint status on resize
      $(window).on('resize.cbLocalNavigation', function () {
        checkMenuStatus();

        if (!$mobileMenu.hasClass('hidden')) {
          calculateMobileNavHeight();
        }
      });

      // check sticky header option
      plugin.checkSticky();
    },

    // find nested list items
    getChildrenList = function ($elm, parentId) {
      var
        list = [],
        item = {};

      $('ul:first', $elm).children().each(function () {
        var
          self = $(this);

        item = {};
        item.obj = self.clone();
        item.child = parentId;
        item.list = getChildrenList(self, parentId + 1 );

        if (item.list.length > 0 ) {

          item.parent = parentId + 1;
          parentCount += 1;
        }

        list.push(item);
        self.attr('data-cb-list', true);
      });


      return list;
    },

    // figure out what the main structure we need (mobie/desktop)
    checkMenuStatus = function () {
      var
        currentViewport = cb.apricot.utils.viewport();

      changeMenuStructure(currentViewport.width);

      if (plugin.options.mobileLayout === 'tablet') {
        if (currentViewport.prefix !== 'sm' && currentViewport.prefix !== 'xs' && !!plugin.options.moreFeature) {
          checkMoreStatus();
        }
      } else if (plugin.options.mobileLayout === 'mobile') {
        if (currentViewport.prefix !== 'xs' && !!plugin.options.moreFeature) {
          checkMoreStatus();
        }
      }
    },

    // Add Link for main items
    // 1: main
    // 0: sub
    addMobileHomeLink = function (mode, label, href) {
      var
        $item = {},
        $a = {},
        $span = {},
        labelText = !!mode ? plugin.options.mobileHomeLabel : ((!!plugin.options.mobileLabelPrefix) ? plugin.options.mobileHomeLabel + ' ' + label : label + ' ' + plugin.options.mobileHomeLabel),
        hrefValue = !!mode ? $('a.' + plugin.options.programNameClass , plugin.$el).attr('href') : href,
        customLbl = false;

      // Only add if parent has an actual URL/Hash value
      if(cb.apricot.utils.isBlank(hrefValue) || hrefValue === '#') {
        return {};
      }

      if (plugin.options.customHomeLabel !== '') {
        labelText = plugin.options.customHomeLabel;
        customLbl = true;
      }

      $item = $('<li/>');

      $a = $('<a/>')
        .attr('href', hrefValue)
        .attr('tabindex', 0)
        .appendTo($item);

      $span = $('<span/>')
        .html(labelText)
        .appendTo($a);

      if (!!customLbl) {
        $('<span/>')
          .addClass('sr-only')
          .html(' ' + label)
          .appendTo($a);
      }

      return $item;
    },

    // find obj in object array
    findObj = function (items, attr, value) {
      for (var item in items) {
        if (items[item][attr] === value) {
          return items[item];
        }
      }

      return {};
    },

    // calculate sub-panel ID
    checkParentId = function (obj) {
      var
        tmp = findObj(nestedPanelArr, 'id', obj.parent),
        count = 0,
        removeIndex = 0;

      if (!!$.isEmptyObject(tmp)) {
        tmp.id = obj.parent;
        tmp.count = 1;
        nestedPanelArr.push(tmp);
      } else {
        removeIndex = nestedPanelArr.map(function(item) { return item.id; }).indexOf(obj.parent);
        nestedPanelArr.splice(removeIndex, 1);
        count = parseInt(tmp.count) + 1;
        tmp.count = count;
        nestedPanelArr.push(tmp);
      }

      return tmp.count;
    },

    // generate HTML for Mobile navigation
    buildMobileNavigation = function () {
      var
        $divHeader = {},
        $divPanel = {},
        $item = {},
        $a = {},
        $span = {},
        $program = $('.' + plugin.options.programNameClass, plugin.$el),
        mainPanelItems = [],
        count = 0;

      // Mobile elements don't exist
      if (!!$.isEmptyObject($mobileMenu)) {
        $mobileMenu = $('<nav/>')
          .addClass(plugin.options.mobileClass)
          .addClass('hidden')
          // .attr('role', 'navigation')
          .appendTo(plugin.$el);

        if (!!$('.' + plugin.options.siteNameClass, plugin.$el).hasClass(plugin.options.multiLineClass)) {
          $mobileMenu.addClass(plugin.options.multiLineClass);
        }

        $divHeader = $('<div/>')
          .addClass(plugin.options.mobileHeaderClass)
          .appendTo($mobileMenu);

        $divPanel = $('<div/>')
          .addClass(plugin.options.mobilePanelClass)
          .appendTo($mobileMenu);

        $a = $('<a/>')
          .addClass('local-menu-title')
          .attr('tabindex', 0)
          .attr('data-cb-header-title', $('span', $program).html())
          .attr('href', $($program).attr('href'))
          .html($program.html())
          .appendTo($divHeader);

        $a = $('<a/>')
          .addClass('local-menu-icon')
          .attr('href', '#')
          .attr('tabindex', 0)
          .attr('data-cb-panel-for', 'panel-main')
          .attr('data-cb-panel-nesting', '0')
          .html('<span class="sr-only">' + cb.apricot.lang.get('localNav1') + '</span>')
          .appendTo($divHeader);

          var $icon = $('<span />')
            .addClass('cb-glyph')
            .addClass('cb-down')
            .addClass('cb-menu')
            .attr('aria-hidden', 'true');

          $a.append($icon);

        // trigger custom event
        $a.on('click.cbLocalNavigation', function() {

          triggerCustomEvents(2, $(this));
        });

        // add tab order for accessibility
        $a.on('keydown.cbLocalNavigation', function(e) {
          if (!!cb.apricot.utils.isKey(e, 'TAB') && e.shiftKey) { //shift & tab
            e.preventDefault();

            $('.local-menu-title', plugin.$el).focus();
          }

          if (!!cb.apricot.utils.isKey(e, 'TAB') && !e.shiftKey) { //tab
            e.preventDefault();

            var $panel = $('.' + plugin.options.panelClass + '.show', plugin.$el).last();


            if (!!$(e.target).hasClass('local-menu-icon')) {
              // No panel is open
              if ($('.' + plugin.options.panelClass + '.show', plugin.$el).length > 0 ){
                $('a', $panel).first().focus();
              } else {
                $('.' + plugin.options.mobilePanelClass, $mobileMenu).find('a').last().focus();
              }
            } else {
              $('a', $panel).first().focus();
            }
          }
        });

        for(var item in menuItems) {
          var
            obj = menuItems[item],
            $elm = obj.obj,
            $clone = $elm.clone();

          if(obj.type === 'nested') {
            $item = {};

            count = checkParentId(obj);
            $item = $('<li/>')
              .attr('data-cb-panel-for', 'panel-sub-' + obj.parent + '-' + count)
              .addClass('panel-sub')
              .addClass('mobile-dropdown');

            $a = $('<a/>')
              .attr('href', $('a', $elm).attr('href'))
              .attr('tabindex', 0)
              .appendTo($item);

            $span = $('<span/>')
              .html($('span', $elm).first().html())
              .appendTo($a);

            $('<span/>')
              .addClass('sr-only')
              .addClass('cb-sub-items')
              .html('has sub-navigation items')
              .appendTo($a);


            mainPanelItems.push($item);

            // find all nested items
            subPanelItems(obj);
          } else if (obj.type === 'simple') {

            mainPanelItems.push($clone);
          }
        }

        buildMenuPanel(mainPanelItems, 'main');
        // order panels
        panels.sort(function (a, b) {
          return parseFloat(a.order) - parseFloat(b.order);
        });
        for (var item in panels) {
          panels[item].panel.appendTo($divPanel);
        }
        mobileEvents();
        $(document).trigger('localnav_mobileLayout_finished', new Date());
      }
    },

    // put together subPanelItems
    subPanelItems = function (object) {
      var
        panelItems = [],
        items = object.list,
        $elm = object.obj,
        $item = {},
        $a = {},
        $span = {},
        label = '',
        href = '',
        count = 0;

      label = $('span:first', $elm).html();
      href = $('a:first', $elm).attr('href');

      if (!!plugin.options.addMobileParentLabel) {
        $elm = addMobileHomeLink(0, label, href);
        if (!$.isEmptyObject($elm)){
          panelItems.push($elm);
        }
      }

      for (var item in items) {
        var
          obj = items[item];

        $elm = {};
        $elm = obj.obj;

        if (obj.list.length > 0) {
          $item = {};
          count = checkParentId(obj);

          $item = $('<li/>')
            .attr('data-cb-panel-for', 'panel-sub-' + obj.parent + '-' + count)
            .addClass('panel-sub')
            .addClass('mobile-dropdown');

          $a = $('<a/>')
            .attr('href', $('a', $elm).attr('href'))
            .attr('tabindex', 0)
            .appendTo($item);

          $span = $('<span/>')
            .html($('span', $elm).first().html())
            .appendTo($a);

          $('<span/>')
            .addClass('sr-only')
            .addClass('cb-sub-items')
            .html('has sub-navigation items')
            .appendTo($a);

          panelItems.push($item);

          subPanelItems(obj);
        } else {
          panelItems.push($elm);
        }
      }

      if (panelItems.length > 0) {
        buildMenuPanel(panelItems, object.parent);
      }
    },

    // build HTML for Mobile sliding panels
    buildMenuPanel = function (items, panel) {
      var
        $ul = {},
        $back = {},
        item = {},
        count = findObj(nestedPanelArr, 'id', panel).count,
        dataPanel = (!!isNaN(panel)) ? 'panel-main' : 'panel-sub-' + panel,
        nesting = (panel === 'main') ? 0 : panel,
        $panel = $('<div/>')
          .addClass(plugin.options.panelClass)
          .attr('data-cb-panel', dataPanel)
          .attr('data-cb-panel-nesting', nesting);

      if (dataPanel !== 'panel-main'){
        $panel.attr('data-cb-panel', dataPanel + '-' + count);
      }

      $ul = $('<ul/>')
        .appendTo($panel)
        .addClass('link-panel');


      if (dataPanel === 'panel-main'){
        $ul.addClass('first-panel');
      }

      if (dataPanel !== 'panel-main'){
        $back = $('<li/>')
          .addClass('cb-nav-back');

        $ul.append($back);
      }

      for(var item in items) {
        $ul.append(items[item]);
      }

      item = {};
      item.order = (!isNaN(panel)) ? panel : 0;
      item.panel = $panel;

      panels.push(item);
    },

    // Calculate mobile panel height
    calculateMobileNavHeight = function () {
      var
        pageHeight = $(window).height(),
        activePanleHeight = 0,
        navigationHeight = 0,
        $panelsBlock = $('.' + plugin.options.mobilePanelClass, $mobileMenu);

      activePanleHeight = calculateMobileItemsHeight();
      navigationHeight = (pageHeight > activePanleHeight) ? pageHeight : activePanleHeight;

      $('.' + plugin.options.panelClass, plugin.$el).css('min-height', navigationHeight);

      if (navigationHeight > pageHeight) {
        $panelsBlock.addClass('cb-overflow-y');
      } else {
        $panelsBlock.removeClass('cb-overflow-y');
      }

      // panels are open
      if (!!$('[data-cb-panel="panel-main"]', plugin.$el).hasClass('show')) {
       setBodyRestrictions();
      }
    },

    // Calculate mobile items height
    calculateMobileItemsHeight = function () {
      var
        $activeOpenPanel = $('.' + plugin.options.panelClass + '.show', plugin.$el),
        $links = $('.link-panel li', $activeOpenPanel.last()),
        itemHeight = parseInt($links.first().height(), 10),
        panelHeight = ($links.length * itemHeight) + navigationTopPosition;


      if (!plugin.$el.hasClass('sticky-local-nav')) {
        panelHeight += plugin.$el.height();
      }

      return (!!$.isNumeric(panelHeight)) ? panelHeight : 0;
    },

    // switch between mobie/desktop structure
    changeMenuStructure = function (width) {
      var
        maxWidth = (plugin.options.mobileLayout === 'tablet') ? cb.apricot.data.viewports.tablet.max : ((plugin.options.mobileLayout === 'mobile') ? cb.apricot.data.viewports.mobile.max : 0);

      if (maxWidth === 0) {
        return false;
      }

      if (width <= maxWidth) {
        resetMoreDropdown();
        switchNavigation(0);
      } else if (!$.isEmptyObject($mobileMenu)) {
        switchNavigation(1);
      }
    },

    //0: desktop
    //1: mobile
    switchNavigation = function (mode) {
      resetBody();
      if (!!mode) {
        $desktopMenu.removeClass('hidden');
        if (!cb.apricot.utils.isBlank(ariaLabel)) {
          $desktopMenu.attr('aria-label', ariaLabel);
        }

        $('.local-menu-icon .cb-glyph', $mobileMenu).removeClass('cb-x-mark')
          .addClass('cb-menu');
        $('.local-menu-icon .sr-only', $mobileMenu).html(cb.apricot.lang.get('localNav1'));


        $mobileMenu.addClass('hidden');
        $mobileMenu.removeAttr('aria-label');
        changeMobileTitle(1);
        $('.' + plugin.options.panelClass + '.show', plugin.$el).removeClass('show');
      } else {
        $mobileMenu.removeClass('hidden');
        if (!cb.apricot.utils.isBlank(ariaLabel)) {
          $mobileMenu.attr('aria-label', ariaLabel);
        }

        $desktopMenu.addClass('hidden');
        $desktopMenu.removeAttr('aria-label');
      }
    },

    // find active menu item
    // activePageType: 1 ,filename
    // activePageType: 0 ,hash
    setActivePage = function () {
      var
        hashValue = window.location.hash,
        url = window.location.pathname,
        filename = '',
        items = [];

      //0: file name
      //1: hash
      if (!!plugin.options.activePageType) {
        filename = getFileName(url);
      } else {
        filename = '#' + hashValue.replace(/^#/, '');
      }

      if (!!cb.apricot.utils.isBlank(filename) || filename === '#') {
        return false;
      }

      for (var link in linkItems) {
        if (linkItems[link].type === 'single') {
          if (!plugin.options.activePageType) { // check for hash
            if (linkItems[link].url === filename) {
              linkItems[link].obj.addClass('active');
            }
          } else { // match name
            if (linkItems[link].url.indexOf(filename) >= 0) {
              linkItems[link].obj.addClass('active');
            }
          }
        } else {
          items = linkItems[link].list;

          for (var item in items) {
            if (!plugin.options.activePageType) { // check for hash
              if (items[item].url === filename) {
                linkItems[link].obj.addClass('active-trail');
              }
            } else { // match name
              if (items[item].url.indexOf(filename) >= 0) {
                linkItems[link].obj.addClass('active');
              }
            }
          }
        }
      }

      $('.active a', plugin.$el)
        .prepend('<span class="sr-only">' + cb.apricot.lang.get('localNav2') + '</span>');
    },

    //strip filename from url
    getFileName = function (url) {

      return !!url ? url.split('/').pop().split('#').shift().split('?').shift() : '';
    },

    // add events to the local navigation
    mobileEvents = function ($elm, $back) {
      var
        $selector = (!!$back && $elm) ? $back : $('[data-cb-panel-for^="panel"]', plugin.$el);


      $selector.off('click.cbLocalNavigation').on('click.cbLocalNavigation', function (e) {
        e.preventDefault();

        var
          self = (!!$back && $elm) ? $elm : $(this),
          panelName = self.attr('data-cb-panel-for'),
          $openPanels = $('.' + plugin.options.panelClass + '.show', plugin.$el),
          $selPanel = $('div[data-cb-panel="' + panelName + '"]', plugin.$el),
          item = {},
          topPosition = 0,
          cEvent = true,
          nested = [],
          eventObj = {};

        if (plugin.options.mobilePanelTopOffset > 0) {
          topPosition = plugin.options.mobilePanelTopOffset;
        } else {
          topPosition = (parseInt($('.' + plugin.options.mobileHeaderClass, plugin.$el).outerHeight(true), 10) !== 0) ?
            parseInt($('.' + plugin.options.mobileHeaderClass, plugin.$el).outerHeight(true), 10) :
            plugin.options.mobileMenuHeight;
        }

        // set CSS
        if (!!plugin.$el.hasClass('sticky-local-nav')) {
          $selPanel.css('top', topPosition);
        } else {
          $selPanel.css('top', '');
        }

        if (!panelName) { //go back one step
          panelName = self.attr('data-cb-panel-close');
          $('div[data-cb-panel="' + panelName + '"]', plugin.$el).removeClass('show').addClass('adjust');
          setTimeout(function () {
            $('div[data-cb-panel="' + panelName + '"]', plugin.$el).removeClass('adjust');
          }, 500);

          cEvent = false;
          if (!!self.attr('data-cb-panel-prev')) {
            nested = self.attr('data-cb-panel-prev').split('-');
            if (!!nested[2]) {
              eventObj.level = parseInt(nested[2], 10);
            }
          } else {
            eventObj.level = 0;
          }
          eventObj.type = 'close';
          // trigger custom event
          triggerCustomEvents(3, eventObj);
          changeMobileTitle(2);

          // accessibility
          var $panel = $('.' + plugin.options.panelClass + '.show').last();
          $('a', $panel).first().focus();
          setAriaHidden($panel);
        } else if (panelName === 'panel-main') { //Show/close main panel
          if ($openPanels.length > 0) { //Close all panels
            $('.cb-glyph', self).removeClass('cb-x-mark')
              .addClass('cb-menu');
            $('.sr-only', self).html(cb.apricot.lang.get('localNav1'));
            resetBody();
            $openPanels.removeClass('show').addClass('adjust');
            setTimeout(function () {
              $openPanels.removeClass('adjust');
            }, 500);
            cEvent = false;
            setAriaHidden();
            // trigger custom event
            triggerCustomEvents(0, self);
          } else { //show main panel
            $('.cb-glyph', self).addClass('cb-x-mark')
              .removeClass('cb-menu');
            $('.sr-only', self).html(cb.apricot.lang.get('localNav3'));

            $selPanel.addClass('show');
            setBodyRestrictions();
            // trigger custom event
            triggerCustomEvents(1, self);
            setAriaHidden($('.cb-menu-panel[data-cb-panel="panel-main"]', plugin.$el));
          }

          changeMobileTitle(1);
        } else { //go to next level
          $selPanel.addClass('show');

          item.panelName = panelName;
          item.title = $('span', self).html();
          item.href = $('a', self).attr('href');

          // Create back label
          changeMobileTitle(0, item);
        }
        calculateMobileNavHeight();

        if (!!$selPanel.attr('data-cb-panel-nesting') && !!cEvent) {
          eventObj.level = parseInt($selPanel.attr('data-cb-panel-nesting'), 10);
          eventObj.type = 'show';
          // trigger custom event

          triggerCustomEvents(3, eventObj);
        }
      });

      // accessibility
      setAriaHidden($('.' + plugin.options.panelClass + '.show').last());

      keyBoardUpDown();
    },

    // accessibility, keyboard navigation
    keyBoardUpDown = function () {
      var $panel = ($('.cb-menu-panel.show', plugin.$el).length > 0) ?
        $('.cb-menu-panel.show', plugin.$el).last() :
        $('[data-cb-panel="panel-main"]', plugin.$el),
        $ul = $('ul', $panel).first(),
        $items = $('li > a', $ul);

      $items.each(function (count) {

        var self = $(this);

        self.off('keydown.cbLocalNavigation.key').on('keydown.cbLocalNavigation.key', function (e) {
          var k = e.which || e.keyCode,
          index = $('li > a', $ul).index($items.filter(':focus'));

          // APRICOT-2504
          if (!!/(13)/.test(k) || !!/(32)/.test(k) ){ //enter
            $(this).trigger('click.cbLocalNavigation');
          } else if (!/(38|40)/.test(k)){//up/down

            return;
          }

          // up
          if (k === 38) {
            index--;
          } else if (k === 40) {  // DOWN
            index++;
          }

          if(index < 0) {
            index = $items.length - 1;
          }
          if(index === $items.length) {
            index = 0;
          }

          var $nextItem= $items.eq(index);
          $nextItem.focus();

          return;
        });
      });

      $items.last().off('keydown.cbLocalNavigation.tab').on('keydown.cbLocalNavigation.tab', function (e) {
        if (!!cb.apricot.utils.isKey(e, 'TAB') && !e.shiftKey) { //tab
          if ($('.' + plugin.options.panelClass + '.show', plugin.$el).length > 0 ){
            e.preventDefault();
            $('.local-menu-title', plugin.$el).focus();
          }
        }
      });

      $items.first().off('keydown.cbLocalNavigation.shiftTab').on('keydown.cbLocalNavigation.shiftTab', function (e) {
        if (!!cb.apricot.utils.isKey(e, 'TAB') && e.shiftKey) { //shift & tab
          e.preventDefault();
          $('.local-menu-icon', plugin.$el).focus();
        }
      });
    },

    // accessibility, add ARIA rules to all hidden panels
    setAriaHidden = function ($panel) {
      var
        panelID = (!!$panel) ? $panel.attr('data-cb-panel') : 'all';

      $('.cb-menu-panel', plugin.$el).each(function () {
        var self = $(this);

        if (self.attr('data-cb-panel') !== panelID) {
          self.attr('aria-hidden', 'true')
              .attr('tabindex', '-1');

          $('a', self).each (function () {
            $(this).attr('aria-hidden', 'true')
              .attr('tabindex', '-1');
          });
        } else {
          $('a', self).each (function () {
            $(this).attr('aria-hidden', 'false')
              .attr('tabindex', '0');

            self.attr('aria-hidden', 'false')
              .attr('tabindex', '0');
          });
        }
      });
    },


    // 1: main
    // 0: sub panel next
    // 2: sub panel prev
    changeMobileTitle = function (mode, item) {
      var
        $header = $('.local-menu-title', plugin.$el),
        $headerBack = $('.' + plugin.options.panelClass + '.show .cb-nav-back').last(),
        prevList = [],
        prev = '',
        titleList = [],
        title = '';

      if (mode === 1) { // reset to main title
        $header
          .removeAttr('data-cb-panel-close')
          .removeAttr('data-cb-panel-prev')
          .removeAttr('data-cb-panel-title');
      } else if (mode === 0 ){ //go to next level
          prevList = (!!$header.attr('data-cb-panel-prev')) ? $header.attr('data-cb-panel-prev').split(',') : [];
          titleList = (!!$header.attr('data-cb-panel-title')) ? $header.attr('data-cb-panel-title').split(',') : [];

          if (!!$header.attr('data-cb-panel-close')) {
            if ($.inArray($header.attr('data-cb-panel-close'), prevList) < 0 ) {
              prevList.push($header.attr('data-cb-panel-close'));
            }
            if ($.inArray($headerBack.html(), titleList) < 0 ) {
              titleList.push($headerBack.html());
            }
          }

          $header
            .attr('data-cb-panel-title', titleList.join())
            .attr('data-cb-panel-prev', prevList.join())
            .attr('data-cb-panel-close', item.panelName);
          var $a = $('<a />');

          $('<span />')
            .addClass('sr-only')
            .html(cb.apricot.lang.get('localNav4'))
            .appendTo($a);

          $('<span />')
            .html(item.title)
            .appendTo($a);

            $a.attr('tabindex', 0)
              .attr('href', item.href)
              .attr('aria-label', cb.apricot.lang.get('localNav4'));

          $headerBack
            .empty()
            .append($a);

          mobileEvents($header, $headerBack);

          $a.focus();
      } else { // go back one level
        prevList = (!!$header.attr('data-cb-panel-prev')) ? $header.attr('data-cb-panel-prev').split(',') : [];
        prev = prevList[prevList.length - 1];
        prevList.splice($.inArray(prev, prevList), 1);

        titleList = (!!$header.attr('data-cb-panel-title')) ? $header.attr('data-cb-panel-title').split(',') : [];
        title = titleList[titleList.length - 1];
        titleList.splice($.inArray(title, titleList), 1);

        if (!prev) {
          changeMobileTitle(1);
        } else {
          $header
            .attr('data-cb-panel-prev', prevList.join())
            .attr('data-cb-panel-title', titleList.join())
            .attr('data-cb-panel-close', prev);

          mobileEvents($header);
        }
      }
    },

    // add class to main navigation for style
    addExtraClass = function ($this) {
      var parent = '';

      if ($this.hasClass('title')) {
        parent= 'div';
      } else {
        parent = 'li';
      }
      $this.on('mouseover.cbLocalNavigation', function () {
        $(this).parent(parent).addClass('hover');
      });
      $this.on('mouseleave.cbLocalNavigation', function () {
        $(this).parent(parent).removeClass('hover');
      });

      $this.on('focus.cbLocalNavigation', function () {
        $(this).parent(parent).addClass('focus');
      });

      $this.on('blur.cbLocalNavigation', function () {
        $(this).parent(parent).removeClass('focus');
      });

      $this.on('keydown.cbLocalNavigation', function (e) {
        var self = $(this),
            $li = self.parent(),
            $a = {};

        if (!!cb.apricot.utils.isKey(e, 'DOWN')) { //down
          if (self.next().is('ul')) {
            var
              items = self.closest('ul').children(),
              index = items.index($li);

            if (index < items.length) {
              var $next = $(items.get(index + 1));
              if (!!$next) {
                $a = $('a', $next).first();
                $a.focus();
              }
            }
          }
        } else if (!!cb.apricot.utils.isKey(e, 'UP')) { //up
          var $prv = $li.prev();
          if ($('ul', $prv).length > 0) {
            $a = $('a', $prv).first();
            $a.focus();
          }
        }
      });

      if (!!$this.parent(parent).hasClass('dropdown')) {
        var
          $dropdown = $this.parent('li');

        $dropdown.on('shown.bs.dropdown', function () {

          $(this).removeClass('focus');
        });
      }
    },

    // add top link to dropdow items
    addAdditionalTopLinks = function () {
      var
        $item = {},
        $a = {},
        $span = {},
        $firstChild = {},
        label = '',
        href = '',
        customLbl = false;

      $('ul > li', plugin.$el).each(function () {
        var
          self = $(this);

        if (!!self.hasClass('dropdown')) {
          href = $('a', self).attr('href');
          // Only add if parent has an actual URL/Hash value
          if(cb.apricot.utils.isBlank(href) || href === '#') {
            return false;
          }

          $firstChild = $('li:first', self);
          label = (!!plugin.options.desktopLabelPrefix) ?
            plugin.options.desktopHomeLabel + ' ' + $('span:first', self).html() :
            $('span:first', self).html() + ' ' + plugin.options.desktopHomeLabel;

          if (plugin.options.customHomeLabel !== '') {
            label = plugin.options.customHomeLabel;
            customLbl = true;
          }

          $item = $('<li/>')
            .addClass('cb-desktop-label');

          $a = $('<a/>')
            .attr('href', href)
            .attr('tabindex', 0)
            .appendTo($item);

          $span = $('<span/>')
            .html(label)
            .appendTo($a);

          if (!!customLbl) {
            $('<span/>')
              .addClass('sr-only')
              .html(' ' + $('span:first', self).html())
              .appendTo($a);
          }

          $item.insertBefore($firstChild);
        }
      });
    },

    // check if we need a more dropdown
    checkMoreStatus = function () {
      var
        containerWidth =  $('.' + plugin.options.desktopClass, plugin.$el).innerWidth(),
        titleWidth = ($('.title', plugin.$el).length > 0) ? $('.title', plugin.$el).innerWidth() : 0,
        breakPointIndex = 0,
        itemsWidth = 0,
        moreWidth = 80,
        //we have to take the width of more drop down into consideration
        limit = parseInt(containerWidth, 10) - parseInt(titleWidth, 10);

      limit = (limit - (45 + moreWidth));

      for (var item in mainItems) {
        itemsWidth += mainItems[item].width;
        if (itemsWidth <= limit) {
          breakPointIndex += 1;
        }
      }

      if (itemsWidth > limit) {
        resetMoreDropdown();
        activateMoreDropdown(breakPointIndex);
      } else {
        $('.exp-more', plugin.$el).addClass('hidden');
        $('.exp-less', plugin.$el).removeClass('hidden')
          .removeClass('exp-less');
      }
    },

    // put together HTML for more dropdown
    buildMoreDropdown = function () {
      var
        $menu = $('.' + plugin.options.desktopClass + ' ul:first', plugin.$el),
        $a = {},
        $span = {},
        $i = {},
        $ul = {};

      if (!!$.isEmptyObject($more)) {
        $more = $('<li/>')
          .addClass('dropdown')
          .addClass('exp-more');

          $menu.append($more);

        $a = $('<a/>')
          .addClass('dropdown-toggle')
          .attr('data-toggle', 'dropdown')
          .attr('href', '#')
          .attr('role', 'button')
          .attr('aria-expanded', 'false')
          .appendTo($more);

        $span = $('<span/>')
          .text(plugin.options.moreLabel)
          .appendTo($a);
        $span = $('<span/>')
          .addClass('sr-only')
          .text(cb.apricot.lang.get('localNav5'))
          .appendTo($a);

        $i = $('<i/>')
          .addClass('cb-glyph')
          .addClass('cb-down')
          .attr('aria-hidden', 'true')
          .appendTo($a);
        $i = $('<i/>')
          .addClass('cb-glyph')
          .addClass('cb-up')
          .addClass('hidden')
          .attr('aria-hidden', 'true')
          .appendTo($a);

        $ul = $('<ul/>')
          .addClass('dropdown-menu')
          .attr('role', 'menu')
          .appendTo($more);
      } else {
        $more.removeClass('hidden');
      }

      return $more;
    },

    // add items to more dropdown
    activateMoreDropdown = function (index) {
      var
        moreList = [],
        $more = buildMoreDropdown(),
        $tmp = {},
        $ul = $('ul', $more),
        $item = {},
        $a = {},
        $span = {};

      for (var i = index; i < mainItems.length; i++) {
        $tmp = mainItems[i].obj;

          if (!!$tmp.hasClass('dropdown') || $('ul', $tmp).length > 0) {
            $item = {};
            $item = $('<li/>');

            $a = $('<a/>')
              .attr('href', $('a:first', $tmp).attr('href'))
              .appendTo($item);

            $span = $('<span/>')
              .html($('span:first', $tmp).first().html())
              .appendTo($a);

            moreList.push($item);
          } else {
            moreList.push($tmp.clone());
          }

          $tmp.addClass('hidden')
            .addClass('exp-less');
      }

      $ul.empty();
      for(var item in moreList) {
        $tmp = moreList[item].removeClass('hidden');
        $ul.append($tmp);
      }

      $more.cbDropdownExtension('destroy');
      $more.cbDropdownExtension();

      addExtraClass($('a', $more));
    },

    // reset more dropdown
    resetMoreDropdown = function () {
      if (!$.isEmptyObject($more)) {
        $more.remove();
        $more = {};
      }

      $('.exp-less', plugin.$el).removeClass('hidden')
        .removeClass('exp-less');
    },

    // calculate elements width
    getWidth = function ($this) {
      var
        result = 0,
        isHidden = (!!$this.hasClass('hidden')) ? true : false;

      // this check is based on the logic used in this plugin
      if (!!isHidden) {
        $this.removeClass('hidden');
        result = $this.outerWidth(true);
        $this.addClass('hidden');
      } else {
        result = $this.outerWidth(true);
        if (!!$.isNumeric(result)) {
          result = Math.ceil(result);
        } else {
          result = 0;
        }
      }

      return result;
    },

    resetBody = function () {
      $('body').removeClass('cb-no-overflow');
    },

    setBodyRestrictions = function () {
      $('body').addClass('cb-no-overflow');
    },

    triggerCustomEvents = function (type, obj) {
      switch(type) {
        case 0: // close all panels
          plugin.$el.trigger('localnav_close', obj);
          break;
        case 1: // open main panel
          plugin.$el.trigger('localnav_open', obj);
          break;
        case 2: // click on main panel title
          plugin.$el.trigger('localnav_mainTitle', obj);
          break;
        case 3: // change in panels
          plugin.$el.trigger('localnav_panel', obj);
          plugin.$el.data('_localnav', obj);
      }
    };

    plugin.checkSticky = function () {
      // only check this is no anchor menu is on the page
      if ($('.' + plugin.options.anchorMenuClass + '.cb-anchor-link li').length === 0) {
        // activate sticky header
        if (!!plugin.options.stickyHeader) {
          $(window).on('scroll.cbLocalNavigation', function() {
            if ($(this).scrollTop() > navigationTopPosition){
              $('body').addClass('cb-adjust-local-nav');
              plugin.$el.addClass('sticky-local-nav');
              calculateMobileNavHeight();
            } else{
              $('body').removeClass('cb-adjust-local-nav');
              plugin.$el.removeClass('sticky-local-nav');
            }
          });
        }
      } else{
        $('body').removeClass('cb-adjust-local-nav');
        plugin.$el.removeClass('sticky-local-nav');
      }
    };

    plugin.mobileCloseAll = function () {
      if (!!plugin.$el.data('cbLocalNavigation')) {
        var
          $openPanels = $('.' + plugin.options.panelClass + '.show', plugin.$el);

        resetBody();
        $openPanels.removeClass('show')
          .addClass('adjust');
        changeMobileTitle(1);
      }
    };

    plugin.mobileClosePanel = function () {
      if (!!plugin.$el.data('cbLocalNavigation')) {
        var
          $panel = $('.' + plugin.options.mobileHeaderClass + ' .menu-back', plugin.$el);

        if ($panel.length > 0) {
          $panel.trigger('click');
        } else {
          plugin.mobileCloseAll();
        }
      }
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      $('.' + plugin.options.mobileClass, plugin.$el).remove();
      $('.' + plugin.options.desktopClass, plugin.$el).removeClass('hidden');
      $('li', plugin.$el).removeAttr('data-cb-parent');
      $('.exp-more', plugin.$el).remove();
      $('.exp-less', plugin.$el).each( function() {
          var
            self = $(this);

          self.removeClass('hidden').removeClass('exp-less');
      });
      $('li.cb-desktop-label', plugin.$el).remove();

      plugin.$el.removeClass('sticky-local-nav');

      $(window).off('resize.cbLocalNavigation');
      $(window).off('scroll.cbLocalNavigation');
      plugin.$el.removeData('cbLocalNavigation');
    };

    plugin.init();
  };

  $.fn.cbLocalNavigation = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbLocalNavigation')) {
          $(this).data('cbLocalNavigation', new cb.apricot.localNav(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbLocalNavigation');
        if (instance instanceof cb.apricot.localNav && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
        }
      });
    }
  };
}(jQuery, cb);
