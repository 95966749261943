/* ========================================================================
 * Resources
 * ========================================================================
 *
 * $.fn.cbDownload
 *
 * This plugin adds a list of files to a .zip file
 * In related to Apricot's resource element
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  cb.apricot.download = function (element, options) {
    var
    defaultOptions = {
      downloadClass: 'cb-resource-plugin',
      noDownloadClass: 'cb-resource-no-plugin',
      loaderSizeClass: 'cb-loader-md',
      loaderColorClass: 'cb-loader-blue'
    },

    plugin = this;
    plugin.$el = $(element);

    var
    addLoader = function ($elm) {
      // add loader plugin
      if ($('.cb-loader', $elm).length <= 0 ) {
        var $loader =$('<div>', {
            'class': 'cb-loader ' + plugin.options.loaderColorClass + ' ' + plugin.options.loaderSizeClass,
            'role': 'progressbar',
            'aria-valuetext': cb.apricot.lang.get('progressBar1')
          });

        $('.cb-resource-body', $elm).append($loader);
      }
    },

    checkViewport = function () {
      var
        currentViewport = cb.apricot.utils.viewport();

      if (currentViewport.prefix === 'sm' || currentViewport.prefix === 'xs') {
        plugin.$el.addClass(plugin.options.noDownloadClass);
        $('.cb-resource-ico-download ', plugin.$el).attr('tabindex', '-1');

      } else {
        plugin.$el.removeClass(plugin.options.noDownloadClass);
        $('.cb-resource-ico-download ', plugin.$el).attr('tabindex', '0');
      }
    },

    addEvents = function ($elm, $parent, title) {
      $elm.off('click.cbrResourceDownload').on('click.cbrResourceDownload', function (e) {
        e.preventDefault();

        if (!!plugin.$el.hasClass(plugin.options.noDownloadClass)) {

          return false;
        } else {
          var
            $loader = $('.cb-loader', $parent),
            $list = $('ul.cb-resource-download-list', $parent);

          generateDownload($loader, $list, title);

          return false;
        }
      }).addClass('cb-resource-download-event');
    },

    deferredAddZip = function (url, filename, zip) {
      var
        deferred = $.Deferred();

      JSZipUtils.getBinaryContent(url, function(err, data) {
        if (err) {
          deferred.reject(err);
        } else {
          zip.file(filename, data, { binary: true });
          deferred.resolve(data);
        }
      });

      return deferred;
    },

    generateDownload = function ($loader, $list, title) {
      // show the loader
      $loader.show();

      // Get href value of each file in a group
      var arr = [];
      $list.children('li').each(function() {

        arr.push($(this).find('.cb-resource-title a').attr('href'));
      });

      // create an instance of JSZip
      var
        zip = new JSZip(),
        deferreds = [],
        protocol = window.location.protocol,
        host = window.location.host;

      $.each(arr, function(i) {
        var
          url = protocol + '//' + host + '/' + arr[i],
          filename = url.replace(/.*\//g, '');

        deferreds.push(deferredAddZip(url, filename, zip));
      });

      // when everything has been downloaded, we can trigger the download
      $.when.apply($, deferreds).done(function() {
        zip.generateAsync({ type: 'blob' })
          .then(function(content) {
            // FileSaver.js
            saveAs(content, title + '.zip');
            $loader.hide();
          });
      }).fail(function() {
        // handle the error here
      });

      return false;
    };


    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, options);

      // check if all dependencies exist
      if (typeof(JSZip) !== typeof(Function)) {
        console.log('cbrResourceDownload requires jszip.js');
        return false;
      }

      plugin.$el.addClass(plugin.options.downloadClass);

      $('.cb-resource-bulk-detail', plugin.$el).each (function() {
        var
          self = $(this),
          $icon = $('a.cb-resource-ico-download', self),
          $download = $('.cb-resource-download-all', self),
          title = $.trim($('.cb-resource-title', self).html());

        title = title.replace(/\s+/g, '-').toLowerCase();

        if ($icon.length > 0) {
          $icon.attr('dynamic-asset-link-track', title + '.zip');
        }
        if ($download.length > 0) {
          $download.attr('dynamic-asset-link-track', title + '.zip');
        }

        // add Loader
        addLoader(self);

        // Download Events
        if ($icon.length > 0) {
          addEvents($icon, self, title);
        }
        if ($download.length > 0) {
          addEvents($download, self, title);
        }
      });

      // Disable click event on mobile and tablet
      checkViewport();
      //Check on resize
      $(window).on('resize.cbrResourceDownload', function () {
        checkViewport();
      });
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      $('.cb-loader', plugin.$el).remove();
      $('[dynamic-asset-link-track]', plugin.$el).removeAttr('dynamic-asset-link-track');
      $('cb-resource-download-event', plugin.$el).off('click.cbrResourceDownload');

      plugin.$el.removeClass(plugin.options.noDownloadClass);
      plugin.$el.removeClass(plugin.options.downloadClass);
      plugin.$el.removeData('cbrResourceDownload');

      $(window).off('resize.cbrResourceDownload');
    };

    plugin.init();
  };

  $.fn.cbrResourceDownload = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbrResourceDownload')) {
          $(this).data('cbrResourceDownload', new cb.apricot.download(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbrResourceDownload');
        if (instance instanceof cb.apricot.download && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
        }
      });
    }
  };
}(jQuery, cb);
