/* ========================================================================
 * Apricot's input method Module
 * ========================================================================
 *
 * Function to track the current input method
 *
 * This plugin is written based on Jeremy Fields, what-input.js
 * https://github.com/ten1seven/what-input
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  // cache document.documentElement
  var
    docElem = document.documentElement,

    // last used input type
    currentInput = 'initial',

    // last used input intent
    currentIntent = null,

    // form input types
    formInputs = [
      'input',
      'select',
      'textarea',
      ''
    ],

    // list of modifier keys commonly used with the mouse and
    // can be safely ignored to prevent false keyboard detection
    ignoreMap = [
      16, // shift
      17, // control
      18, // alt
      91, // Windows key / left Apple cmd
      93  // Windows menu / right Apple cmd
    ],

    // mapping of events to input types
    inputMap = {
      'keyup': 'keyboard',
      'mousedown': 'mouse',
      'mousemove': 'mouse',
      'MSPointerDown': 'pointer',
      'MSPointerMove': 'pointer',
      'pointerdown': 'pointer',
      'pointermove': 'pointer',
      'touchstart': 'touch'
    },

    // array of all used input types
    inputTypes = [],

    // boolean: true if touch buffer timer is running
    isBuffering = false,

    // touch buffer timer
    touchTimer = null;



  // ----- Events
  function _addListeners () {

    // `pointermove`, `MSPointerMove`, `mousemove` and mouse wheel event binding
    // can only demonstrate potential, but not actual, interaction
    // and are treated separately

    // pointer events (mouse, pen, touch)
    if (window.PointerEvent) {
      docElem.addEventListener('pointerdown', _updateInput);
      docElem.addEventListener('pointermove', _setIntent);
    } else if (window.MSPointerEvent) {
      docElem.addEventListener('MSPointerDown', _updateInput);
      docElem.addEventListener('MSPointerMove', _setIntent);
    } else {

      // mouse events
      docElem.addEventListener('mousedown', _updateInput);
      docElem.addEventListener('mousemove', _setIntent);

      // touch events
      if ('ontouchstart' in window) {
        docElem.addEventListener('touchstart', _touchBuffer);
      }
    }

    // mouse wheel
    docElem.addEventListener(_detectWheel(), _setIntent);

    // keyboard events
    docElem.addEventListener('keydown', _updateInput);
    docElem.addEventListener('keyup', _updateInput);
  }

  // checks conditions before updating new input
  function _updateInput (event) {

    // only execute if the touch buffer timer isn't running
    if (!isBuffering) {
      var eventKey = event.which;
      var value = inputMap[event.type];
      if (value === 'pointer') value = _pointerType(event);

      if (currentInput !== value || currentIntent !== value) {
        var activeElem = document.activeElement;
        var activeInput = (
          activeElem &&
          activeElem.nodeName &&
          formInputs.indexOf(activeElem.nodeName.toLowerCase()) === -1
        ) ? true : false;

        if (value === 'touch' ||

          // ignore mouse modifier keys
          (value === 'mouse' && ignoreMap.indexOf(eventKey) === -1) ||

          // don't switch if the current element is a form input
          (value === 'keyboard' && activeInput)) {
          // set the current and catch-all variable
          currentInput = currentIntent = value;

          _setInput();
        }
      }
    }
  }

  // updates the doc and `inputTypes` array with new input
  function _setInput() {
    docElem.setAttribute('data-cb-input-m', currentInput);
    docElem.setAttribute('data-cb-intent-m', currentInput);


    if (inputTypes.indexOf(currentInput) === -1) {
      inputTypes.push(currentInput);
    }
  }

  // updates input intent for `mousemove` and `pointermove`
  function _setIntent(event) {

    // only execute if the touch buffer timer isn't running
    if (!isBuffering) {
      var value = inputMap[event.type];
      if (value === 'pointer') {
        value = _pointerType(event);
      }

      if (currentIntent !== value) {
        currentIntent = value;

        docElem.setAttribute('data-cb-intent-m', currentIntent);
      }
    }
  }

  // buffers touch events because they frequently also fire mouse events
  function  _touchBuffer(event) {

    // clear the timer if it happens to be running
    window.clearTimeout(touchTimer);

    // set the current input
    _updateInput(event);

    // set the isBuffering to `true`
    isBuffering = true;

    // run the timer
    touchTimer = window.setTimeout(function() {

      // if the timer runs out, set isBuffering back to `false`
      isBuffering = false;
    }, 200);
  }

  // ----- Utilities
  function _pointerType(event) {
   if (typeof event.pointerType === 'number') {
      return pointerMap[event.pointerType];
   } else {
      return (event.pointerType === 'pen') ? 'touch' : event.pointerType; // treat pen like touch
   }
  }

  // detect version of mouse wheel event to use
  // via https://developer.mozilla.org/en-US/docs/Web/Events/wheel
  function _detectWheel() {
    return 'onwheel' in document.createElement('div') ?
      'wheel' : // Modern browsers support "wheel"

      document.onmousewheel !== undefined ?
        'mousewheel' : // Webkit and IE support at least "mousewheel"
        'DOMMouseScroll'; // let's assume that remaining browsers are older Firefox
  }

  // -----
  function _init() {
    // add correct mouse wheel event mapping to `inputMap`
    inputMap[_detectWheel()] = 'mouse';

    _addListeners();
    _setInput();
  }

  // return current input type
  // 'strict' (default): returns the same value as the 'data-cb-input-m' attribute
  // 'loose': includes 'data-cb-intent-m' value if it's more current than 'data-cb-input-m'
  function _input(opt) {
    return (opt === 'loose') ? currentIntent : currentInput;
  }

  // returns array: all the detected input types
  function _types() {
    return inputTypes;
  }

  // ----- cb Apricot input method module to public API
  var _inputMethod = {
    init: _init,
    types: _types,
    input: _input
  };

  cb.apricot.inputMethod = _inputMethod;

  // activate input listener
  if (!!cb.apricot.setup.isCb('inputMethod')) {
    // start
    if ('addEventListener' in window && Array.prototype.indexOf) {
      _init();
    }
  }
}(jQuery, cb);
