/* ========================================================================
 * Highlighted Transactions band
 * ========================================================================
 *
 * Style and behavior adjustment for Highlighted Transactions band elements
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  function _ctaAccordion ($elm) {
    // add accordion effect
    if ($('.cb-hi-transactions', $elm).length > 3 && !$elm.parent().hasClass('cb-hi-transactions-accordion')) {
      var
        $wrapper = $('<div>', {'class': 'cb-hi-transactions-accordion'}),
        $accordionBtn  = $('<div>', {'class': 'cb-hi-transactions-btn'}),
        $a =  $('<a>', {'class': 'cb-hi-transactions-see-more'}),
        children = parseInt($('.cb-hi-transactions', $elm).length, 10),
        tdNum = Math.floor(children/2) * 2,
        oNum = Math.floor(children/3) * 3,
        label = (!!$elm.attr('data-cb-hi-transactions-label')) ? $elm.attr('data-cb-hi-transactions-label') : '',
        id = (!!$elm.attr('id')) ? $elm.attr('id') : cb.apricot.utils.uniqueID(5, 'apricot_');

      $a.attr('aria-label', 'See More (' + label + ')')
        .attr('href', '#')
        .attr('aria-expanded', false)
        .attr('aria-controls', id);

      $elm.attr('id', id);

      // $elm.append($evenMore);
      $elm.wrap($wrapper);

      $accordionBtn.append($a);
      $accordionBtn.insertAfter($elm);

      if (children === 4) {
        $accordionBtn.addClass('cb-hi-transactions-four');
      }

      $('.cb-hi-transactions', $elm).each( function(index) {
        var
          self  = $(this);

        // adjust border for tablet/desktop
        if (children === tdNum) {
          $('.cb-hi-transactions', $elm).slice(-2).addClass('cb-hi-transactions-tdb');
        } else if ((index + 1) > tdNum) {
          self.addClass('cb-hi-transactions-tdb');
        }
        // adjust border for oversize
        if (children === oNum) {
          $('.cb-hi-transactions', $elm).slice(-3).addClass('cb-hi-transactions-ob');
        } else if ((index + 1) > oNum) {
          self.addClass('cb-hi-transactions-ob');
        }
      });

      $a.on('click', function (e) {
        e.preventDefault();
        var
          $parent = $elm.parent(),
          num = 0;

        $parent.toggleClass('open');

        if (!!$parent.hasClass('open')) {
          $a.attr('aria-label', cb.apricot.lang.getReplace('ctaAccordion1', label))
            .attr('aria-expanded', true);

          num = _selectFucosElm();
          if (!!$('.cb-hi-transactions', $elm).eq(num).find('a')) {
            $('.cb-hi-transactions', $elm).eq(num).find('a').focus();
          }
        } else {
          $a.attr('aria-label', cb.apricot.lang.getReplace('ctaAccordion2', label))
            .attr('aria-expanded', false);
        }
      });
    } else if($elm.parent().hasClass('cb-hi-transactions-accordion')) {
      // destroy
      var
        $parent = $elm.parent();

      $('.cb-hi-transactions-btn', $parent).remove();

      $elm.unwrap();
    }
  }

  function _selectFucosElm () {
    var
      currentViewport = cb.apricot.utils.viewport(),
      elmNum = 0;

      switch(currentViewport.prefix) {
        case 'lg': // oversize
          elmNum = 3;
          break;
        case 'md': // desktop
          elmNum = 3;
          break;
        case 'sm': // tablet
          elmNum = 4;
          break;
        case 'xs': // mobile
          elmNum = 3;
          break;
      }


    return elmNum;
  }

  function _fucosState ($elm) {
    var $a = $('a', $elm).first();

    $a.on('focus', function () {

      $elm.addClass('focus');
    }).on('blur', function () {

      $elm.removeClass('focus');
    });
  }


  // ----- cb Apricot ctaAccordion module to public API
  var _ctaAccordion = {
    check: _ctaAccordion,
    focusState: _fucosState
  };

  cb.apricot.ctaAccordion = _ctaAccordion;

  // ----- Activate on page load
  $(window).on('load', function () {
    // CTA Band
    $('.cb-hi-transactionsband').each(function () {
      var
        self = $(this);

      if (self.data('cb-element') !== 'no-cb') {
        cb.apricot.ctaAccordion.check(self);
      }
    });

    // CTA Element
    $('.cb-hi-transactions').each(function () {
      var
        self = $(this);

      if (self.data('cb-element') !== 'no-cb') {
        cb.apricot.ctaAccordion.focusState(self);
      }
    });
  });
}(jQuery, cb);
