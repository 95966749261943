/* ========================================================================
 * Form Collection Add-Ons
 * ========================================================================
 *
 * $.fn.cbPopulateDropDown
 *
 * Populate dropDowns (Month, state)
 * ========================================================================
 *
 * $.fn.cbCreditCardTypeDetector
 *
 * Add Credit card detection to input field
 * Based on card type, the card icon will change
 * ========================================================================
 *
 * $.fn.cbIntPhoneDetector
 *
 * Add Internation phone# detection to input field
 * Based on country code, we will change the flag
 *
 * This plugin wont generate a custom button for IE 8.0 and below
 *
 * This plugin is written based on the jQuery plugin from Jack O'Connor
 * http://jackocnr.com/intl-tel-input.html
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  // COLLEGE BOARD'S POPULATE DROPDOWN
  // =========================================
  cb.apricot.populateDropDown = function (element, options) {
    var
      defaultOptions = {
        type: '',
        data: {},  //Array of objects {name: '', value: ''}
        disableByIndex: 0,
        selectByIndex: 1,
        selectByValue: ''
      },
      plugin = this;

    plugin.$el = $(element);

    var
    populate = function (items) { //Populate dropDowns - Month or State
      plugin.$el.empty();
      for (var item in items) {
        //Following line generates an IE8 issue
        //plugin.$el.append(new Option(items[item].name, items[item].value));

        plugin.$el.append('<option value="' + items[item].value + '">' + items[item].name + '</option>');
      }
      addSettings(plugin.$el);
    },

    addSettings = function ($this) {
      if (plugin.options.disableByIndex > 0) {
        $('option:nth-child(' + plugin.options.disableByIndex + ')', $this).attr('disabled', 'disabled');
      }
      if (plugin.options.selectByIndex > 0) {
        $('option:nth-child(' + plugin.options.selectByIndex + ')', $this).attr('selected', true);
      }
      if (!!plugin.options.selectByValue) {
        $this.val(plugin.options.selectByValue);
      }
    };

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, options);

      var
        type = !!plugin.$el.attr('data-cb-populate') ? plugin.$el.attr('data-cb-populate') : plugin.options.type,
        // If data obj is empty use default Aprocit data
        items = !$.isEmptyObject(plugin.options.data) ? plugin.options.data : cb.apricot.data[type];

      if (!items) {
        return false;
      }

      //If we have data, start
      if (!!items.length) {
        populate(items);
      }
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      var
        $parent = plugin.$el.parent();

      plugin.$el.empty();
      if (!!$parent.hasClass('cb-select-container')) {
        plugin.$el.trigger('value_changed');
      }

      plugin.$el.removeData('cbPopulateDropDown');
    };

    plugin.init();
  };

  $.fn.cbPopulateDropDown = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbPopulateDropDown')) {
          $(this).data('cbPopulateDropDown', new cb.apricot.populateDropDown(this, options));
        }
       });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbPopulateDropDown');
        if (instance instanceof cb.apricot.populateDropDown && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
        }
      });
    }
  };

  // COLLEGE BOARD'S CREDIT CARD DETECTION
  // =========================================
  cb.apricot.cardTypeDetector = function (element, options) {
    var
      defaultOptions = {
        data: {}  //Array of objects {name: '', className: '', regExp: ''}
      },
      plugin = this;

    plugin.$el = $(element);
    plugin.id = cb.apricot.utils.uniqueID(10, 'cbCreditCardNum_');

    var
     creditCardType = function (creditCards) {
      var
        $info = $('<span>', {'class': 'sr-only'});

      plugin.id = !!plugin.$el.attr('id') ? plugin.$el.attr('id') : plugin.id;

      plugin.$el.attr('id', plugin.id);
      $info.attr('id', plugin.id + 'help')
            .html(cb.apricot.lang.get('creditCard1'));

      plugin.$el.addClass('cb-credit-card')
            .attr('aria-describedby', plugin.id + 'help')
            .after($info);

      plugin.$el.attr('title', cb.apricot.lang.get('creditCard1'));

      plugin.$el.on('keyup', function () {
        var
          currentValue = $(this).val().replace(/ /g, '').replace(/-/g, ''),
          $card = $(this),
          card = '';

        for (var i = 0; i < creditCards.length; i++) {
          if (currentValue.match(new RegExp(creditCards[i].regExp))) {
            $card.addClass(creditCards[i].className);
            card = creditCards[i].name;
          } else {
            $card.removeClass(creditCards[i].className);
          }
        }

        if (!cb.apricot.utils.isBlank(card)) {
          $card.attr('title', cb.apricot.lang.get('creditCard2') + card );
          $info.html(cb.apricot.lang.get('creditCard2') + card);
        } else {
          plugin.$el.attr('title', cb.apricot.lang.get('creditCard3'));
          $info.html(cb.apricot.lang.get('creditCard3'));
        }
      });

    };

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, options);

      // If data obj is empty use default Apricot data
      var
        items = !$.isEmptyObject(plugin.options.data) ? plugin.options.data : cb.apricot.data.creditCards;

      if (!items) {
        return false;
      }

      //If we have data, start
      if (!!items.length) {
        creditCardType(items);
      }
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      plugin.$el.off('keyup')
        .removeAttr('aria-describedby')
        .removeAttr('title')
        .removeClass('cb-credit-card')
        .css('background-image', '');

      $('#' + plugin.id + 'help').remove();
      plugin.$el.removeData('cbCreditCardTypeDetector');
    };

    plugin.init();
  };

  $.fn.cbCreditCardTypeDetector = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbCreditCardTypeDetector')) {
          $(this).data('cbCreditCardTypeDetector', new cb.apricot.cardTypeDetector(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbCreditCardTypeDetector');
        if (instance instanceof cb.apricot.cardTypeDetector && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
        }
      });
    }
  };

  // COLLEGE BOARD'S INTERNATION PHONE# DETECTION
  // =========================================

  cb.apricot.intPhoneDetector = function (element, options) {
    var
      defaultOptions = {
        defaultCountry: 'us',
        onlyCountries: [], //['ir','fr']
        preferredCountries: ['us'],  //Only works when we display all countries
        data: {}, //Array of objects {name: '', iso: '', dialCode: ''}
        wrapperClass: 'cb-int-phone-container',
        elmClass: 'cb-int-phone',
        dropDownWrapperClass: 'flag-dropdown',
        ieVersion: 8
      },
      plugin = this,
      keys = cb.apricot.utils.keys,

      $wrapper = {}, // main wrapper
      $flagWrapper = {}, //dropdown  wrapper
      $selectedFlag = {}, //dropdown activation element
      $activeFlag = {}, //selected flag
      $list = {}, // dropdown list
      $srOnly = {},
      hadInitialPlaceholder = Boolean($(element).attr('placeholder'));

    plugin.$el = $(element);

    plugin.countries = [];
    plugin.countryCodes = {};
    plugin.filterCode = '';
    plugin.filterType = '';
    plugin.countryCode = '';

    plugin.init = function () {
      var
        browser = cb.apricot.utils.browser();

      plugin.options = $.extend({}, defaultOptions, options);

      //Don't continue if lower than IE 8.0
      if (!!browser.msie && parseInt(browser.version, 10) < plugin.options.ieVersion) {
        plugin.$el.addClass('no-cb');
        return false;
      }

      var
        items = !$.isEmptyObject(plugin.options.data) ? plugin.options.data : cb.apricot.data.countries;

      if (!items) {
        return false;
      }

      //If there is no country array/obj return
      if (!!items.length) {
        //Add accessibility feature
        processCountryData(items);
      }
    };

    var
    generateMarkup = function () {
      plugin.$el.addClass(plugin.options.elmClass);
      $wrapper = $('<div>', {'class': plugin.options.wrapperClass});
      plugin.$el.wrap($wrapper);

      $flagWrapper = $('<div>', {
        'class': plugin.options.dropDownWrapperClass
      }).insertBefore(plugin.$el);

      $selectedFlag = $('<a>', {
        'class': 'selected-flag'
      }).attr('role', 'link')
        .attr('aria-expanded', false)
        .attr('tabindex', '0').appendTo($flagWrapper);

      $activeFlag = $('<span>', {
        'class': 'flag'
      }).appendTo($selectedFlag);

      $srOnly = $('<span>', {'class': 'sr-only'})
        .html(cb.apricot.lang.get('intPhone1'))
        .appendTo($activeFlag);
      $('<span>', {'class': 'arrow down'})
        .attr('aria-hidden', true)
        .appendTo($activeFlag);

      $list = $('<ul>', {
        'class': 'country-list'
      }).insertAfter($selectedFlag);

      addDesignStyle();
      appendListItems();
      setActiveFlag(plugin.options.defaultCountry);
      updateVal();
      updatePlaceholder();
    },

    addDesignStyle = function () {
      var
        $wrap = plugin.$el.closest('.' + plugin.options.wrapperClass);

      plugin.$el.on('focus', function (e) {
        $wrap.addClass('cb-input-focus');
      }).on ('blur', function (e) {
        $wrap.removeClass('cb-input-focus');
      });
    },

    processCountryData = function (allCountries) {
      var
        i = 0;
      if (plugin.options.onlyCountries.length > 0) {
        plugin.countries = [];
        for (i = 0; i < allCountries.length; i++) {
          if ($.inArray(allCountries[i].iso, plugin.options.onlyCountries) !== -1) {
            plugin.countries.push(allCountries[i]);
            addCountryCode(allCountries[i].iso, allCountries[i].dialCode);
          }
        }
      } else if (plugin.options.preferredCountries.length > 0) {
        for (i = 0; i < allCountries.length; i++) {
          if ($.inArray(allCountries[i].iso, plugin.options.preferredCountries) !== -1) {
            plugin.countries.push(allCountries[i]);
            addCountryCode(allCountries[i].iso, allCountries[i].dialCode);
          }
        }
        for (i = 0; i < allCountries.length; i++) {
          if ($.inArray(allCountries[i].iso, plugin.options.preferredCountries) === -1) {
            plugin.countries.push(allCountries[i]);
          addCountryCode(allCountries[i].iso, allCountries[i].dialCode);
          }
        }
      } else {
        plugin.countries = allCountries;
        for (i = 0; i < allCountries.length; i++) {
          addCountryCode(allCountries[i].iso, allCountries[i].dialCode);
        }
      }

      // Only generate custom markup if we have data
      generateMarkup();
    },

    // add a country code to plugin.countryCodes
    addCountryCode = function (iso, dialCode) {
      if (!(dialCode in plugin.countryCodes)) {
        plugin.countryCodes[dialCode] = [];
      }

      plugin.countryCodes[dialCode][0] = iso;
    },

    appendListItems = function () {
     var
        obj = {},
        item = '';

      for (var i = 0; i < plugin.countries.length; i++) {
        obj = plugin.countries[i];
        item = '';

        //open the list item
        item += '<li class="country">';
        item += '<a href= "#" data-cb-dial-code="' + obj.dialCode + '" data-cb-country-code="' + obj.iso + '" title="' + obj.name + '">';

        //add the flag
        item += '<span class="sr-only">' + obj.name + '</span>';
        item += '<span class="flag ' + obj.iso + '" aria-hidden="true"></span>';
        item += '</a>';
        item += '</li>';

        $list.append(item);
      }

      bindInputListeners();
      bindSelectedFlagListeners();
      bindDropdownListeners();
    },

    // decide where to position dropdown
    setDropdownPosition = function () {
      var
        inputTop = plugin.$el.offset().top,
        windowTop = $(window).scrollTop(),
        dropdownHeight = $list.outerHeight(),
        dropdownFitsBelow = inputTop + plugin.$el.outerHeight() + dropdownHeight < windowTop + $(window).height(),
        dropdownFitsAbove = inputTop - dropdownHeight > windowTop,
        cssTop = !dropdownFitsBelow && dropdownFitsAbove ? '-' + (dropdownHeight - 1) + 'px' : '';

        if (!dropdownFitsBelow && dropdownFitsAbove ) {
          plugin.$el.parent().removeClass('slide-down').addClass('slide-up');
        } else {
          plugin.$el.parent().removeClass('slide-up').addClass('slide-down');
        }

      $list.css('top', cssTop);
    },

    getCountryData = function (countryCode) {
      for (var i = 0; i < plugin.countries.length; i++) {
        if (plugin.countries[i].iso == countryCode) {
          return plugin.countries[i];
        }
      }
    },

    getDialCodeData = function (dialCode) {
      for (var i = 0; i < plugin.countries.length; i++) {
        if (plugin.countries[i].dialCode == dialCode) {
          return plugin.countries[i];
        }
      }
    },

    setActiveFlag = function (code) {
      var
        country;

      if (!!$.isNumeric(code)) {
        country = getDialCodeData(code);
      } else if (code !== '') {
        country = getCountryData(code);
      } else {
        country = {
          name: '',
          iso: '',
          dialCode: ''
        };
      }

      $activeFlag.removeClass()
                .addClass('flag')
                .addClass(country.iso);

      $selectedFlag.attr('data-cb-country-code', country.iso)
                  .attr('data-cb-dial-code', country.dialCode);

      $srOnly.html(cb.apricot.lang.getReplace('intPhone2', country.name));

      updatePlaceholder();
    },

    selectListFlag = function (code) {
      var
        $theFlag = $('[data-cb-country-code^="' + code + '"]', $list ).first();

      setListFlag($theFlag);
      $theFlag.focus();
    },

    filterListFlag = function (code, type) {
      var
        $theFlag,
        $currentFlag = $('.active', $list),
        newCode = '';

      code = code.toLowerCase();

      if (!$currentFlag) {
        $theFlag = $('[data-cb-' + type + '-code^="' + code + '"]', $list ).first();
      } else {
        //Check if we can extend the selected code
        if (plugin.filterCode !== '') {
          if (type === 'country') {
            newCode = plugin.filterCode.length >= 2 ? code : plugin.filterCode + code;
          } else {
            newCode = !!$('[data-cb-' + type + '-code^="' + plugin.filterCode + code + '"]', $list ) ? plugin.filterCode + code : code;
          }

          $theFlag = $('[data-cb-' + type + '-code^="' + newCode + '"]', $list ).first();

          if (!!$theFlag ) {
            $theFlag.focus();
            plugin.filterCode = newCode;
          } else {
            plugin.filterCode = '';
          }
        } else {
          plugin.filterCode = '';

          $theFlag = $('[data-cb-' + type + '-code^="' + code + '"]', $list ).first();
          if (!!$theFlag ) {
            $theFlag.focus();
            plugin.filterCode = code;
          }
        }
      }
    },

    setListFlag = function ($this) {
      $('.active', $list).removeClass('active');
      $this.addClass('active');
    },

    toggleFlags = function () {
      $list.toggle();


      if ($list.is(':visible')) {
        $selectedFlag.attr('aria-expanded', true);
        $('.arrow', $selectedFlag)
          .removeClass('down')
          .addClass('up');

        plugin.$el.addClass('active-flag');
      } else {
        $('.arrow', $selectedFlag)
          .removeClass('up')
          .addClass('down');

        $selectedFlag.attr('aria-expanded', false);
        plugin.$el.removeClass('active-flag');
      }
    },

    updateVal = function (dialCode) {
      dialCode = !!dialCode ? dialCode : '+' + $selectedFlag.attr('data-cb-dial-code');
      plugin.$el.val(dialCode);
    },

    updatePlaceholder = function () {
      if (!!hadInitialPlaceholder) {
        var
          number = $selectedFlag.attr('data-cb-dial-code'),
          placeholder = '+' + number;

          plugin.$el.attr('placeholder', placeholder);
      }
    },

    // try and extract a valid international dial code from a full telephone number
    // Note: dialCode
    getDialCode = function (number) {
      var
        dialCode = '';

      // only interested in international numbers (starting with a plus)
      if (number.charAt(0) === '+') {
        var numericChars = '';
        // iterate over chars
        for (var i = 0; i < number.length; i++) {
          var c = number.charAt(i);
          // if char is number
          if ($.isNumeric(c)) {
            numericChars += c;

            // if current numericChars make a valid dial code
            if (!!plugin.countryCodes[numericChars]) {
              dialCode = number.substr(0, i + 1);
            }
            // longest dial code is 4 chars
            if (numericChars.length === 4) {
              break;
            }
          }
        }
      }
      return dialCode.slice(1, dialCode.length);
    },

    bindInputListeners = function () {
      plugin.$el.on('keydown', function (e) {
        if (!!cb.apricot.utils.isKey(e, 'PREV')) { //prev
          e.preventDefault();
          $selectedFlag.focus();
        } else if (!!cb.apricot.utils.isKey(e, 'DOWN') || !!cb.apricot.utils.isKey(e, 'UP')) { //down/up
          e.preventDefault();
          $selectedFlag.click();
        }
      });

      plugin.$el.on('keyup', function (e) {
        var
          value = plugin.$el.val(),
          numValue = '';

        //Make sure we always have a +
        if (value === '') {
          plugin.$el.val('+');
          noFlag();
        } else if (value === '+') {
          noFlag();
        } else if ((e.which >= keys.SPACE && !e.metaKey) || (e.which === keys.DEL || e.which === keys.BACKSPACE)) {
          // 32 is space, and after that it's all chars (not meta/nav keys)
          // del and backspace may change the number too

          value = value.slice(1, value.length);
          numValue = value.replace(/\s+/g, '');

          //If pressed key is not a numeric key, ignore
          if (!$.isNumeric(numValue) && !cb.apricot.utils.isKey(e, 'SPACE')) {
            //We just want numeric values
            value = value.slice(0, -1);
            plugin.$el.val('+' + value);
          }

          value = getDialCode(plugin.$el.val());
          if (!!$.isNumeric(value)) {
            //Update only if the countryCode has changed
            if (value !== plugin.countryCode) {
              setActiveFlag(value);
              updateVal(plugin.$el.val());
              plugin.countryCode = value;
            }
          } else {
            setActiveFlag('');
            $srOnly.html(cb.apricot.lang.get('intPhone1'));
          }
        }
      });

      plugin.$el.on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        if ($list.is(':visible')) {
          toggleFlags();
        }
      });
    },

    noFlag = function () {
      var
        value = plugin.$el.val();

      //No country
      if (value === '' || value === '+') {
        if ($selectedFlag.attr('data-cb-country-code') !== '') {
          $activeFlag.removeClass($selectedFlag.attr('data-cb-country-code'));
          $selectedFlag.attr('data-cb-country-code', '');
          $srOnly.html(cb.apricot.lang.get('intPhone1'));
        }
      }
    },

    bindSelectedFlagListeners = function () {
      $selectedFlag.keydown(function (e) {
        var
          self = $(this);

        if (!!cb.apricot.utils.isKey(e, 'ENTER')) { //enter
          e.preventDefault();
          self.click();
        } else if (!!cb.apricot.utils.isKey(e, 'TAB') && !e.shiftKey) { //tab
          e.preventDefault();
          plugin.$el.focus();
        } else if (!!cb.apricot.utils.isKey(e, 'DOWN')) { //down
          e.preventDefault();
          self.click();
        } else if (!!cb.apricot.utils.isKey(e, 'NEXT')) { //next
          e.preventDefault();
          plugin.$el.focus();
        }
      });

      $selectedFlag.on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        //Adjust list position before opening
        if (!$list.is(':visible')) {
          setDropdownPosition();
        }

        toggleFlags();

        if ($selectedFlag.attr('data-cb-country-code') !== '') {
          selectListFlag($selectedFlag.attr('data-cb-country-code'));
        } else {
          $list.find('a:first').focus();
        }
        plugin.filterCode = '';
      });
    },

    bindDropdownListeners = function () {
      $('a', $list).each( function () {
        $(this).on('keydown', function (e) {
          var
            self = $(this);

          if (e.which >= keys.ZERO && e.which <= keys.NINE) {
            plugin.filterCode = plugin.filterType === 'number' ? plugin.filterCode : '';
            plugin.filterType = 'number';
            filterListFlag(String.fromCharCode(e.which), 'dial');

          } else if (e.which >= keys.A && e.which <= keys.Z) {
            plugin.filterCode = plugin.filterType === 'string' ? plugin.filterCode : '';
            plugin.filterType = 'string';
            filterListFlag(String.fromCharCode(e.which), 'country');

          } else if (!!cb.apricot.utils.isKey(e, 'DOWN')) { //down
            e.preventDefault();
            self.parent().next().find('a').focus();

          } else if (!!cb.apricot.utils.isKey(e, 'UP')) { //up
            e.preventDefault();
            self.parent().prev().find('a').focus();

          }else if (!!cb.apricot.utils.isKey(e, 'ESC')) { //esc
            e.preventDefault();
            toggleFlags();
            plugin.$el.focus();
          }
        });

        $(this).on('click', function (e) {
          e.preventDefault();
          e.stopPropagation();
          setActiveFlag($(this).attr('data-cb-country-code'));
          updateVal();

          toggleFlags();
          plugin.$el.focus();
        });

      });

      $list.find('a:first').keydown(function (e) {
        if (!!cb.apricot.utils.isKey(e, 'TAB') && e.shiftKey) { //shift & tab
          e.preventDefault();
          toggleFlags();
          plugin.$el.focus();
        }
      });

      $list.find('a:last').keydown(function (e) {
        if (!!cb.apricot.utils.isKey(e, 'TAB') && !e.shiftKey) { //tab
          e.preventDefault();
          toggleFlags();
          plugin.$el.focus();
        }
      });

      $('html').on('click', function () {
        if ($list.is(':visible')) {
          toggleFlags();
        }
      });
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      var
        $parent = plugin.$el.parent();

      $('.' + plugin.options.dropDownWrapperClass, $parent).remove();

      plugin.$el.off('keydown')
        .off('keyup')
        .off('click');

      plugin.$el.unwrap();
      plugin.$el.removeData('cbIntPhoneDetector');
    };

    plugin.init();
  };

  $.fn.cbIntPhoneDetector = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbIntPhoneDetector')) {
          $(this).data('cbIntPhoneDetector', new cb.apricot.intPhoneDetector(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbIntPhoneDetector');
        if (instance instanceof cb.apricot.intPhoneDetector && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
        }
      });
    }
  };

  // Activate creditCardTypeDetector & cbIntPhoneDetector
  // ==============
  $(window).on('load', function () {
    //Custom Credit Card
    if ($('[data-cb-element="credit-card"]').length > 0) {
      $('[data-cb-element="credit-card"]').each(function () {
        $(this).cbCreditCardTypeDetector();
      });
      // trigger custom event
      $(document).trigger('cb_creditCard_finished');
    }

    if ($('[data-cb-element="int-phone"]').length > 0) {
      //Custom International Telephone
      $('[data-cb-element="int-phone"]').each(function () {
        $(this).cbIntPhoneDetector();
      });
      // trigger custom event
      $(document).trigger('cb_intPhone_finished');
    }
  });
}(jQuery, cb);
