/* ========================================================================
 * Item List
 * ========================================================================
 *
 * Style and behavior adjustment for Item List
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  $(window).on('load', function () {
    // icon adjustments
    $('.cb-item-list-showcase li[class^="cb-type-"], .cb-item-list-rich li[class^="cb-type-"]').each( function () {
    	var
    		self = $(this),
        isRich = !!self.closest('.cb-item-list').hasClass('cb-item-list-rich'),
        checkMeta = !!isRich,
        hasLink = $('a', self).length > 0;

      if (!!self.hasClass('cb-type-resource')) {
      	if (!!$('.cb-glyph', self).hasClass('cb-batch-download')) {
          if (!!isRich) {
            $('.cb-item-meta', self).addClass('batch-download');
            checkMeta = false;
          } else {
            $('a', self).addClass('batch-download');
          }
        } else if (!!$('.cb-glyph', self).hasClass('cb-series')) {
          if (!!isRich) {
            $('.cb-item-meta', self).addClass('series');
          } else {
            $('a', self).addClass('series');
          }
      	}
      }

      if (!!isRich && (!!self.hasClass('cb-type-video') || !!self.hasClass('cb-type-related'))) {

        checkMeta = false;
      }

      if (!!checkMeta && !!hasLink) {
        if ($('.cb-item-meta', self).length === 0 ) {
          // add meta tag
          $('<ul />')
            .addClass('cb-item-meta')
            .append($('<li />').html('&nbsp;'))
            .insertAfter($('.cb-item-text', self));

          if (!!$('.cb-glyph', self).hasClass('cb-series')) {
            $('.cb-item-meta', self).addClass('series');
          }
        }
      }

      if ($('.cb-item-des', self).length === 0 && ($('.cb-item-image', self).length === 0 || self.hasClass('cb-no-img'))) {

        self.addClass('cb-no-des');
      }
    });

    // Batch list Resource in Accordion - Collapsed
    $('.cb-item-list-rich .cb-batch-resource').each( function () {
      var
        self = $(this),
        $parent = self.parent(),
        $title = $('.cb-item-title', self).first(),
        title = $title.html(),
        $more = $('.cb-see-more', self),
        $container = $('.collapse', self),
        id = (!!$container.attr('id')) ? $container.attr('id') : cb.apricot.utils.uniqueID(5, 'apricot_');

      $more.attr('aria-label', cb.apricot.lang.getReplace('itemList2', title))
        .attr('aria-controls', id)
        .attr('aria-expanded', false);

      $container.attr('id', id);

      $more.on('click', function (e) {
        e.preventDefault();

        if (!!$more.hasClass('open')) {
          $more.removeClass('open')
            .attr('aria-label', cb.apricot.lang.getReplace('itemList2', title))
            .attr('aria-expanded', false);

          $container.slideUp( 300, function() {
            $(this)
              .removeClass('in');
          });

          $parent.removeClass('cb-batch-download-open');
        } else {
          $more.addClass('open')
            .attr('aria-label', cb.apricot.lang.getReplace('itemList1', title))
            .attr('aria-expanded', true);

          $container
              .addClass('in')
              .slideDown( 300);

          $parent.addClass('cb-batch-download-open');
        }
      });
    });

    // showcase, height adjustment
    adjustCardHeight();

    $(window).on('resize.adjustItemCardHeight', function () {
      adjustCardHeight();
    });
  });

  function adjustCardHeight () {
    var
      currentViewport = cb.apricot.utils.viewport(),
      browser = cb.apricot.utils.browser(),
      num = 0;

    switch(currentViewport.prefix) {
      case 'lg': // oversize
        num = 4;
        break;
      case 'md': // desktop
        num = 3;
        break;
      case 'sm': // tablet
        num = 2;
        break;
      case 'xs': // mobile
        num = 1;
        break;
    }


    $('.cb-item-list-showcase').each( function () {
      var
        self = $(this),
        max = 0,
        meta = [],
        items = [];

      // reset
      $('.cb-item-meta', self).css('margin-top', 0);

      if (currentViewport.prefix !== 'xs') {
        $('li[class^="cb-type-"]', self).each(function () {
          var
            self = $(this),
            $meta = $('.cb-item-meta', self),
            obj = {};

          obj.elm = {};
          obj.top = 0;
          obj.height = 0;
          obj.bottom = 0;

          if ($meta.length > 0) {
            if($meta.is(':visible')) {
              obj = {};
              obj.elm = $meta;
              obj.top = $meta.position().top;
              obj.height = self.height();
              obj.bottom = cb.apricot.utils.height($meta) + obj.top;
            }
          }

         // Safari, flexbox bug
          if (!!browser.safari) {
            $('a', self).css('height', self.height());
          }

          meta.push(obj);
        });

        items = chunkArray(meta, num);

        for(var item in items) {
          meta = items[item];
          meta.sort(function (a, b) {
            return a.height - b.height;
          });

          max = meta[meta.length - 1].height;

          $.each(meta, function(index, object) {
            var
              diff = max - object.bottom,
              $elm = object.elm;

            if (diff > 0 && object.bottom !== 0) {
              $elm.css('margin-top', diff + 'px');
            }
          });
        }
      }
    });
  }

  function chunkArray(arr, chunk){
    var results = [];

    while (arr.length) {
      results.push(arr.splice(0, chunk));
    }

    return results;
  }
}(jQuery, cb);
