/* ========================================================================
 * Anchor Menu
 * ========================================================================
 *
 * Style and behavior adjustment for Anchor Menus
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  cb.apricot.anchorMenu = function (element, options) {
    var
      defaultOptions = {
        desktopClass: 'cb-desktop-anchor',
        mobileClass: 'cb-mobile-anchor',
        linkClass: 'cb-anchor-link',
        btnClass: 'cb-anchor-btn',
        stickyClass: 'sticky-anchor-menu',
        btnShowClass: 'cb-anchor-btn-show',
        anchorNoFocus: 'cb-anchor-no-focus',
        localNavClass: 'cb-local-navigation',
        menuName: 'Features',
        menuHeight: 60,
        animationScroll: true,
        animationTime: cb.apricot.data.animationTime,
        focusState: false,
        autoGenerate: false,
        anchor: {},
        anchorArr: [], //array of anchors
        heroElm: {},
        ctaCount : 1
      },
      $mobileMenu = {},
      $desktopMenu = {},
      $btnList = {},
      $localNav = {},
      hasLocalNav = false,
      localNavHeight = 0,
      navAnchorTopPosition = 0,
      anchorTopPosition = 0,
      plugin = this;

    plugin.$el = $(element);

    var
    getMenuItems = function () { //Populate dropDowns
    	var
      	mainItems = [];

      $('.' + plugin.options.linkClass +' li', plugin.$el).each(function () {
        mainItems.push($(this).clone());
      });

      // build Mobile anchor
      $btnList = $('.' + plugin.options.btnClass, plugin.$el).clone();
      buildMobileAnchor(mainItems);
    },

    buildMobileAnchor = function (items) {
    	var
        idValue = cb.apricot.utils.uniqueID(5, 'apricot_'),
        ariaLabel = $('.' + plugin.options.desktopClass, plugin.$el).attr('aria-label');

      $mobileMenu = $('<nav/>')
        .addClass(plugin.options.mobileClass)
        .addClass('container')
        .addClass('hidden')
        // .attr('role', 'navigation')
        .attr('aria-label', ariaLabel)
        .insertAfter($desktopMenu);

      var $row = $('<div/>')
    		.addClass('row')
        .appendTo($mobileMenu);

    	var $dropdown = $('<div/>')
    		.addClass('dropdown')
    		.addClass('cb-dropdown')
        .appendTo($row);

    	var $a = $('<a/>')
    		.addClass('dropdown-toggle')
    		.attr('data-toggle', 'dropdown')
    		.attr('href', '#')
    		.attr('role', 'button')
        .attr('aria-haspopup', true)
    		.attr('aria-expanded', true)
    		.attr('id', idValue)
    		.appendTo($dropdown);

    	$('<span/>')
    		.html(plugin.options.menuName)
    		.appendTo($a);

    	$('<i/>')
    		.addClass('cb-glyph')
    		.addClass('cb-down')
    		.attr('aria-hidden', true)
    		.appendTo($a);

    	$('<i/>')
    		.addClass('cb-glyph')
    		.addClass('cb-up')
    		.addClass('hidden')
    		.attr('aria-hidden', true)
    		.appendTo($a);

    	var $ul = $('<ul/>')
    		.addClass('dropdown-menu')
    		.attr('role', 'menu')
    		.attr('aria-labelledby', idValue);

    	for (var item in items) {
  			items[item].appendTo($ul);
    	}

    	$ul.appendTo($dropdown);

      // set accessibility for dropdowns
      $dropdown.cbDropdownExtension();

    	$btnList.removeClass('col-xs-5')
    		.removeClass('col-md-4')
    		.appendTo($row);

      //----- add bookmark animation
      if (!!plugin.options.animationScroll) {
      	addScrollAnimation();
      }
    },

    breakpointChanges = function () {
      var
        eTop = plugin.$el.offset().top;

      // check desktop vs. mobile
      checkMenuStatus();

      //Check breakpoint status on resize
      $(window).on('resize.cbAnchorMenu', function () {
        checkMenuStatus();
      });

      // activate sticky header
      $(window).on('scroll.cbAnchorMenu', function() {
        var bPos = 0;

        // logic to handel Local Nav + Anchor menu
        if (!!hasLocalNav) {
          if ($(this).scrollTop() > navAnchorTopPosition){
            if ($(this).scrollTop() > anchorTopPosition) {
              plugin.$el.addClass(plugin.options.stickyClass);
            } else {
              bPos = eTop  - $(window).scrollTop();
              $localNav.css('top', (bPos -localNavHeight) + 'px');
            }
          } else{
            plugin.$el.removeClass(plugin.options.stickyClass);
            $localNav.css('top', '0');
          }
        } else { // No local Nav, just stick
          if ($(this).scrollTop() > anchorTopPosition){
            plugin.$el.addClass(plugin.options.stickyClass);
          } else{
            plugin.$el.removeClass(plugin.options.stickyClass);
          }
        }

        // show/hide CTA button
        var $heroCta = (!$.isEmptyObject(plugin.options.heroElm)) ? $('.cb-hero-cta li', plugin.options.heroElm).first() : $('.cb-hero-cta li').first(),
        $heroCtaAll = (!$.isEmptyObject(plugin.options.heroElm)) ? $('.cb-hero-cta li', plugin.options.heroElm) : $('.cb-hero-cta li'),
        checkPoint = 0;

        if ($heroCta.length > 0) {
          checkPoint = parseInt($heroCta.offset().top, 10) + parseInt($heroCta.height(), 10) + 2;
          if (!!hasLocalNav) {
            checkPoint -= localNavHeight;
          }

          if (checkPoint > 0) {
            if ($(this).scrollTop() > checkPoint) {
              plugin.$el.addClass(plugin.options.btnShowClass);
              ctaHiddenState($heroCtaAll, false);
            } else{
              plugin.$el.removeClass(plugin.options.btnShowClass);
              ctaHiddenState($heroCtaAll, true);
            }
          }
        }
      });
    },

    ctaHiddenState = function ($heroCtaAll, mode) {
      $('.btn', plugin.$el).each (function () {

        $(this).attr('aria-hidden', mode);
      });
      $('.btn', $heroCtaAll).each (function (index) {

        $(this).attr('aria-hidden', !mode);
      });
    },

    // figure out which structure we need (mobile/desktop)
    checkMenuStatus = function () {
      var
        currentViewport = cb.apricot.utils.viewport();

      changeMenuStructure(currentViewport.width);
    },

    // switch between mobile/desktop structure
    changeMenuStructure = function (width) {
      var
        maxWidth = cb.apricot.data.viewports.tablet.max;

      if (maxWidth === 0) {
        return false;
      }

      if (width <= maxWidth) {
        switchNavigation(1);
      } else {
        switchNavigation(0);
      }
    },

    //0: desktop
    //1: mobile
    switchNavigation = function (mode) {
      if (!!mode) {
        $desktopMenu.addClass('hidden');
        $mobileMenu.removeClass('hidden');
      } else {
        $mobileMenu.addClass('hidden');
        $desktopMenu.removeClass('hidden');
      }
    },

    // add animation to bookmark scroll
    addScrollAnimation = function () {
      $('.' + plugin.options.linkClass +  ' a, .dropdown-menu a', plugin.$el).on('click', function(e) {
        e.preventDefault();

        var adjust = parseInt(plugin.options.menuHeight, 10),//anchor menu height
        isSticky =(!!plugin.$el.hasClass(plugin.options.stickyClass));

        if (!!$(e.target).attr('href')) {
	        $('body, html').animate({
	          scrollTop: $($(e.target).attr('href')).offset().top - adjust
	        }, plugin.options.animationTime, function() {
	        	if (!!plugin.$el.hasClass(plugin.options.stickyClass) && !isSticky) {
	        		$('body, html').animate({
			          scrollTop: $($(e.target).attr('href')).offset().top - adjust
			        });
	        	}

            // Accessibility: Focus on target after animation is complete.
            var $scrollDestination = $($(e.target).attr('href'));

            // See if the ID of the element is capable of being focused. If not, add a tabindex of '-1'
            if (typeof $scrollDestination.attr('tabindex') === 'undefined') {
              $scrollDestination.attr('tabindex', '-1');
            }
            // Focus on the target.
            if (!plugin.options.focusState) {
              $('.' + plugin.options.anchorNoFocus).removeClass(plugin.options.anchorNoFocus);
              $scrollDestination.addClass(plugin.options.anchorNoFocus);
            }

            $scrollDestination.focus();
	        });
        }
      });
    },

    buildAnchorMenu =  function () {
    	var
    		items = [];

    	if (plugin.options.anchorArr.length > 0) {

    		items = plugin.options.anchorArr;
    	} else { //parse page
    		if (!$.isEmptyObject(plugin.options.anchor)) {
    			var $selector = plugin.options.anchor.selector;

    			$selector.each(function() {
    				var
    					self = $(this),
    					obj ={},
    					idTmp ='';

    				if (!!self.attr('id')) {
    					obj.id = self.attr('id');
    				} else {
    					idTmp = cb.apricot.utils.uniqueID(5, 'apricot_');
    					self.attr('id', idTmp);
    					obj.id = idTmp;
    				}

  					obj.label = (!!plugin.options.anchor.html) ? self.html() :
  						((plugin.options.anchor.attr !== '') ? self.attr(plugin.options.anchor.attr) : '');

  					items.push(obj);
    			});
    		}
    	}

    	buildDesktopAnchor(items);
    },

    // HTML markup for desktop anchor
		buildDesktopAnchor =  function (items) {
    	$desktopMenu = $('<nav/>')
        .addClass(plugin.options.desktopClass)
        .addClass('container')
        .attr('role', 'menu')
        .appendTo(plugin.$el);

      var $row = $('<div/>')
    		.addClass('row')
        .appendTo($desktopMenu);

    	var $ul = $('<ul/>')
        .attr('role', 'menu')
    		.addClass(plugin.options.linkClass)
    		.addClass('col-xs-7')
    		.addClass('col-md-8')
    		.appendTo($row);

    	for (var item in items) {
    		var $li = $('<li/>')
          .attr('role', 'menuitem');

        $('<a/>')
    			.attr('href', '#' + items[item].id)
    			.html(items[item].label)
    			.appendTo($li);

    		$li.appendTo($ul);
    	}

    	var $ulBtn = $('<ul/>')
    		.addClass(plugin.options.btnClass)
    		.addClass('col-xs-5')
    		.addClass('col-md-4')
    		.appendTo($row);

    	if (!!plugin.options.heroElm) {
	    	var $cta1 = $('.cb-hero-cta li', plugin.options.heroElm).first().clone();
	    	if (!!$cta1) {
	    		$cta1.addClass('cb-cta-btn')
	    			.appendTo($ulBtn);

          $('.btn', $cta1).attr('aria-hidden', true);
	    	}

	    	if (plugin.options.ctaCount === 2) {
	    		var $cta2 = $('.cb-hero-cta li', plugin.options.heroElm).last().clone();
		    	if (!!$cta2) {
		    		$cta2.addClass('cb-cta-btn')
	    				.insertBefore($cta1);

            $('.btn', $cta2).attr('aria-hidden', true);
		    	}
	    	}
    	}

    	getMenuItems();
    };

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, options);

      anchorTopPosition = parseInt(plugin.$el.offset().top, 10);
      hasLocalNav = ($('.' + plugin.options.localNavClass).length > 0) ? true : false;

      if (!!hasLocalNav) {
        $localNav = $('.' + plugin.options.localNavClass);
        localNavHeight = $localNav.height();
        navAnchorTopPosition = anchorTopPosition - localNavHeight;
      }

      if (!!plugin.options.autoGenerate) {
      	buildAnchorMenu();
      } else {
      	$desktopMenu = $('.' + plugin.options.desktopClass, plugin.$el);
      	getMenuItems();
      }

      // anchor band is ready
      plugin.$el.trigger('finished.cbAnchorMenu');

      // track breakpoint changes
      breakpointChanges();
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
    	if (!!plugin.options.autoGenerate) {
    		$desktopMenu.remove();
    	}

      var $heroCtaAll = (!$.isEmptyObject(plugin.options.heroElm)) ? $('.cb-hero-cta li', plugin.options.heroElm) : $('.cb-hero-cta li');

      $('.btn', $heroCtaAll).each( function () {
        $(this).removeAttr('aria-hidden');
      });

      $mobileMenu.remove();
      plugin.$el.removeClass('.' + plugin.options.stickyClass);

      $(window).off('resize.cbAnchorMenu');
      $(window).off('scroll.cbAnchorMenu');
      plugin.$el.removeData('cbAnchorMenu');
    };

    plugin.init();
  };

  $.fn.cbAnchorMenu = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbAnchorMenu')) {
          $(this).data('cbAnchorMenu', new cb.apricot.anchorMenu(this, options));
        }
       });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbAnchorMenu');
        if (instance instanceof cb.apricot.anchorMenu && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call(args, 1) );
        }
      });
    }
  };
}(jQuery, cb);
