/*!
 *  Apricot v3.4.0
 *  By:             The College Board
 *  App:            apricot
 *  Build Time:     2019-04-02 [10:28:53 AM] EDT
 *  Build Number:   unknown
 *  SVN Revision:   unknown
 *  Jenkins Job:    unknown
 *  This version of Apricot includes Bootstrap v3.7.0
 */

/* ========================================================================
 * Popover Extension
 * ========================================================================
 *
 * This extension closes all open popOvers
 * when the user resizes the page
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  if (!$.fn.popover) throw new Error('popover-extension requires popover.js');

  //Prevent default behavior for CB's custom popOvers
  $(window).on('load.cbPopover', function () {
    //Close all popOvers on click outside
    $('body').on('click', function (e) {
      $('[data-toggle=popover]').each(function () {
          if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
              (($(this).popover('hide').data('bs.popover')||{}).inState||{}).click = false;
          }
      });
    });

    //Close all popOvers on ESC pressed
    $(document).on('keydown.cbDropdownExtension', function (e) {
      var
        keys = cb.apricot.utils.keys;

      if (e.which === keys.ESC) {
        $('[data-toggle="popover"]').each(function () {
          var $popover = $(this);
  
          if (!!$popover.attr('aria-describedby')) {
            (($popover.popover('hide').data('bs.popover')||{}).inState||{}).click = false;
          }
        });
      }
    });

    //Close all popOvers on window resize
    $(window).on('resize.cbPopover', function () {
      $('[data-toggle="popover"]').each(function () {
        var $popover = $(this);

        if (!!$popover.attr('aria-describedby')) {
          (($popover.popover('hide').data('bs.popover')||{}).inState||{}).click = false;
        }
      })
    });

    $('.cb-popover-button-list, .cb-popover-link-list').each(function () {
      $(this).on('click', function (e) {

        e.preventDefault();
      });
    });
  });
}(jQuery, cb);
