
/* ========================================================================
 * Stepper
 * ========================================================================
 *
 * behavior adjustment for Stepper
 * ======================================================================== */


+ function($, cb) {
  'use strict';

  cb.apricot.stepper = function(element, options) {
    var
      defaultOptions = {
        simple: false,
        autoGenerate: false,
        nodes: [],
        label: '',
        disableLinks: true,
        activeNode: 0,
        skipped: []
      },
      plugin = this;

    plugin.$el = $(element);

    var
    buildStepper = function() {
      var $ol = $('<ol />'),
      items = plugin.options.nodes,
      total = items.length,
      num = 0;

      if (!!plugin.options.simple) {
        plugin.$el.addClass('cb-stepper-simple');
      }
      plugin.$el.attr('data-cb-stepper-total', total)
        .attr('aria-label', plugin.options.label);

      for(var item in items) {
        var
          status = 'pending',
          node = items[item],
          $li = $('<li />'),
          $span = $('<span />')
            .attr('aria-hidden', true)
            .addClass('cb-glyph'),
          $des = $('<p />')
            .html(node.des),
          $ext = $('<p />');

        // Step counter
        num += 1;

        // add glyph
        if (!!node.active) {
          $li.addClass('cb-stepper-active');
          status = 'active';

          if (!!plugin.options.simple) {
            $span.addClass('cb-disc');
          }
        } else if (!!node.skipped) {
          $li.addClass('cb-stepper-skip');
          status = 'skipped';

          if (!!plugin.options.simple) {
            $span.addClass('cb-circle');
          }
        } else if (!!plugin.options.simple) {
          $span.addClass('cb-circle');
        }

        if (!plugin.options.simple) {
          $span.addClass('cb-glyph-circular')
            .addClass(node.class);

          if (node.class === 'cb-glyph-numeric') {
            $span.addClass('cb-glyph-numeric')
              .html(node.number);
          }
        }

        $span.appendTo($li);

        $des.appendTo($li);
        if (node.ext !== '') {
          $ext.html(node.ext)
            .appendTo($li);
        }

        if (!!node.link) {
          var $a = $('<a />')
            .attr('href', node.href)
            .attr('target', node.target);
          $li.wrapInner($a);
        }

        $('<span />').addClass('sr-only')
          .addClass('cb-stepper-step')
          .html('step ' +  num + ' of ' + total + ' ' + status)
          .appendTo($li);

        $li.attr('data-cb-stepper-step', num);

        $ol.append($li);
      }

      if ($('a', $ol).length > 0) {
        plugin.$el.attr('role', 'navigation');
      } else {
        plugin.$el.attr('role', 'region');
      }

      plugin.$el.append($ol);
    },

    getActiveNode = function () {
      var
        $active = $('li.cb-stepper-active', plugin.$el).last(),
        activeIndex = ($active.length > 0) ? $('li', plugin.$el).index($active) : 0;

      plugin.activeNodeIndex = activeIndex;
    },

    setActiveNode = function(num) {
      // reset
      $('li', plugin.$el).removeClass('cb-stepper-complete')
        .removeClass('cb-stepper-active');

      if (!plugin.options.simple) {
        resetNumericNodes();
        resetGlyphNodes();
      } else {
        resetSimpleNodes();
      }

      // make sure we always set an active state
      num = (num > $('li', plugin.$el).length ) ? $('li', plugin.$el).length : num;

      $('li', plugin.$el).each(function (index) {
        var
          self = $(this),
          $span = $('.cb-glyph', self);

        if (index < parseInt(num, 10)) {
          if (!self.hasClass('cb-stepper-skip')) {
            self.addClass('cb-stepper-complete');
            setStepInfo(self, 'completed');

            if (!plugin.options.simple) {
              if (!!$span.hasClass('cb-glyph-numeric')) {
                $span.removeClass('cb-glyph-numeric')
                  .html('');
              } else {
                var className = $span.attr('class');

                className = className.replace('cb-glyph', '')
                  .replace('cb-glyph-circular', '')
                  .trim();

                $span.removeClass(className)
                  .addClass('cb-glyph')
                  .addClass('cb-glyph-circular');
              }

              $span.addClass('cb-check');
            } else {
              $span.addClass('cb-check-circle')
                .removeClass('cb-circle')
                .removeClass('cb-disk');
            }
          }
        } else if (index === parseInt(num, 10)) {
          self.addClass('cb-stepper-active');

          setStepInfo(self, 'active');
          if (!!plugin.options.simple) {
            $span.removeClass('cb-check-circle')
              .removeClass('cb-circle')
              .addClass('cb-disc');
          }
        }
      });

      if (!!plugin.options.disableLinks) {
        disableLinks();
      }
    },

    setSkippedNode = function(num) {

      $('li:eq('+ num +')', plugin.$el).addClass('cb-stepper-skip');
      setStepInfo($('li:eq('+ num +')', plugin.$el), 'skipped');
    },

    setStepInfo = function ($stp, status) {
      var txtData = [
        {
          "name": "step",
          "value": $stp.attr('data-cb-stepper-step')
        },
        {
          "name": "total",
          "value": plugin.$el.attr('data-cb-stepper-total')
        },
        {
          "name": "status",
          "value": status
        }
      ];

      $('.cb-stepper-step', $stp)
        .html(cb.apricot.lang.getReplace('stepper1', txtData));
    },

    disableLinks = function() {
      // reset
      $('li a', plugin.$el).removeClass('cb-stepper-disabled');
      getActiveNode();

      $('li', plugin.$el).each(function (index) {
        var
          self = $(this);

        if (index >= plugin.activeNodeIndex) {
          if ($('a', self).length > 0 ) {
            disableLink($('a', self));
          } else {
            $('a', self).removeClass('cb-stepper-disabled')
              .attr('aria-disabled', false);
          }
        }
      });

      $('a', plugin.$el).each(function () {
        var self = $(this);

        self.off('click.cbStepper').on('click.cbStepper', function (e) {
          if (!!self.hasClass('cb-stepper-disabled')) {
            e.preventDefault();
          }
        });
      });
    },

    disableLink = function($elm) {
      if (!!$elm) {
        $elm.addClass('cb-stepper-disabled')
          .attr('aria-disabled', true);

        $elm.off('click.cbStepper').on('click.cbStepper', function (e) {
          if (!!$elm.hasClass('cb-stepper-disabled')) {
            e.preventDefault();
          }
        });
      }
    },

    setupNodes = function () {
      var
        className  = '';

      $('.cb-glyph', plugin.$el).each(function () {
        var
          self = $(this);

        if (!!self.hasClass('cb-glyph-numeric')) {
          self.attr('data-cb-stepper-num', self.html());
        } else {
          className = self.attr('class').replace('cb-glyph', '')
                .replace('cb-glyph-circular', '')
                .trim();

          self.attr('data-cb-stepper-glyph', className);
        }
      });
    },

    resetGlyphNodes = function () {
      $('.cb-glyph', plugin.$el).each(function () {
        var self = $(this);

        if (!!self.attr('data-cb-stepper-glyph')) {
          self.removeClass('cb-check')
            .addClass(self.attr('data-cb-stepper-glyph'));
        }
      });
    },

    resetNumericNodes = function () {
      $('.cb-glyph', plugin.$el).each(function () {
        var self = $(this);

        if (!!self.attr('data-cb-stepper-num')) {
          self.addClass('cb-glyph-numeric')
            .removeClass('cb-check')
            .html(self.attr('data-cb-stepper-num'));
        }
      });
    },

    resetSimpleNodes = function () {
      $('.cb-glyph', plugin.$el).each(function () {
        var self = $(this);

        self.removeClass('cb-check-circle')
          .removeClass('cb-disc')
          .addClass('cb-circle');
      });
    };

    plugin.init = function() {
      plugin.options = $.extend({}, defaultOptions, options);
      plugin.activeNodeIndex = 0;

      if (!!plugin.options.autoGenerate) {
        buildStepper();
      } else {
        plugin.$el.attr('data-cb-stepper-total', $('li', plugin.$el).length);
        $('li', plugin.$el).each( function (index) {
          $(this).attr('data-cb-stepper-step', index + 1);
        });
      }

      if (!plugin.options.simple) {
        setupNodes();
      }

      if (plugin.options.skipped.length > 0) {
        // reset
        $('li', plugin.$el).removeClass('cb-stepper-skip');

        for (var i in plugin.options.skipped) {
          setSkippedNode(plugin.options.skipped[i]);
        }
      }

      if (parseInt(plugin.options.activeNode, 10) > 0) {
        plugin.activeNodeIndex = parseInt(plugin.options.activeNode, 10);
        setActiveNode(plugin.activeNodeIndex);
      }

      if (!!plugin.options.autoGenerate) {
        getActiveNode();
        setActiveNode(plugin.activeNodeIndex);
      }
    };

    plugin.activeNode = function(val) {
      if (!!$.isNumeric(val)) {
        setActiveNode(val);
      }
    };

    plugin.skippedNode = function(val) {
      // reset
      $('li', plugin.$el).removeClass('cb-stepper-skip');

      if (!!plugin.options.simple) {
        $('li .cb-glyph', plugin.$el).removeClass('cb-check-circle')
          .addClass('cb-circle');
      }
      if (!!$.isNumeric(val)) {
        setSkippedNode(val);
      } else if ($.isArray(val)) {
        for (var i in val) {
          setSkippedNode(val[i]);
        }
      }
    };

    plugin.resetSkippedNode = function(val) {
      if (!!$.isNumeric(val)) {
        resetNumericNodes(val);
      } else {
        $('li', plugin.$el).removeClass('cb-stepper-skip');

        if (!!plugin.options.simple) {
          $('li .cb-glyph', plugin.$el).removeClass('cb-check-circle')
            .addClass('cb-circle');
        }
      }
    };

    plugin.disableNode = function (val) {
      if (!!$.isNumeric(val)) {
        disableLink($('li:eq('+ val +') a', plugin.$el));
      }
    };

    // Remove plugin instance and clean up
    plugin.destroy = function() {
      if (!!plugin.options.autoGenerate) {
        plugin.$el.empty();
      }

      plugin.$el.removeData('cbStepper');
    };

    plugin.init();
  };

  $.fn.cbStepper = function(options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function() {
        if (!$(this).data('cbStepper')) {
          $(this).data('cbStepper', new cb.apricot.stepper(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function() {
        var instance = $.data(this, 'cbStepper');
        if (instance instanceof cb.apricot.stepper && typeof instance[options] === 'function') {
          instance[options].apply(instance, Array.prototype.slice.call(args, 1));
        }
      });
    }
  };
}(jQuery, cb);
