/* ========================================================================
 * Apricot Data
 * ========================================================================
 * $.fn.cbPopulateDropDown
 *
 * cb.apricot.data.monthshort
 * cb.apricot.data.monthlong
 * cb.apricot.data.states
 * ========================================================================
 * $.fn.cbIntPhoneDetector
 *
 * cb.apricot.data.countries
 * ========================================================================
 * $.fn.cbCreditCardTypeDetector
 *
 * cb.apricot.data.creditCards
 * ========================================================================
 * cb.apricot.utils.viewport()
 *
 * cb.apricot.data.viewports
 * ========================================================================
 * cb.apricot.utils.transitionDuration
 * ======================================================================== */

cb.apricot.data = {
  monthshort: [
    {
      value: '',
      name: 'Month'
    },
    {
      value: 1,
      name: 'Jan'
    },
    {
      value: 2,
      name: 'Feb'
    },
    {
      value: 3,
      name: 'Mar'
    },
    {
      value: 4,
      name: 'Apr'
    },
    {
      value: 5,
      name: 'May'
    },
    {
      value: 6,
      name: 'Jun'
    },
    {
      value: 7,
      name: 'Jul'
    },
    {
      value: 8,
      name: 'Aug'
    },
    {
      value: 9,
      name: 'Sep'
    },
    {
      value: 10,
      name: 'Oct'
    },
    {
      value: 11,
      name: 'Nov'
    },
    {
      value: 12,
      name: 'Dec'
    }
  ],
  monthlong: [
    {
      value: '',
      name: 'Month'
    },
    {
      value: 1,
      name: 'January'
    },
    {
      value: 2,
      name: 'February'
    },
    {
      value: 3,
      name: 'March'
    },
    {
      value: 4,
      name: 'April'
    },
    {
      value: 5,
      name: 'May'
    },
    {
      value: 6,
      name: 'June'
    },
    {
      value: 7,
      name: 'July'
    },
    {
      value: 8,
      name: 'August'
    },
    {
      value: 9,
      name: 'September'
    },
    {
      value: 10,
      name: 'October'
    },
    {
      value: 11,
      name: 'November'
    },
    {
      value: 12,
      name: 'December'
    }
  ],
  states: [
    {
    name: 'Choose a State',
    value: ''
    },
    {
    name: 'Alabama',
    value: 'AL'
    },
    {
    name: 'Alaska',
    value: 'AK'
    },
    {
    name: 'American Samoa',
    value: 'AS'
    },
    {
    name: 'Arizona',
    value: 'AZ'
    },
    {
    name: 'Arkansas',
    value: 'AR'
    },
    {
    name: 'California',
    value: 'CA'
    },
    {
    name: 'Colorado',
    value: 'CO'
    },
    {
    name: 'Connecticut',
    value: 'CT'
    },
    {
    name: 'Delaware',
    value: 'DE'
    },
    {
    name: 'District Of Columbia',
    value: 'DC'
    },
    {
    name: 'Federated States Of Micronesia',
    value: 'FM'
    },
    {
    name: 'Florida',
    value: 'FL'
    },
    {
    name: 'Georgia',
    value: 'GA'
    },
    {
    name: 'Guam',
    value: 'GU'
    },
    {
    name: 'Hawaii',
    value: 'HI'
    },
    {
    name: 'Idaho',
    value: 'ID'
    },
    {
    name: 'Illinois',
    value: 'IL'
    },
    {
    name: 'Indiana',
    value: 'IN'
    },
    {
    name: 'Iowa',
    value: 'IA'
    },
    {
    name: 'Kansas',
    value: 'KS'
    },
    {
    name: 'Kentucky',
    value: 'KY'
    },
    {
    name: 'Louisiana',
    value: 'LA'
    },
    {
    name: 'Maine',
    value: 'ME'
    },
    {
    name: 'Marshall Islands',
    value: 'MH'
    },
    {
    name: 'Maryland',
    value: 'MD'
    },
    {
    name: 'Massachusetts',
    value: 'MA'
    },
    {
    name: 'Michigan',
    value: 'MI'
    },
    {
    name: 'Minnesota',
    value: 'MN'
    },
    {
    name: 'Mississippi',
    value: 'MS'
    },
    {
    name: 'Missouri',
    value: 'MO'
    },
    {
    name: 'Montana',
    value: 'MT'
    },
    {
    name: 'Nebraska',
    value: 'NE'
    },
    {
    name: 'Nevada',
    value: 'NV'
    },
    {
    name: 'New Hampshire',
    value: 'NH'
    },
    {
    name: 'New Jersey',
    value: 'NJ'
    },
    {
      name: 'New Mexico',
      value: 'NM'
    },
    {
      name: 'New York',
      value: 'NY'
    },
    {
      name: 'North Carolina',
      value: 'NC'
    },
    {
      name: 'North Dakota',
      value: 'ND'
    },
    {
      name: 'Northern Mariana Islands',
      value: 'MP'
    },
    {
      name: 'Ohio',
      value: 'OH'
    },
    {
      name: 'Oklahoma',
      value: 'OK'
    },
    {
      name: 'Oregon',
      value: 'OR'
    },
    {
      name: 'Palau',
      value: 'PW'
    },
    {
      name: 'Pennsylvania',
      value: 'PA'
    },
    {
      name: 'Puerto Rico',
      value: 'PR'
    },
    {
      name: 'Rhode Island',
      value: 'RI'
    },
    {
      name: 'South Carolina',
      value: 'SC'
    },
    {
      name: 'South Dakota',
      value: 'SD'
    },
    {
      name: 'Tennessee',
      value: 'TN'
    },
    {
      name: 'Texas',
      value: 'TX'
    },
    {
      name: 'Utah',
      value: 'UT'
    },
    {
      name: 'Vermont',
      value: 'VT'
    },
    {
      name: 'Virgin Islands',
      value: 'VI'
    },
    {
      name: 'Virginia',
      value: 'VA'
    },
    {
      name: 'Washington',
      value: 'WA'
    },
    {
      name: 'West Virginia',
      value: 'WV'
    },
    {
      name: 'Wisconsin',
      value: 'WI'
    },
    {
      name: 'Wyoming',
      value: 'WY'
    }
  ],
  countries: [
    {
      name: 'Afghanistan',
      iso: 'af',
      dialCode: '93'
    },
    {
      name: 'Albania',
      iso: 'al',
      dialCode: '355'
    },
    {
      name: 'Algeria',
      iso: 'dz',
      dialCode: '213'
    },
    {
      name: 'Andorra',
      iso: 'ad',
      dialCode: '376'
    },
    {
      name: 'Angola',
      iso: 'ao',
      dialCode: '244'
    },
    {
      name: 'Antigua and Barbuda',
      iso: 'ag',
      dialCode: '1268'
    },
    {
      name: 'Argentina',
      iso: 'ar',
      dialCode: '54'
    },
    {
      name: 'Armenia',
      iso: 'am',
      dialCode: '374'
    },
    {
      name: 'Australia',
      iso: 'au',
      dialCode: '61'
    },
    {
      name: 'Austria',
      iso: 'at',
      dialCode: '43'
    },
    {
      name: 'Azerbaijan',
      iso: 'az',
      dialCode: '994'
    },
    {
      name: 'Bahamas',
      iso: 'bs',
      dialCode: '1242'
    },
    {
      name: 'Bahrain',
      iso: 'bh',
      dialCode: '973'
    },
    {
      name: 'Bangladesh',
      iso: 'bd',
      dialCode: '880'
    },
    {
      name: 'Barbados',
      iso: 'bb',
      dialCode: '1246'
    },
    {
      name: 'Belarus',
      iso: 'by',
      dialCode: '375'
    },
    {
      name: 'Belgium',
      iso: 'be',
      dialCode: '32'
    },
    {
      name: 'Belize',
      iso: 'bz',
      dialCode: '501'
    },
    {
      name: 'Benin',
      iso: 'bj',
      dialCode: '229'
    },
    {
      name: 'Bhutan',
      iso: 'bt',
      dialCode: '975'
    },
    {
      name: 'Bolivia',
      iso: 'bo',
      dialCode: '591'
    },
    {
      name: 'Bosnia and Herzegovina',
      iso: 'ba',
      dialCode: '387'
    },
    {
      name: 'Botswana',
      iso: 'bw',
      dialCode: '267'
    },
    {
      name: 'Brazil',
      iso: 'br',
      dialCode: '55'
    },
    {
      name: 'Brunei',
      iso: 'bn',
      dialCode: '673'
    },
    {
      name: 'Bulgaria',
      iso: 'bg',
      dialCode: '359'
    },
    {
      name: 'Burkina Faso',
      iso: 'bf',
      dialCode: '226'
    },
    {
      name: 'Burma',
      iso: 'mm',
      dialCode: '95'
    },
    {
      name: 'Burundi',
      iso: 'bi',
      dialCode: '257'
    },
    {
      name: 'Cape Verde',
      iso: 'cv',
      dialCode: '238'
    },
    {
      name: 'Cambodia',
      iso: 'kh',
      dialCode: '855'
    },
    {
      name: 'Cameroon',
      iso: 'cm',
      dialCode: '237'
    },
    {
      name: 'Canada',
      iso: 'ca',
      dialCode: '1'
    },
    {
      name: 'Central African Republic',
      iso: 'cf',
      dialCode: '236'
    },
    {
      name: 'Chad',
      iso: 'td',
      dialCode: '235'
    },
    {
      name: 'Chile',
      iso: 'cl',
      dialCode: '56'
    },
    {
      name: 'China',
      iso: 'cn',
      dialCode: '86'
    },
    {
      name: 'Colombia',
      iso: 'co',
      dialCode: '57'
    },
    {
      name: 'Comoros',
      iso: 'km',
      dialCode: '269'
    },
    {
      name: 'Congo (Brazzaville)',
      iso: 'cd',
      dialCode: '243'
    },
    {
      name: 'Congo (Kinshasa)',
      iso: 'cg',
      dialCode: '242'
    },
    {
      name: 'Costa Rica',
      iso: 'cr',
      dialCode: '506'
    },
    {
      name: 'Côte d’Ivoire',
      iso: 'ci',
      dialCode: '225'
    },
    {
      name: 'Croatia',
      iso: 'hr',
      dialCode: '385'
    },
    {
      name: 'Cuba',
      iso: 'cu',
      dialCode: '53'
    },
    {
      name: 'Cyprus',
      iso: 'cy',
      dialCode: '357'
    },
    {
      name: 'Czech Republic',
      iso: 'cz',
      dialCode: '420'
    },
    {
      name: 'Denmark',
      iso: 'dk',
      dialCode: '45'
    },
    {
      name: 'Djibouti',
      iso: 'dj',
      dialCode: '253'
    },
    {
      name: 'Dominica',
      iso: 'dm',
      dialCode: '1767'
    },
    {
      name: 'Dominican Republic',
      iso: 'do',
      dialCode: '1'
    },
    {
      name: 'Ecuador',
      iso: 'ec',
      dialCode: '593'
    },
    {
      name: 'Egypt',
      iso: 'eg',
      dialCode: '20'
    },
    {
      name: 'El Salvador',
      iso: 'sv',
      dialCode: '503'
    },
    {
      name: 'Equatorial Guinea',
      iso: 'gq',
      dialCode: '240'
    },
    {
      name: 'Eritrea',
      iso: 'er',
      dialCode: '291'
    },
    {
      name: 'Estonia',
      iso: 'ee',
      dialCode: '372'
    },
    {
      name: 'Ethiopia',
      iso: 'et',
      dialCode: '251'
    },
    {
      name: 'Fiji',
      iso: 'fj',
      dialCode: '679'
    },
    {
      name: 'Finland',
      iso: 'fi',
      dialCode: '358'
    },
    {
      name: 'France',
      iso: 'fr',
      dialCode: '33'
    },
    {
      name: 'Gabon',
      iso: 'ga',
      dialCode: '241'
    },
    {
      name: 'Gambia',
      iso: 'gm',
      dialCode: '220'
    },
    {
      name: 'Georgia',
      iso: 'ge',
      dialCode: '995'
    },
    {
      name: 'Germany',
      iso: 'de',
      dialCode: '49'
    },
    {
      name: 'Ghana',
      iso: 'gh',
      dialCode: '233'
    },
    {
      name: 'Greece',
      iso: 'gr',
      dialCode: '30'
    },
    {
      name: 'Grenada',
      iso: 'gd',
      dialCode: '1473'
    },
    {
      name: 'Guatemala',
      iso: 'gt',
      dialCode: '502'
    },
    {
      name: 'Guinea',
      iso: 'gn',
      dialCode: '224'
    },
    {
      name: 'Guinea-Bissau',
      iso: 'gw',
      dialCode: '245'
    },
    {
      name: 'Guyana',
      iso: 'gy',
      dialCode: '592'
    },
    {
      name: 'Haiti',
      iso: 'ht',
      dialCode: '509'
    },
    {
      name: 'Holy See',
      iso: 'va',
      dialCode: '39'
    },
    {
      name: 'Honduras',
      iso: 'hn',
      dialCode: '504'
    },
    {
      name: 'Hungary',
      iso: 'hu',
      dialCode: '36'
    },
    {
      name: 'Iceland',
      iso: 'is',
      dialCode: '354'
    },
    {
      name: 'India',
      iso: 'in',
      dialCode: '91'
    },
    {
      name: 'Indonesia',
      iso: 'id',
      dialCode: '62'
    },
    {
      name: 'Iran',
      iso: 'ir',
      dialCode: '98'
    },
    {
      name: 'Iraq',
      iso: 'iq',
      dialCode: '964'
    },
    {
      name: 'Ireland',
      iso: 'ie',
      dialCode: '353'
    },
    {
      name: 'Israel',
      iso: 'il',
      dialCode: '972'
    },
    {
      name: 'Italy',
      iso: 'it',
      dialCode: '39'
    },
    {
      name: 'Jamaica',
      iso: 'jm',
      dialCode: '1876'
    },
    {
      name: 'Japan',
      iso: 'jp',
      dialCode: '81'
    },
    {
      name: 'Jordan',
      iso: 'jo',
      dialCode: '962'
    },
    {
      name: 'Kazakhstan',
      iso: 'kz',
      dialCode: '7'
    },
    {
      name: 'Kenya',
      iso: 'ke',
      dialCode: '254'
    },
    {
      name: 'Kiribati',
      iso: 'ki',
      dialCode: '686'
    },
    {
      name: 'Korea, North',
      iso: 'kp',
      dialCode: '850'
    },
    {
      name: 'Korea, South',
      iso: 'kr',
      dialCode: '82'
    },
    {
      name: 'Kosovo',
      iso: 'xk',
      dialCode: '381'
    },
    {
      name: 'Kuwait',
      iso: 'kw',
      dialCode: '965'
    },
    {
      name: 'Kyrgyzstan',
      iso: 'kg',
      dialCode: '996'
    },
    {
      name: 'Laos',
      iso: 'la',
      dialCode: '856'
    },
    {
      name: 'Latvia',
      iso: 'lv',
      dialCode: '371'
    },
    {
      name: 'Lebanon',
      iso: 'lb',
      dialCode: '961'
    },
    {
      name: 'Lesotho',
      iso: 'ls',
      dialCode: '266'
    },
    {
      name: 'Liberia',
      iso: 'lr',
      dialCode: '231'
    },
    {
      name: 'Libya',
      iso: 'ly',
      dialCode: '218'
    },
    {
      name: 'Liechtenstein',
      iso: 'li',
      dialCode: '423'
    },
    {
      name: 'Lithuania',
      iso: 'lt',
      dialCode: '370'
    },
    {
      name: 'Luxembourg',
      iso: 'lu',
      dialCode: '352'
    },
    {
      name: 'Macedonia',
      iso: 'mk',
      dialCode: '389'
    },
    {
      name: 'Madagascar',
      iso: 'mg',
      dialCode: '261'
    },
    {
      name: 'Malawi',
      iso: 'mw',
      dialCode: '265'
    },
    {
      name: 'Malaysia',
      iso: 'my',
      dialCode: '60'
    },
    {
      name: 'Maldives',
      iso: 'mv',
      dialCode: '960'
    },
    {
      name: 'Mali',
      iso: 'ml',
      dialCode: '223'
    },
    {
      name: 'Malta',
      iso: 'mt',
      dialCode: '356'
    },
    {
      name: 'Marshall Islands',
      iso: 'mh',
      dialCode: '692'
    },
    {
      name: 'Mauritania',
      iso: 'mr',
      dialCode: '222'
    },
    {
      name: 'Mauritius',
      iso: 'mu',
      dialCode: '230'
    },
    {
      name: 'Mexico',
      iso: 'mx',
      dialCode: '52'
    },
    {
      name: 'Micronesia',
      iso: 'fm',
      dialCode: '691'
    },
    {
      name: 'Moldova',
      iso: 'md',
      dialCode: '373'
    },
    {
      name: 'Monaco',
      iso: 'mc',
      dialCode: '377'
    },
    {
      name: 'Mongolia',
      iso: 'mn',
      dialCode: '976'
    },
    {
      name: 'Montenegro',
      iso: 'me',
      dialCode: '382'
    },
    {
      name: 'Morocco',
      iso: 'ma',
      dialCode: '212'
    },
    {
      name: 'Mozambique',
      iso: 'mz',
      dialCode: '258'
    },
    {
      name: 'Namibia',
      iso: 'na',
      dialCode: '264'
    },
    {
      name: 'Nauru',
      iso: 'nr',
      dialCode: '674'
    },
    {
      name: 'Nepal',
      iso: 'np',
      dialCode: '977'
    },
    {
      name: 'Netherlands',
      iso: 'nl',
      dialCode: '31'
    },
    {
      name: 'New Zealand',
      iso: 'nz',
      dialCode: '64'
    },
    {
      name: 'Nicaragua',
      iso: 'ni',
      dialCode: '505'
    },
    {
      name: 'Niger',
      iso: 'ne',
      dialCode: '227'
    },
    {
      name: 'Nigeria',
      iso: 'ng',
      dialCode: '234'
    },
    {
      name: 'Norway',
      iso: 'no',
      dialCode: '47'
    },
    {
      name: 'Oman',
      iso: 'om',
      dialCode: '968'
    },
    {
      name: 'Pakistan',
      iso: 'pk',
      dialCode: '92'
    },
    {
      name: 'Palau',
      iso: 'pw',
      dialCode: '680'
    },
    {
      name: 'Panama',
      iso: 'pa',
      dialCode: '507'
    },
    {
      name: 'Papua New Guinea',
      iso: 'pg',
      dialCode: '675'
    },
    {
      name: 'Paraguay',
      iso: 'py',
      dialCode: '595'
    },
    {
      name: 'Peru',
      iso: 'pe',
      dialCode: '51'
    },
    {
      name: 'Philippines',
      iso: 'ph',
      dialCode: '63'
    },
    {
      name: 'Poland',
      iso: 'pl',
      dialCode: '48'
    },
    {
      name: 'Portugal',
      iso: 'pt',
      dialCode: '351'
    },
    {
      name: 'Qatar',
      iso: 'qa',
      dialCode: '974'
    },
    {
      name: 'Romania',
      iso: 'ro',
      dialCode: '40'
    },
    {
      name: 'Russia',
      iso: 'ru',
      dialCode: '7'
    },
    {
      name: 'Rwanda',
      iso: 'rw',
      dialCode: '250'
    },
    {
      name: 'Saint Kitts and Nevis',
      iso: 'kn',
      dialCode: '1869'
    },
    {
      name: 'Saint Lucia',
      iso: 'lc',
      dialCode: '1758'
    },
    {
      name: 'Saint Vincent and the Grenadines',
      iso: 'vc',
      dialCode: '1784'
    },
    {
      name: 'Samoa',
      iso: 'ws',
      dialCode: '685'
    },
    {
      name: 'San Marino',
      iso: 'sm',
      dialCode: '378'
    },
    {
      name: 'Sao Tome and Principe',
      iso: 'st',
      dialCode: '239'
    },
    {
      name: 'Saudi Arabia',
      iso: 'sa',
      dialCode: '966'
    },
    {
      name: 'Senegal',
      iso: 'sn',
      dialCode: '221'
    },
    {
      name: 'Serbia',
      iso: 'rs',
      dialCode: '381'
    },
    {
      name: 'Seychelles',
      iso: 'sc',
      dialCode: '248'
    },
    {
      name: 'Sierra Leone',
      iso: 'sl',
      dialCode: '232'
    },
    {
      name: 'Singapore',
      iso: 'sg',
      dialCode: '65'
    },
    {
      name: 'Slovakia',
      iso: 'sk',
      dialCode: '421'
    },
    {
      name: 'Slovenia',
      iso: 'si',
      dialCode: '386'
    },
    {
      name: 'Solomon Islands',
      iso: 'sb',
      dialCode: '677'
    },
    {
      name: 'Somalia',
      iso: 'so',
      dialCode: '252'
    },
    {
      name: 'South Africa',
      iso: 'za',
      dialCode: '27'
    },
    {
      name: 'South Sudan',
      iso: 'ss',
      dialCode: '211'
    },
    {
      name: 'Spain',
      iso: 'es',
      dialCode: '34'
    },
    {
      name: 'Sri Lanka',
      iso: 'lk',
      dialCode: '94'
    },
    {
      name: 'Sudan',
      iso: 'sd',
      dialCode: '249'
    },
    {
      name: 'Suriname',
      iso: 'sr',
      dialCode: '597'
    },
    {
      name: 'Swaziland',
      iso: 'sz',
      dialCode: '268'
    },
    {
      name: 'Sweden',
      iso: 'se',
      dialCode: '46'
    },
    {
      name: 'Switzerland',
      iso: 'ch',
      dialCode: '41'
    },
    {
      name: 'Syria',
      iso: 'sy',
      dialCode: '963'
    },
    {
      name: 'Tajikistan',
      iso: 'tj',
      dialCode: '992'
    },
    {
      name: 'Tanzania',
      iso: 'tz',
      dialCode: '255'
    },
    {
      name: 'Thailand',
      iso: 'th',
      dialCode: '66'
    },
    {
      name: 'Timor-Leste',
      iso: 'tl',
      dialCode: '670'
    },
    {
      name: 'Togo',
      iso: 'tg',
      dialCode: '228'
    },
    {
      name: 'Tonga',
      iso: 'to',
      dialCode: '676'
    },
    {
      name: 'Trinidad and Tobago',
      iso: 'tt',
      dialCode: '1868'
    },
    {
      name: 'Tunisia',
      iso: 'tn',
      dialCode: '216'
    },
    {
      name: 'Turkey',
      iso: 'tr',
      dialCode: '90'
    },
    {
      name: 'Turkmenistan',
      iso: 'tm',
      dialCode: '993'
    },
    {
      name: 'Tuvalu',
      iso: 'tv',
      dialCode: '688'
    },
    {
      name: 'Uganda',
      iso: 'ug',
      dialCode: '256'
    },
    {
      name: 'Ukraine',
      iso: 'ua',
      dialCode: '380'
    },
    {
      name: 'United Arab Emirates',
      iso: 'ae',
      dialCode: '971'
    },
    {
      name: 'United Kingdom',
      iso: 'gb',
      dialCode: '44'
    },
    {
      name: 'United States',
      iso: 'us',
      dialCode: '1'
    },
    {
      name: 'Uruguay',
      iso: 'uy',
      dialCode: '598'
    },
    {
      name: 'Uzbekistan',
      iso: 'uz',
      dialCode: '998'
    },
    {
      name: 'Vanuatu',
      iso: 'vu',
      dialCode: '678'
    },
    {
      name: 'Venezuela',
      iso: 've',
      dialCode: '58'
    },
    {
      name: 'Vietnam',
      iso: 'vn',
      dialCode: '84'
    },
    {
      name: 'Yemen',
      iso: 'ye',
      dialCode: '967'
    },
    {
      name: 'Zambia',
      iso: 'zm',
      dialCode: '260'
    },
    {
      name: 'Zimbabwe',
      iso: 'zw',
      dialCode: '263'
    }
  ],
  creditCards: [
    {
        name: 'Visa',
        className: 'visa',
        regExp: '^4[0-9]{0,15}$'
    },
    {
        name: 'Master Card',
        className: 'mastercard',
        regExp: '^5$|^5[1-5][0-9]{0,14}$'
    },
    {
        name: 'American Express',
        className: 'amex',
        regExp: '^3$|^3[47][0-9]{0,13}$'
    },
    {
        name: 'Discover',
        className: 'discover',
        regExp: '^6$|^6[05]$|^601[1]?$|^65[0-9][0-9]?$|^6(?:011|5[0-9]{2})[0-9]{0,12}$'
    },
    {
        name: 'Diners Club',
        className: 'diners',
        regExp: '^3$|^3[068]$|^3(?:0[0-5]|[68][0-9])[0-9]{0,11}$'
    },
    {
        name: 'JCB',
        className: 'jcb',
        regExp: '^2[1]?$|^21[3]?$|^1[8]?$|^18[0]?$|^(?:2131|1800)[0-9]{0,11}$|^3[5]?$|^35[0-9]{0,14}$'
    }
  ],
  viewports: {
    'mobile': {
      'min': 0,
      'max': 767
    },
    'tablet': {
      'min': 768,
      'max': 1023
    },
    'desktop': {
      'min': 1024,
      'max': 1249
    },
    'oversize': {
      'min': 1250,
      'max': 99999
    }
  },
  transitionDuration: 250,
  animationTime: 300
};
