/* ========================================================================
 * Program Band
 * ========================================================================
 *
 * Style and behavior adjustment for Program Band Element
 * ======================================================================== */

+function ($, cb) {
  'use strict';


  cb.apricot.programBand = function (element) {
    var
      plugin = this,
      programs = [];

    plugin.$el = $(element);

    var
    setOrder = function (type) {
      if (type === 'lg' || type === 'md') {
          programs.sort(function (a, b) {
              return parseFloat(a.d) - parseFloat(b.d);
          });
      } else if (type === 'sm'){
          programs.sort(function (a, b) {
              return parseFloat(a.t) - parseFloat(b.t);
          });
      } else if (type === 'xs'){
          programs.sort(function (a, b) {
              return parseFloat(a.m) - parseFloat(b.m);
          });
      }
      buildBand();
    },

    buildBand = function() {

      plugin.$el.empty();

      $.each(programs, function(index, obj) {
        plugin.$el.append(obj.elm);
      });
    };

    plugin.init = function () {

      $('li', plugin.$el).each(function () {
        var
            self = $(this),
            $a = $('a', self),
            obj = {};

        // for accessibility IE, removed color
        if (self.css('background-color') === 'rgb(255, 255, 255)' || self.css('background-color') === 'rgb(0, 0, 0)') {
          self.css('background', 'none')
            .addClass('cb-no-color');

          $a.css('background', 'none');
          $('span', $a).removeClass('sr-only');
        }

        obj.d = self.attr('data-cb-d');
        obj.t = (!!self.attr('data-cb-t')) ? self.attr('data-cb-t') : obj.d;
        obj.m = (!!self.attr('data-cb-m')) ? self.attr('data-cb-m') : obj.t;
        obj.elm = self;

        programs.push(obj);

      });
      var viewport = cb.apricot.utils.viewport();

      if (viewport.prefix === 'sm') {
        setOrder('sm');
      } else if (viewport.prefix === 'xs') {
        setOrder('xs');
      }

      // add listener
      cb.apricot.utils.breakpoints();
      $(document).on('breakpoint_change', function (e, data) {
        setOrder(data.prefix);
      });
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      if (!!plugin.$el.data('cbProgramBand')) {
        plugin.$el.removeData('cbProgramBand');
      }
    };

    plugin.init();
  };

  $.fn.cbProgramBand = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbProgramBand')) {
          $(this).data('cbProgramBand', new cb.apricot.programBand(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbProgramBand');
        if (instance instanceof cb.apricot.programBand && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
        }
      });
    }
  };

  // ----- Activate on page load
  $(window).on('load', function () {
    // Progran Band
    $('.cb-program-brand-v1').each(function () {
      var
        self = $(this);

      if (self.data('cb-element') !== 'no-cb') {
        self.cbProgramBand();
      }
    });

    $('.cb-program-brand a[class$="-black-b"], .cb-program-brand-v2 a[class$="-black-b"]').each(function () {
      var
        self = $(this),
        className = self.attr('class'),
        hoverClass= className.replace('-black','');

      self.attr('data-cb-org-class', className)
        .attr('data-cb-hover-class', hoverClass);

      self.on('mouseover.cbProgramBand focus.cbProgramBand', function() {
        self.addClass(hoverClass)
          .removeClass(className);
      });

      self.on('mouseleave.cbProgramBand blur.cbProgramBand', function() {
        self.addClass(className)
          .removeClass(hoverClass);
      });

      self.on('click.cbProgramBand', function(e) {

        resetBand();
      });
    });

    // for accessibility IE, removed color
    $('.cb-program-brand li, .cb-program-brand-v2 li').each(function () {
      var self =$(this),
      $a = $('a', self),
      $prev = self.prev();

      if ($a.css('color') !== 'rgb(0, 119, 200)') {
        self.css('background', 'none')
          .addClass('cb-no-color');

        $a.css('background', 'none');
        $('span', $a).removeClass('sr-only');
      }

      if ($('ul', $prev).length > 0) {
        self.addClass('cb-dark-border');
      }

    });

    function resetBand () {
      $('.cb-program-brand a[class$="-black-b"], .cb-program-brand-v2 a[class$="-black-b"]').each(function () {
      var
        self = $(this);

        self.addClass(self.attr('data-cb-org-class', className))
          .removeClass(self.attr('data-cb-hover-class', hoverClass));

      });
    }
  });
}(jQuery, cb);
