/* ========================================================================
 * Anchor Menu
 * ========================================================================
 *
 * Style and behavior adjustment for Anchor Menus
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  cb.apricot.onPageMenu = function (element, options) {
    var
      defaultOptions = {
        desktopClass: 'cb-onpage-menu-desktop',
        mobileClass: 'cb-onpage-menu-mobile',
        menuName: 'Menu'
      },
      $desktopMenu = {},
      $mobileMenu = {},
      plugin = this;

    plugin.$el = $(element);

    var
    getMenuItems = function () { //Populate dropDowns
    	var
      	mainItems = [];

      $('li', plugin.$el).each(function () {
        mainItems.push($(this).clone());
      });

      // build Mobile menu
      buildMobileMenu(mainItems);
    },

    buildMobileMenu = function (items) {
    	var
        idValue = cb.apricot.utils.uniqueID(5, 'apricot_'),
        ariaLabel = ($desktopMenu.attr('aria-label')) ? $desktopMenu.attr('aria-label') : 'On-Page Menu';

      $mobileMenu = $('<nav/>')
        .addClass(plugin.options.mobileClass)
        .addClass('hidden')
        .attr('aria-label', ariaLabel)
        .insertAfter($desktopMenu);

    	var $dropdown = $('<div/>')
    		.addClass('dropdown')
    		.addClass('cb-dropdown')
        .appendTo($mobileMenu);

    	var $a = $('<a/>')
    		.addClass('dropdown-toggle')
    		.attr('data-toggle', 'dropdown')
    		.attr('href', '#')
    		.attr('role', 'button')
        .attr('aria-haspopup', true)
    		.attr('aria-expanded', false)
    		.attr('id', idValue)
    		.appendTo($dropdown);

    	$('<span/>')
    		.html(plugin.options.menuName)
    		.appendTo($a);

    	$('<i/>')
    		.addClass('cb-glyph')
    		.addClass('cb-down')
    		.attr('aria-hidden', true)
    		.appendTo($a);

    	$('<i/>')
    		.addClass('cb-glyph')
    		.addClass('cb-up')
    		.addClass('hidden')
    		.attr('aria-hidden', true)
    		.appendTo($a);

    	var $ul = $('<ul/>')
    		.addClass('dropdown-menu')
    		.attr('role', 'menu')
    		.attr('aria-labelledby', idValue);

    	for (var item in items) {
  			items[item].appendTo($ul);
    	}

      $('li', $ul).attr('role', 'menuitem')
      $ul.appendTo($dropdown);
      

      // set accessibility for dropdowns
      $dropdown.cbDropdownExtension();
    },

    breakpointChanges = function () {

      // check desktop vs. mobile
      checkMenuStatus();

      //Check breakpoint status on resize
      $(window).on('resize.cbOnPageMenu', function () {
        checkMenuStatus();
      });
    },

    // figure out which structure we need (mobile/desktop)
    checkMenuStatus = function () {
      var
        currentViewport = cb.apricot.utils.viewport();

      changeMenuStructure(currentViewport.width);
    },

    // switch between mobile/desktop structure
    changeMenuStructure = function (width) {
      var
        maxWidth = cb.apricot.data.viewports.tablet.max;

      if (maxWidth === 0) {
        return false;
      }

      if (width <= maxWidth) {
        switchNavigation(1);
      } else {
        switchNavigation(0);
      }
    },

    //0: desktop
    //1: mobile
    switchNavigation = function (mode) {
      if (!!mode) {
        $desktopMenu.addClass('hidden');
        $mobileMenu.removeClass('hidden');
      } else {
        $mobileMenu.addClass('hidden');
        $desktopMenu.removeClass('hidden');
      }
    };

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, options);

      	$desktopMenu = $('.' + plugin.options.desktopClass, plugin.$el);
      	getMenuItems();

      // track breakpoint changes
      breakpointChanges();
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      $mobileMenu.remove();

      $(window).off('resize.cbOnPageMenu');
      plugin.$el.removeData('cbOnPageMenu');
    };

    plugin.init();
  };

  $.fn.cbOnPageMenu = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbOnPageMenu')) {
          $(this).data('cbOnPageMenu', new cb.apricot.onPageMenu(this, options));
        }
       });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbOnPageMenu');
        if (instance instanceof cb.apricot.onPageMenu && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call(args, 1) );
        }
      });
    }
  };
}(jQuery, cb);
