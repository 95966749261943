/* ========================================================================
 * Responsive Image
 * ========================================================================
 *
 * $.fn.cbResponsiveImage
 *
 * This plugin provides support for multiple image sources for a singular image
 * based on current active viewport.
 * Options:
 * Assigning an image based on the active viewport when the page is loaded
 * Assigning a new image whenever the breakpoint changes
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  cb.apricot.responsiveImage = function (element, options) {
    var
    defaultOptions = {
      cbViewport: true,
      cbBreakpoint: true,
      cbDefaultImage: '',
      cbXsImage: '',
      cbSmImage: '',
      cbMdImage: '',
      cbLgImage: '',
      cbPath: '',
      cbRetina: false,
      cbRetinaExt: '@2x'
    },
    imageList = [],
    plugin = this;

    plugin.$el = $(element);
    plugin.id = cb.apricot.utils.uniqueID(10, 'responsiveImage_');
    plugin.src = '';
    plugin.retina = cb.apricot.utils.isRetina();
    plugin.retinaImage = '';

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, plugin.$el.data(), options);

      // if no ID found add new id
      if (!!plugin.$el.attr('id')) {
        plugin.id = plugin.$el.attr('id');
      } else {
        plugin.$el.attr('id', plugin.id);
      }

      // list all images for different breakpoints
      buildImageList();
      getImage();

      // get viewport specific image
      if (!!plugin.options.cbViewport) {
        changeImage(cb.apricot.utils.viewport().prefix);
      } else if (!!plugin.retina && !!plugin.options.cbRetina) { // just check retina option
        getRetina();
      } else if (!plugin.options.cbBreakpoint) {
        plugin.$el.trigger('no_image_change.cbResponsiveImage');
      }

      // change image based on breakpoints
      if (!!plugin.options.cbBreakpoint) {
        activateBreakpoints();
      }
    };

    var
    activateBreakpoints = function () {
      //Initialize breakpoint_change event
      cb.apricot.utils.breakpoints();

      //Listen to viewport changes
      $(document).on('breakpoint_change.cbResponsiveImage.' + plugin.id, function (e, data) {
        changeImage(data.prefix);
      });
    },

    buildImageList = function () {
      //mobile
      if (!cb.apricot.utils.isBlank(plugin.options.cbXsImage)) {
        imageList.push(getImageObj('xs'));
      }
      //tablet
      if (!cb.apricot.utils.isBlank(plugin.options.cbSmImage)) {
        imageList.push(getImageObj('sm'));
      }
      //desktop
      if (!cb.apricot.utils.isBlank(plugin.options.cbMdImage)) {
        imageList.push(getImageObj('md'));
      }
      //oversize
      if (!cb.apricot.utils.isBlank(plugin.options.cbLgImage)) {
        imageList.push(getImageObj('lg'));
      }
    },

    getImageObj = function (prefix) {
      var
        imageObj = {},
        image = '';

      image = plugin.options.cbPath + plugin.options[breakpointAttribute(prefix)];

      // check if device is supporting high-resolution
      // check if user has not disabled this feature
      // get retina for each break point
      if (!!plugin.retina && !!plugin.options.cbRetina)  {
        image = fileName(image) + plugin.options.cbRetinaExt + '.' + fileExt(image);
        plugin.$el.addClass('cb-retina-image');
      }

      imageObj = {
        prefix: prefix,
        url: image
      };

      return imageObj;
    },

    fileName = function (file) {
      return file.split('.').slice(0,-1).join('.') || file;
    },

    fileExt = function (file) {
      var filename = file.split('\\').pop().split('/').pop();

      return filename.substr((Math.max(0, filename.lastIndexOf('.')) || Infinity) + 1);
    },

    breakpointAttribute = function (value) {
      return 'cb' + value.substr(0, 1).toUpperCase() + value.substr(1) + 'Image';
    },

    changeImage = function (prefix) {
      var
        url = getImage(prefix);

      if (!cb.apricot.utils.isBlank(url)) {
        setImage(url);
      } else if (plugin.retinaImage !== '') {
        setImage(plugin.retinaImage);
      }
    },

    setImage = function (url) {
      //Check if we are dealing with an image element
      if (!!plugin.$el.is('img')) {
        plugin.$el.attr('src', url);
      } else {
        plugin.$el.css('background-image', 'url(' + url + ')');
      }

      // trigger event for image change
      plugin.$el.trigger('image_change.cbResponsiveImage');
    },

    getImage = function (prefix) {
      var
        imageObj = {},
        theImage = '';

      // if image is not provieded for viewport use default Image
      // if default image is not passes in check for src
      if (!!cb.apricot.utils.isBlank(plugin.options.cbDefaultImage)) {
        if (!!plugin.$el.is('img')) {
          if (!cb.apricot.utils.isBlank(plugin.$el.attr('src'))) {
            theImage = plugin.$el.attr('src');
          }
        } else {
          theImage = plugin.$el.css('background-image').replace(/^url\(['"]?/, '').replace(/['"]?\)$/, '');
        }
      } else {
        theImage = plugin.options.cbPath + plugin.options.cbDefaultImage;
      }

      plugin.src = theImage;

      for (var image in imageList ) {
        if (imageList[image].prefix === prefix) {
          imageObj = imageList[image];
        }
      }
      if (!$.isEmptyObject(imageObj)) {
        theImage = imageObj.url;
      }

      return theImage;
    },

    getRetina =  function () {
      plugin.retinaImage = plugin.options.cbPath + fileName(plugin.options.cbDefaultImage) + plugin.options.cbRetinaExt + '.' + fileExt(plugin.options.cbDefaultImage);
      setImage(plugin.retinaImage);

      plugin.$el.addClass('cb-retina-image');
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      $(document).off('breakpoint_change.cbResponsiveImage.' + plugin.id);
      plugin.$el.off('image_change.cbResponsiveImage');
      plugin.$el.off('no_image_change.cbResponsiveImage');

      // set default image
      if (!!plugin.$el.is('img')) {
        plugin.$el.attr('src', plugin.src);
      } else {
        plugin.$el.css('background-image', 'url(' + plugin.src + ')');
      }

      plugin.$el.removeData('cbResponsiveImage');
    };

    plugin.init();
  };

  $.fn.cbResponsiveImage = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbResponsiveImage')) {
          $(this).data('cbResponsiveImage', new cb.apricot.responsiveImage(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbResponsiveImage');
        if (instance instanceof cb.apricot.responsiveImage && typeof instance[options] === 'function') {
          instance[options].apply(instance, Array.prototype.slice.call(args, 1));
        }
      });
    }
  };

  // Activate responsive images
  // ==============
  $(window).on('load', function () {
    if (!!cb.apricot.setup.isCb('responsiveImage')) {
      $('[data-cb-element="responsive-image"]').each(function () {
        $(this).cbResponsiveImage();
      });
    } else if (!!cb.apricot.setup.hasSelector('responsiveImage')) {
      //apply CB's style to desired elements
      $.each(cb.apricot.setup.selector('responsiveImage'), function(index, value) {
          $(value).cbResponsiveImage();
      });
    }
  });
}(jQuery, cb);
