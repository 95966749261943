/* ========================================================================
 * Feature Preset
 * ========================================================================
 *
 * behavior adjustment for Feature Preset
 * ======================================================================== */

+function ($) {
  'use strict';

  $(window).on('load', function () {
    $('.cb-panel').each( function () {
      var $panel = $(this);
      if ($('.cb-comparison-block', $panel).length === 2) {
        var $first = $('.cb-comparison-block', $panel).first();
        $('.cb-comparison-block', $panel).last().addClass('cb-comparison-sec');
        $('<div />')
          .addClass('cb-comparison-vs')
          .addClass('hidden-xs')
          .attr('aria-hidden', true)
          .appendTo($('.cb-block-img', $first));

        $('<div />')
          .addClass('cb-comparison-vs')
          .addClass('hidden-sm')
          .addClass('hidden-md')
          .addClass('hidden-lg')
          .attr('aria-hidden', true)
          .appendTo($first);
      }
    });

    if ($('.cb-media-full').length > 0) {
      $('.cb-media-full').each ( function () {
        var self = $(this);

        adjustMediaSize(self);
        $(document).on('resize', function () {
          adjustMediaSize(self);
        });
      });
    }


    if ($('.cb-free-form').length > 0 || 
      $('.cb-key-benefit').length > 0 ||
      $('.cb-procedures').length > 0 ||
      $('.cb-testimonial').length > 0 ) {

      // Safari, flexbox bug
      cb.apricot.utils.addClassSafari($('body'));

      // IE 11, min-height bug
      cb.apricot.utils.addClassIE($('body'));
    }
  });

  function adjustMediaSize($elm) {
    var $content =  $('.cb-block-content', $elm),
    $img = $('.cb-block-img', $elm),
    heightTxt = cb.apricot.utils.height($content),
    heightMain = cb.apricot.utils.height($elm),
    height = ((heightMain - heightTxt) / 2) - 64;
    if ($('.cb-play', $img).length <=0 ) {
      return false;
    }

    $img.css('top', height  + 'px');
  }
}(jQuery);